import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Table, Select, Tooltip} from 'antd';
import useGetCompList from '../../hooks/Comp/useGetCompList';
import StyleAtoms from '../../common/StyleAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import useGetTeamStat from '../../hooks/Stat/useGetTeamStat';
import useGetPlayerStat from '../../hooks/Stat/useGetPlayerStat';
import { 포지션필터 } from '../../common/util' 
import useGetGroupName from '../../hooks/Stat/useGetGroupName';
import { QuestionCircleOutlined } from '@ant-design/icons';

const  StatMain = () => {
    const { Column } = Table;
    const { Option } = Select;
    const { compList } = useGetCompList();
    const { getTeamStat, teamStat } = useGetTeamStat();
    const { getPlayerStat, playerStat } = useGetPlayerStat();
    const { getGroupName, groupNameList } = useGetGroupName();
    const { PageTitle, TitleBox, Title, SToolIcon  } = StyleAtoms();
    const [selectedComp, setSelectedComp] = useState();
    const [selectedGroup, setSelectedGroup] = useState('전체 그룹');
    const [teamFilters, setTeamFilters] = useState([]);
    const [currentTeamPage, setCurrentTeamPage] = useState(1);
    const [currentTeamPageSize, setCurrentTeamPageSize] = useState(10);
    const [currentPlayerPage, setCurrentPlayerPage] = useState(1);
    const [currentPlayerPageSize, setCurrentPlayerPageSize] = useState(10);
    const filteredGroupNameList = groupNameList?.filter(groupName => groupName?.length > 0)

    const rowClassName = (record, index) => {
        if (index % 2 !== 0) {
          return 'even-row';
        }
        return '';
    };

    //경기뛴 애들만 보여줌, 백엔드에서 걸러서 줌
    const sortedTeamStat = teamStat
        ? [...teamStat].sort((a, b) => {
            if (b.win === a.win) {
              if (b.draw === a.draw) {
                if (b.goal - b.goalConceded === a.goal - a.goalConceded) {
                  if (b.gamePlayed === a.gamePlayed) {
                    const aCards = a.yellowCard + a.redCard;
                    const bCards = b.yellowCard + b.redCard;
                    return aCards - bCards;
                  }
                  return a.gamePlayed - b.gamePlayed;
                }
                return (b.goal - b.goalConceded) - (a.goal - a.goalConceded);
              }
              return b.draw - a.draw;
            }
            return b.win - a.win;
          // })
          }).filter(a => a.gamePlayed > 0)
    : null;

    const sortedPlayerStat = playerStat
        ? [...playerStat].sort((a, b) => {
            if (b.goal === a.goal) {
              if (b.assist === a.assist) {
                if (a.gamePlayed === b.gamePlayed) {
                  if (a.playingTime === b.playingTime) {
                    if (a.redCard === b.redCard) {
                      if (a.yellowCard === b.yellowCard) {
                        if (b.win === a.win) {
                          return b.draw - a.draw;
                        }
                        return b.win - a.win;
                      }
                      return a.yellowCard - b.yellowCard;
                    }
                    return a.redCard - b.redCard;
                  }
                  return a.playingTime - b.playingTime;
                }
                return a.gamePlayed - b.gamePlayed;
              }
              return b.assist - a.assist;
            }
            return b.goal - a.goal;
          // })
          }).filter(a => a.gamePlayed > 0)
  : null;

    const onCompChange = (value) => {
      setSelectedComp(value);
      setSelectedGroup(null);
      setSelectedGroup('전체 그룹');
    }

    const onGroupChange = (value) => {
      setSelectedGroup(value)
    }

    const generateTeamFilters = (teamStat) => {
      const filters = teamStat.map((team) => ({
        text: team.name,
        value: team.name,
      }));
      return filters;
    };

    useEffect(() => { 
      if (compList && compList.length > 0) {
        setSelectedComp(compList[0].id);
        getGroupName(compList[0].id)
      }
    }, [compList]);

    useEffect(() => {
      if(selectedComp) {
        getTeamStat(selectedComp)
        getPlayerStat(selectedComp)
        getGroupName(selectedComp)
      }
    },[selectedComp])

    useEffect(() => {
      if(selectedGroup !== '전체 그룹') {
        getTeamStat(selectedComp, selectedGroup)
        getPlayerStat(selectedComp, selectedGroup)  
      } else if(selectedComp) {
        getTeamStat(selectedComp)
        getPlayerStat(selectedComp)
      }
    },[selectedGroup])
    
    useEffect(() => {
      if (teamStat) {
        const filters = generateTeamFilters(teamStat);
        setTeamFilters(filters);
      }
    }, [teamStat]);

    const onTeamPageChange = (page, pageSize) => {
      setCurrentTeamPage(page)
      setCurrentTeamPageSize(pageSize)
    }

    const onPlayerPageChange = (page, pageSize) => {
      setCurrentPlayerPage(page)
      setCurrentPlayerPageSize(pageSize)
    }

  return (
    <Wrapper>
      <PageTitle>
          <FontAwesomeIcon icon={faChartSimple} size='xl' style={{marginRight:'3px'}}/>통계
      </PageTitle>
      <SelectTitleBox>
          <SelectTitle>대회</SelectTitle>
          <SelectTitle>경기 그룹</SelectTitle>
      </SelectTitleBox>
      <SelectBox>
          <Select style={{ width: '456px' }} value={selectedComp} onChange={onCompChange}>
              {compList?.map((a,i)=>{
                  return <Option key={a.id+'compid'} value={a.id}>{a.name}</Option>
              })}
          </Select>
          <Select 
            style={{ width: '278px', marginLeft:'16px', lineHeight:'52px' }} 
            value={selectedGroup} 
            onChange={onGroupChange} 
            allowClear
            placeholder='경기 그룹을 선택해 보세요'
          >
            <Option key='allGroupOption' value='전체 그룹'>전체 그룹</Option>
            {filteredGroupNameList?.map((a,i)=>{
                return <Option key={a+'groupid'} value={a}>{a}</Option>
            })}
          </Select>
      </SelectBox>

      <TitleBox>
        <Title><Tooltip title='취소된 경기, 기록지가 입력되지 않은 경기는 통계에 포함되지 않습니다.'>팀<SToolIcon/></Tooltip></Title>
      </TitleBox>
      
      <Table dataSource={sortedTeamStat} 
        pagination={{defaultPageSize: 10, position: ['bottomRight'], hideOnSinglePage: false, showSizeChanger:true, onChange:(page, pageSize)=>{onTeamPageChange(page, pageSize)}}}
        rowClassName={rowClassName} 
        rowKey={(record)=>record.id}
        showSorterTooltip={false}
      >
        <Column title={<Tooltip title='대회 실제 순위와 다를 수 있습니다'>#<SToolIcon/></Tooltip>} key="rank"
          render={(text, record, index) =>
            (index + 1) + currentTeamPageSize * (currentTeamPage - 1)
          }
        />
        <Column title="팀이름" dataIndex="name" key="name"/>
        <Column title={<Tooltip title='경기 기록지가 입력된 경기(몰수 경기 포함)'>경기수<SToolIcon/></Tooltip>} dataIndex="gamePlayed" key="gamePlayed"
          sorter={(a, b) => a.gamePlayed - b.gamePlayed}/>
        <Column title={<Tooltip title='몰수승이 포함된 경기'>승<SToolIcon/></Tooltip>} dataIndex="win" key="win"
          sorter={(a, b) => a.win - b.win}/>
        <Column title="무" dataIndex="draw" key="draw"
          sorter={(a, b) => a.draw - b.draw}/>
        <Column title={<Tooltip title='몰수패가 포함된 경기'>패<SToolIcon/></Tooltip>} dataIndex="lose" key="lose"
          sorter={(a, b) => a.lose - b.lose}/>
        <Column title={<Tooltip title='자책골과 몰수득점이 포함된 득점'>득점<SToolIcon/></Tooltip>} dataIndex="goal" key="goal"
          sorter={(a, b) => a.goal - b.goal}/>
        <Column title="실점" dataIndex="goalConceded" key="goalConceded"
          sorter={(a, b) => a.goalConceded - b.goalConceded}/>
        <Column title="득실차" key="goalDifference" 
          render={(record, index) => (record.goal - record.goalConceded)}
          sorter={(a, b) => (a.goal - a.goalConceded) - (b.goal - b.goalConceded)}
        />
        <Column title="실점률" key="goalConcededPerGame"
          render={(record, index) => (
            record.gamePlayed === 0
              ? 0
              : (record.goalConceded / record.gamePlayed).toFixed(3)
          )}
          sorter={(a, b) => parseFloat(a.gamePlayed === 0 ? 0 : (a.goalConceded / a.gamePlayed)) - parseFloat(b.gamePlayed === 0 ? 0 : (b.goalConceded / b.gamePlayed))}
        />
        <Column title="도움" dataIndex="assist" key="assist"
          sorter={(a, b) => a.assist - b.assist}/>
        <Column title="경고" dataIndex="yellowCard" key="yellowCard"
          sorter={(a, b) => a.yellowCard - b.yellowCard}/>
        <Column title="퇴장" dataIndex="redCard" key="redCard"
          sorter={(a, b) => a.redCard - b.redCard}/>
        <Column title="PK득점" dataIndex="pkGoal" key="pkGoal"
          sorter={(a, b) => a.pkGoal - b.pkGoal}/>
        <Column title="FK득점" dataIndex="fkGoal" key="fkGoal"
          sorter={(a, b) => a.fkGoal - b.fkGoal}/>
        <Column title={<Tooltip title='대회에 참가 승인된 선수'>참가 인원<SToolIcon/></Tooltip>} dataIndex="participantsCount" key="participantsCount"
          sorter={(a, b) => a.participantsCount - b.participantsCount}/>
      </Table>

      <TitleBox>
        <Title><Tooltip title='선수 기록은 참여한 경기(QR) 기준입니다'>선수<SToolIcon/></Tooltip></Title>
      </TitleBox>
      <Table dataSource={sortedPlayerStat} 
        pagination={{defaultPageSize: 10, position: ['bottomRight'], hideOnSinglePage: false, showSizeChanger:true, onChange:(page, pageSize)=>{onPlayerPageChange(page, pageSize)}}}
        rowClassName={rowClassName}
        rowKey={(record)=>record.id}
        showSorterTooltip={false}
      >
        <Column title="#" key="rank"
          render={(text, record, index) =>
            (index + 1) + currentPlayerPageSize * (currentPlayerPage - 1)
          }
        />
        <Column title="팀이름" key="teamName"
          render={(record, index) => (record.team.name)}
          filters={teamFilters} onFilter={(value, record) => record.team.name === value}
        />
        <Column title="포지션" dataIndex="position" key="position"
          filters={포지션필터} onFilter={(value, record) => record.position.startsWith(value)}
        />
        <Column title="선수이름" dataIndex="name" key="name" className='playerNameCol' />
        <Column title={<Tooltip title='자책골과 승부차기가 제외된 득점'>득점<SToolIcon/></Tooltip>} dataIndex="goal" key="goal"
          sorter={(a, b) => a.goal - b.goal}/>
        <Column title="도움" dataIndex="assist" key="assist"
          sorter={(a, b) => a.assist - b.assist}/>
        <Column title="공격포인트" key="goalAssist"
          render={(record, index) => (record.goal + record.assist)}
          sorter={(a, b) => (a.goal + a.assist) - (b.goal + b.assist)}
        />
        <Column title={<Tooltip title='경기 기록지가 입력된 경기(몰수 경기 포함) 중 QR코드를 찍어 참여한 경기'>경기수<SToolIcon/></Tooltip>} dataIndex="gamePlayed" key="gamePlayed"
          sorter={(a, b) => a.gamePlayed - b.gamePlayed}/>
        <Column title="출장시간(분)" dataIndex="playingTime" key="playingTime"
          sorter={(a, b) => a.playingTime - b.playingTime}/>
        <Column title="경고" dataIndex="yellowCard" key="yellowCard"
          sorter={(a, b) => a.yellowCard - b.yellowCard}/>
        <Column title="퇴장" dataIndex="redCard" key="redCard"
          sorter={(a, b) => a.redCard - b.redCard}/>
        <Column title="경기당득점률" key="goalPerGame"
          render={(record, index) => (
            record.gamePlayed === 0
              ? 0
              : (record.goal / record.gamePlayed).toFixed(2)
          )}
          sorter={(a, b) => parseFloat(a.gamePlayed === 0 ? 0 : (a.goal / a.gamePlayed)) - parseFloat(b.gamePlayed === 0 ? 0 : (b.goal / b.gamePlayed))}
        />
        <Column title="평균득점시간(분)" key="goalPerMinute"
          render={(record, index) => (
            record.goal === 0
              ? 0
              : (record.playingTime / record.goal).toFixed(0)
          )}
          sorter={(a, b) => parseFloat(a.goal === 0 ? null : (a.playingTime / a.goal)) - parseFloat(b.goal === 0 ? null : (b.playingTime / b.goal))}
        />
        <Column title="PK득점" dataIndex="pkGoal" key="pkGoal"
          sorter={(a, b) => a.pkGoal - b.pkGoal}/>
        <Column title="FK득점" dataIndex="fkGoal" key="fkGoal"
          sorter={(a, b) => a.fkGoal - b.fkGoal}/>
        <Column title={<Tooltip title='경기 기록지가 입력된 경기(몰수 경기 포함) 중 QR코드를 찍어 참여한 경기'>승<SToolIcon/></Tooltip>} dataIndex="win" key="win"
          sorter={(a, b) => a.win - b.win}/>
        <Column title={<Tooltip title='경기 기록지가 입력된 경기 중 QR코드를 찍어 참여한 경기'>무<SToolIcon/></Tooltip>} dataIndex="draw" key="draw"
          sorter={(a, b) => a.draw - b.draw}/>
        <Column title={<Tooltip title='경기 기록지가 입력된 경기(몰수 경기 포함) 중 QR코드를 찍어 참여한 경기'>패<SToolIcon/></Tooltip>} dataIndex="lose" key="lose"
          sorter={(a, b) => a.lose - b.lose}/>
      </Table>
    </Wrapper>
  )
}

export default  StatMain

const Wrapper = styled.div`
.playerNameCol {
  min-width: 90px;
}
`

const SelectBox = styled.div`
margin-bottom: 39px;
display: flex;
.ant-select-selector {
  height: 52px !important;
  line-height: 52px !important;
}

.ant-select-selection-item {
  line-height: 52px !important;
}

.ant-select-selection-placeholder {
  line-height: 52px !important;
}
`

const SelectTitleBox = styled.div`
margin-bottom: 12px;
:first-child {
  margin-right: 442px;
}
`;

const SelectTitle = styled.span`
color: #1F1F1F;
font-family: 'NanumSquareNeo800';
font-size: 16px; 
font-weight: 800;
`