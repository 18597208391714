import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useValidateApplyMember() {
  const baseUrl = getBaseUrl();
  const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
  const { teamId, compId } = useParams(); 

    const validateApplyMember = async (id, value, groupValue) => {
        const requestBody = {
          'teamId': parseInt(teamId, 10),
            'applyMember': [
              {
                'id': id,
                'isValidated': value,
              }
            ]
          };
        const groupRequestBody = {
          'teamId': parseInt(teamId, 10),
          'applyMember': groupValue
        };
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            const response = await axios.put(`${baseUrl}/competition/${compId}/apply-member`, 
            id ? requestBody : groupRequestBody, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

        } catch (error) {
            console.error(error);
        }
    };

    return { validateApplyMember };
}

export default useValidateApplyMember;
