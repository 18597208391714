import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useGetApplyMember() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [memberList, setMemberList] = useState(null);
    const { compId, teamId } = useParams();

    const getApplyMember = async () => {
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            const response = await axios.get(`${baseUrl}/competition/${compId}/apply-member?teamId=${teamId}`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setMemberList(response.data.data[0].applyMember);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getApplyMember();
    }, []);

    return { memberList, getApplyMember };
}

export default useGetApplyMember;
