import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useReadMatch() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [matchContent, setMatchContent] = useState(null);
    const { matchId } = useParams();
    
    const getMatchContent = async () => {
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            const response = await axios.get(`${baseUrl}/match-schedule/${matchId}`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setMatchContent(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }; 

    useEffect(() => {
        getMatchContent();
    }, []);

    return { matchContent };
}

export default useReadMatch;
