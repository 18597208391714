export const ACCESS_TOKEN = 'wedidAcstk';
export const REFRESH_TOKEN = 'wedidRfrshtk';

export const prodUrl = 'https://admin-wedid-api.projectwith.com/api/v1';
export const devUrl = 'https://admin-wedid-api-dev.projectwith.com/api/v1';

export function getBaseUrl() {
  return localStorage.getItem('baseUrl') || prodUrl;
}

export function changeBaseUrl() {
  let orin = localStorage.getItem('baseUrl');
  if(orin === devUrl) {
    //실서버로
    console.log('PROD serv ..');
    localStorage.setItem('baseUrl', prodUrl);  
    return 'PROD'
  } else {
    //개발서버로
    console.log('DEV serv ..');
    localStorage.setItem('baseUrl', devUrl);
    return 'DEV'
  }
}