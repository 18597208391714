import { useEffect, useState } from 'react';
import axios from 'axios'; 
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../../common/api';

function useGetAllTeam() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [allTeamList, setAllTeamList] = useState(null);

    const getAllTeam = async (id) => {
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                'Authorization': `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
                'Content-type':'application/json'
            };

            const response = await axios(`${baseUrl}/competition/${id}/team`, {
                method:'GET',
                headers,
            });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setAllTeamList(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    return { allTeamList, getAllTeam };
}

export default useGetAllTeam;
