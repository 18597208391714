import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs';
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useCreateRecord() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const { matchId } = useParams();
    const [recordResult, setRecordResult] = useState();
    
    const createRecord = async (values, createId,) => {
        const homeGoal = values.goal_home || [];
        const awayGoal = values.goal_away || [];
        const homeOwnGoal = values.ownGoal_home || [];
        const awayOwnGoal = values.ownGoal_away || [];
        const homeShootOutGoal = values.shootOut_home || [];
        const awayShootOutGoal = values.shootOut_away || [];
        const homeSubstitute = values.substitute_home || [];
        const awaySubstitute = values.substitute_away || [];
        const homeCard = values.penalty_home || [];
        const awayCard = values.penalty_away || [];

        const home1 = parseInt(values.homeTeamFirstHalfTimeScore);
        const home2 = parseInt(values.homeTeamSecondHalfTimeGoal);
        const home3 = parseInt(values.homeTeamExtraTimeGoal); 
        const away1 = parseInt(values.awayTeamFirstHalfTimeScore);
        const away2 = parseInt(values.awayTeamSecondHalfTimeGoal);
        const away3 = parseInt(values.awayTeamExtraTimeGoal);

        const goalMapping = (goal, isHomeTeam) => goal.map(a => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime || null,
            memberRecordId: a.memberRecordId,
            assistMemberRecordId: a.assistMemberRecordId || null,
            goalType: a.goalType || null,
            isHomeTeam,
        }));

        const ownGoalMapping = (ownGoal, isHomeTeam) => ownGoal.map(a => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime || null,
            memberRecordId: a.memberRecordId,
            isHomeTeam,
        }));

        const shootOutGoalMapping = (shootOutGoal, isHomeTeam) => shootOutGoal.map(a => ({
            recordTime: 0,
            recordOrder: a.recordOrder,
            memberRecordId: a.memberRecordId,
            isGoal: a.isGoal,
            isHomeTeam,
        }));

        const subMapping = (substitute, isHomeTeam, type) => substitute.map(a => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime || null,
            memberRecordId: type==='IN'? a.memberRecordId_IN : a.memberRecordId_OUT,
            substituteType: type==='IN'? 'IN' : 'OUT',
            isHomeTeam,
            // substituteMemberRecordId: a.substituteMemberRecordId,
            substituteMemberRecordId: type==='IN'? a.memberRecordId_OUT : a.memberRecordId_IN,
        }));

        const cardMapping = (card, isHomeTeam) => card.map(a => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime || null,
            memberRecordId: a.memberRecordId,
            cardType: a.cardType,
            isHomeTeam,
        }));

        const requestBody = {
            "homeTeamFirstHalfTimeScore": values.homeTeamFirstHalfTimeScore,
            "awayTeamFirstHalfTimeScore": values.awayTeamFirstHalfTimeScore,
            "homeTeamSecondHalfTimeGoal": values.homeTeamSecondHalfTimeGoal,
            "awayTeamSecondHalfTimeGoal": values.awayTeamSecondHalfTimeGoal,

            "homeTeamExtraTimeGoal": values.homeTeamExtraTimeGoal,
            "awayTeamExtraTimeGoal": values.awayTeamExtraTimeGoal,

            "homeTeamScore": 
            values.awayFor ? 3 
            : values.homeFor ? 0
            : home1 + home2 + (values.homeTeamExtraTimeGoal ? home3 : 0),  
            "awayTeamScore": 
            values.homeFor ? 3 
            : values.awayFor ? 0 
            : away1 + away2 + (values.awayTeamExtraTimeGoal ? away3 : 0),

            "firstHalfTime": values.firstHalfTime,  
            "secondHalfTime": values.secondHalfTime, 
            
            "homeTeamShootoutScore": values.homeTeamShootoutScore, 
            "awayTeamShootoutScore": values.awayTeamShootoutScore,  

            "firstHalfAddedTime": values.firstHalfAddedTime || 0,  
            "secondHalfAddedTime": values.secondHalfAddedTime || 0, 

            "firstHalfExtraTime": values.firstHalfExtraTime || 0, 
            "firstHalfExtraAddedTime": values.firstHalfExtraAddedTime || 0,  
            "secondHalfExtraTime": values.secondHalfExtraTime || 0,  
            "secondHalfExtraAddedTime": values.secondHalfExtraAddedTime || 0,  
            "endType": (values.awayFor || values.homeFor) ? 'FORFEIT_LOSS' : 'NORMAL',
            "recordNumber": values.recordNumber, 
            "endAt": dayjs(values.endAt).valueOf(),
            "referee": values.referee, 
            "assistantReferee1": values.assistantReferee1, 
            "assistantReferee2": values.assistantReferee2, 
            "fourthOfficial": values.fourthOfficial, 
            "recorder": values.recorder, 
            "matchCommissioner": values.matchCommissioner,
            "detail": values.detail,
            
            "record": {
                "goal": [
                    ...goalMapping(homeGoal, true),
                    ...goalMapping(awayGoal, false),
                ],
                "ownGoal": [
                    ...ownGoalMapping(homeOwnGoal, true),
                    ...ownGoalMapping(awayOwnGoal, false),
                ],
                "penaltyShootout": [
                    ...shootOutGoalMapping(homeShootOutGoal, true),
                    ...shootOutGoalMapping(awayShootOutGoal, false),
                ],
                "substitute": [
                    ...subMapping(homeSubstitute, true, 'IN'),
                    ...subMapping(homeSubstitute, true, 'OUT'),
                    ...subMapping(awaySubstitute, false, 'IN'),
                    ...subMapping(awaySubstitute, false, 'OUT'),
                ],
                "penalty": [
                    ...cardMapping(homeCard, true),
                    ...cardMapping(awayCard, false),
                ],
            },
        }
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            let response;
            if (createId) {
                // Create Record
                response = await axios.post(`${baseUrl}/match-schedule/${createId}/score-sheet`, requestBody, { headers });
            } else {
                // Update Record
                response = await axios.put(`${baseUrl}/match-schedule/${matchId}/score-sheet`, requestBody, { headers });
            }

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setRecordResult(response.data.data);
        } catch (error) {
            console.error(error);
            setRecordResult(false)
        }
    };

    return { recordResult, createRecord, setRecordResult };
}

export default useCreateRecord;
