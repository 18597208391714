import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useEditAccount() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [editAccountResult, setEditAccountResult] = useState(null);
    
    const editAccount = async (values) => {
        setEditAccountResult(null)
        const requestBody = {
            "loginId": values.id, 
            "password" : values.pw,
        }
        try {
            const headers = {
                Authorization: `Bearer ${cookies[ACCESS_TOKEN]}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };
            
            const response = await axios.put(`${baseUrl}/admin-member`, requestBody, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setEditAccountResult(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    return { editAccount, editAccountResult };
}

export default useEditAccount;
