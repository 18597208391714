import logo from '../assets/img/wedid_logo.png'
import logo_with from '../assets/img/logo_with.png'
import main_logo from '../assets/img/wedid_main_logo.png'
import card from '../assets/img/foulCardIcon.svg' 
import main_banner from '../assets/img/main_banner.png'
import background from '../assets/img/background.png'
import background_w from '../assets/img/background_w.webp'
import detail from '../assets/img/skeletone_detail.png'
import trophy from '../assets/img/icon_trophy.svg'
import home from '../assets/img/icon_home.svg'
import logout from '../assets/img/icon_logout.svg'
import arrow from '../assets/img/icon_arrow.svg'
import print from '../assets/img/icon_print.svg'
import subsArrow from '../assets/img/icon_subsArrow.svg'
import o from '../assets/img/icon_o.svg'
import x from '../assets/img/icon_x.svg'

const images = {
logo : logo,
logo_with : logo_with,
main_logo : main_logo,
card : card, 
main_banner : main_banner,
background : background,
background_w : background_w,

icon : {
    home : home,
    trophy : trophy,
    logout : logout,
    arrow : arrow,
    print : print,
    subsArrow : subsArrow,
    o : o,
    x : x,
},

skeletone : {
    detail : detail,
},
}

export default images;