import React from 'react'
import { styled } from 'styled-components'
import { Table, Input, Button} from 'antd';

const StyleAtomsRead = () => {
  
    return { Container, BannerBox, DetailBoxContainer, DetailBox, InfoContainer, Box, Row ,
        Col, ColLong, InfoTextContainer,   }
  }

export default StyleAtomsRead

const Container = styled.div`
display: flex;
border-radius: 6px;
background: #FAFAFA;
min-height: 341px;
padding: 25px 24px 28px;
`;

const BannerBox = styled.div`
width: 100%;
min-height: 157px;
margin-bottom: 19px;
img{
    width: 100%;
}
`;

const DetailBoxContainer = styled.div`
display: flex;
gap: 13px;
`

const DetailBox = styled.div`
/* width: 33%; */
min-height: 112px;
img{
    width: 100%;
}
`

const InfoContainer = styled.div`
margin-left: 24px;
width: 100%;
`;

const Box = styled.div`
flex: 1;
`

const Row = styled.div`
display: flex;
padding-top: 25px;
padding-bottom: 25px;
font-size: 14px;
border-bottom: 1px solid #E0E0E0;
`;

const Col = styled.div`
width: 33%;
color: #818181;
`;

const ColLong = styled.div`
width: 67%;
color: #3D3D3D;
padding-right: 10px;
white-space: nowrap; 
overflow: hidden; 
text-overflow: ellipsis;
`;

const InfoTextContainer = styled.div`
display: flex;
`;