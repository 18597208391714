import React from 'react'
import { Button, Result } from 'antd';
import { useLocation, useNavigate  } from 'react-router-dom';
import queryString from 'query-string';

const ActionResult = () => {
    const navigate = useNavigate(); 
    const location = useLocation();
    const { compId, compName } = queryString.parse(location.search);
    
    const goBack = () => {
      // if(compId==='comp') {
      //   navigate('/comp'); 
      // } else {
      //   navigate(`/comp/${compId}?compName=${compName}`); 
      // }
      navigate('/comp');
    };

  return (
    <Result
        status="success"
        title="성공적으로 삭제됐습니다"
        subTitle=''
        extra={[
          <Button type="primary" key="console" onClick={goBack}>목록으로 가기</Button>,
        ]}
    />
  )
}

export default ActionResult