import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useGetMatchList() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [matchList, setMatchList] = useState(null);
    const { compId } = useParams();
    
    const getMatchList = async (compIdSelection) => {
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            const response = await axios.get(`${baseUrl}/match-schedule?competitionId=${compId || compIdSelection}`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setMatchList(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(compId) {
            getMatchList();
        }
    }, []);

    return { matchList, getMatchList };
}

export default useGetMatchList;
