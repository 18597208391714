import React from 'react'
import { styled } from 'styled-components'
import { Table, Input, Button} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


const StyleAtoms = () => {

  const RedDot = () => (
    <RedDotStyle>•</RedDotStyle>
  );

  const SToolIcon = () => {
    return <QuestionCircleOutlined style={{verticalAlign:'super', fontSize:'10px', color:'#999999', marginLeft:'10px'}} />
  }

  return {PageTitle, SearchBox, TitleBox, Title, Title2, SubTitle, CompTitle,
    RedText, SSearch, BlueImg, InvertImg, GreyText, BlackText, ButtonBox,
    RedDot, SumBox, SumCol, SumColBl, SToolIcon }
}

export default StyleAtoms

const PageTitle = styled.div`
margin-bottom: 31px;
display: flex;
align-items: center;
height: 20px;
line-height: 20px;
font-size: 14px;
  img { 
    margin-right: 3px;
    width: 20px;
  }
  .blue {
    color : #498AFF;
  }
  .pointer {
    cursor: pointer;
  }
`;

const BlueImg = styled.img`
filter: invert(63%) sepia(60%) saturate(5302%) hue-rotate(200deg) brightness(97%) contrast(109%) !important;
`;

const InvertImg = styled.img`
filter: invert(100%); 
`;

const SearchBox = styled.div`
margin-bottom: 39px;
text-align: left;
`;

const TitleBox = styled.div`
display: flex;
  button {
    margin-right: 6px;
    margin-bottom: 5px;
  }
`;

const ButtonBox = styled.div`
display: flex;
margin-left: auto;
margin-right: 8px;
`;

const Title = styled.div`
color: #1F1F1F;
font-family: 'NanumSquareNeo800';
font-size: 20px; 
font-weight: 800; 
margin-bottom: 19px;
`;

const SubTitle = styled.div`
color: #999;
font-size: 12px;
margin-top: 20px;
margin-bottom: 10px;
`;

const CompTitle = styled.div`
color: #323232;
font-family: 'NanumSquareNeo800';
font-size: 18px;
font-weight: 800;
margin-bottom: 23px;
`;


const BlackText = styled.span`
color: black !important;
`;

const RedText = styled.div`
color: #FF8667;
`;

const GreyText = styled.span`
font-size: 10px;
color: #8D8D8D;
`;

const SSearch = styled(Input.Search)`
width: 456px;
  .ant-input{
    height: 52px;
  } 
  .ant-btn {
    width: 52px;
    height: 52px;
  } 
  .ant-select-selector {
    height: 52px;
  }
`;

const RedDotStyle = styled.span`
vertical-align: super;
color: #EB543B;
margin-left: 3px;
`;

const SumBox = styled.div`
display: flex;
margin-bottom: 14px;
gap: 14px;
`;

const SumCol = styled.div`
font-size: 14px;
line-height: 31px;
min-width: 100px;
padding-left: 15px;
padding-right: 15px;
background-color: rgb(250,250,250);
display: flex;
border-radius: 8px;
:last-child {
  text-align: right;
  min-width: 40px;
}
`

const SumColBl = styled(SumCol)`
background-color: #2D2D2D;
color: white;
`

const Title2 = styled.div`
color: #1F1F1F;
font-family: 'NanumSquareNeo800';
font-size: 20px;  
margin-bottom: 15px;
`;

