import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Table, Input, Button} from 'antd';
import { Link } from 'react-router-dom';
import useGetCompList from '../../hooks/Comp/useGetCompList';
import images from '../../common/images';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import StyleAtoms from '../../common/StyleAtoms';
import {대회상태번역기, 대회상태필터} from '../../common/util'

const CompList = () => {
  const { Column } = Table;
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { compList } = useGetCompList();
  const {PageTitle, SearchBox, TitleBox, Title, GreyText, SSearch, InvertImg, ButtonBox } = StyleAtoms();

  const rowClassName = (record, index) => {
    if (index % 2 !== 0) {
      // 짝수 로우 클래스
      return 'even-row';
    }
    return '';
  };

  const handleSearch = () => {
    const searchTextLower = searchText.toLowerCase();  
    const filtered = compList?.filter(item => item.name.toLowerCase().includes(searchTextLower));
    setFilteredData(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  
  return (            
    <>
    <PageTitle>
      <InvertImg src={images.icon.trophy} alt='' />대회
    </PageTitle>
    <SearchBox>
      <SSearch
        placeholder="대회 이름을 검색해 보세요"
        onChange={handleSearchChange}
        onSearch={handleSearch}
        value={searchText}
      />
    </SearchBox>

    <TitleBox>
      <Title>대회 리스트</Title>
      <ButtonBox>
        <Link to={`/comp/compCreate`}><Button type='primary'>대회 생성</Button></Link>
      </ButtonBox>
    </TitleBox>
    
    <Table dataSource={filteredData || compList} 
      pagination={{defaultPageSize: 8, position: ['bottomRight'], hideOnSinglePage: false, showSizeChanger:false}}
      rowClassName={rowClassName}
      rowKey={(record)=>record.id}
    >
      <Column title="대회 이름" dataIndex="name" key="name"
        render={(text, record) => (
          <Link to={`/comp/${record.id}?compName=${encodeURIComponent(text)}`} style={{ color: 'black' }}>{text}</Link>
      )}/>
      <Column title="대회 생성일" dataIndex="createdAt" key="createdAt" sorter={(a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA - dateB;
        }}
      render={(createdAt) => (
        <span>
          {dayjs(createdAt).format(`YYYY.MM.DD(dd)`)}
        </span>
      )}/>
      <Column title="대회 일정" key="startAt" sorter={(a, b) => {
          const dateA = new Date(a.startAt);
          const dateB = new Date(b.startAt);
          return dateA - dateB;
        }}
        render={(record) => {
          const startDate = dayjs(record.startAt);
          const endDate = dayjs(record.endAt);
          const daysDiff = endDate.diff(startDate, 'day'); 
          const dateRange = `${startDate.format('YYYY.MM.DD(ddd)')} ~ ${endDate.format('YYYY.MM.DD(ddd)')} `;
          return <span>{dateRange} <GreyText>&#40;{daysDiff + 1}일간&#41;</GreyText></span>;
        }}/>

      <Column title="대회 상태" dataIndex="competitionState" key="competitionState" 
      filters={대회상태필터} onFilter={(value, record) => record.competitionState.startsWith(value)} 
      render={(state)=>(
        <StateColor state={state}>{대회상태번역기(state)}</StateColor>
      )}/>
      <Column
        title="참가 팀 리스트"
        key="player"
        render={(record) => (
          <Link to={`/comp/${record.id}/team?compName=${encodeURIComponent(record.name)}`} >참가 팀 리스트</Link>
        )}
      />
    </Table>
    </>
  )
}

export default CompList

const StateColor = styled.span`
color: ${props =>
  props.state === 'UPCOMING' || props.state === 'ONGOING'
    ? '#20D167'
    : props.state === 'CLOSED' || props.state === 'CANCELED'
    ? '#8c8c8c'
    : 'black'};
`;