import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Input, Form, Descriptions, DatePicker, Select, Button } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR'; 
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import queryString from 'query-string';
import UpdateModal from '../../components/commons/UpdateModal';
import DeleteModal from '../../components/commons/DeleteModal';
import useReadMatch from '../../hooks/Match/useReadMatch';
import useCreateMatch from '../../hooks/Match/useCreateMatch';
import useDeleteMatch from '../../hooks/Match/useDeleteMatch';
import useCancelMatch from '../../hooks/Match/useCancelMatch';
import Notification from '../../components/commons/Notification';
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import Navigation from '../../common/Navigation';

import useCallbackPrompt from '../../hooks/useCallbackPrompt'
import {useBackListener} from '../../hooks/useBlocker'
import {
  Location,
  NavigationType,
  // useLocation,
  useNavigationType,
} from "react-router-dom";
import CancelModal from '../../components/commons/CancelModal';
import { adminTypeChecker } from '../../common/util';
import { useCookies } from 'react-cookie';
import { ACCESS_TOKEN } from '../../common/api';

const MatchDetailEdit = () => {
    const {PageTitle, BlueImg, TitleBox, Title, SubTitle, InvertImg, CompTitle, RedDot } = StyleAtoms();
    const { Option } = Select;
    const { compId, matchId } = useParams();
    const navigate = useNavigate(); 
    const location = useLocation();
    const { compName } = queryString.parse(location.search);
    const [form] = Form.useForm();
    const { matchContent } = useReadMatch();
    const { matchResult, createMatch, setMatchResult } = useCreateMatch();
    const { deleteMatchResult, deleteMatch, setDeleteMatchResult } = useDeleteMatch();
    const { cancelMatchResult, cancelMatch, setCancelMatchResult } = useCancelMatch();
    const { contextHolder, successNoti, errorNoti, loadingNoti, closeLoadingNoti } = Notification();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const adminType = adminTypeChecker(cookies);
    const isCanceled = matchContent?.matchStatus === 'CANCEL'
    const [isFormModified, setIsFormModified] = useState(false);
    const {goComp, goCompList} = Navigation();
    const sample = useCallbackPrompt(true);
    const navType = useNavigationType();

    const onCancelSubmit = () => {
      loadingNoti()
      cancelMatch()
    };

    const onUpdateSubmit = (values) => {
      window.scrollTo(0, 0)
      loadingNoti()
      createMatch(values)
    };
  
    const onDeleteSubmit = () => {
      loadingNoti()
      deleteMatch()
    };

    useEffect(()=>{
      form.setFieldsValue({ 
        name: matchContent?.name, 
        location: matchContent?.location,
        startAt: dayjs(matchContent?.startAt), 
        matchType: matchContent?.matchType,
        groupName: matchContent?.groupName,
      });
      form.isFieldsTouched() && setIsFormModified(true);
    },[matchContent])

    useEffect(()=>{
      //에러핸들링
      if ((matchResult || cancelMatchResult) === false) {
        closeLoadingNoti()
        errorNoti();
      } else if ((matchResult || cancelMatchResult) === true) {
        closeLoadingNoti()
        if(matchResult === true) {
          successNoti('수정');
        } else {
          successNoti('취소');
        }
        setIsFormModified(false);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
      setMatchResult(null);
      setCancelMatchResult(null);
    }, [matchResult, cancelMatchResult])

    useEffect(()=>{
      //에러핸들링
      if(deleteMatchResult === false){
        closeLoadingNoti()
        errorNoti();
        setDeleteMatchResult(null);
      } else if (deleteMatchResult === true) {
        closeLoadingNoti()
        navigate(`/actionResult?compId=${encodeURIComponent(compId)}?compName=${encodeURIComponent(compName)}`, { replace: true });
      }
    }, [deleteMatchResult])

    const items = [
        {
          key: '1',
          label: 
          <>
            <DescTitle>경기명<RedDot/></DescTitle>
            <br/>
            <DescSubTitle>*최대 50자</DescSubTitle>
          </>,
          children: 
          <Form.Item name='name' rules={[{ required: true, message:'경기명 입력 필요' }]}>
            <Input placeholder='경기명을 입력해주세요' disabled={isCanceled} />
          </Form.Item>,
          span:4,
        },
        {
          key: '2',
          label: 'HOME',
          children: matchContent?.homeTeam.name, 
          span:2,
        },
        {
          key: '3',
          label: 'AWAY',
          children: matchContent?.awayTeam.name, 
          span:2,
        },
        {
          key: '4',
          label: <span>경기 시작 일시<RedDot/></span>,
          children: 
          <Form.Item name='startAt' rules={[{ required: true, message:'시작시간 입력 필요' }]}>
            <DatePicker showTime format='YYYY-MM-DD HH:mm' locale={locale} style={{width:'100%'}} disabled={isCanceled} />
          </Form.Item>,
            span:2,
        },
        {
          key: '5',
          label:
          <>
            <DescTitle>경기 장소<RedDot/></DescTitle>
            <br/>
            <DescSubTitle>*최대 50자</DescSubTitle>
          </>,
          children: 
          <Form.Item name='location' rules={[{ required: true, message:'경기 장소 입력 필요' }]}>
            <Input placeholder='경기장을 입력해주세요' disabled={isCanceled} />
          </Form.Item>,
          span:2,
        },
        {
          key: '6',
          label: <span>경기 종류<RedDot/></span>,
          children: 
          <Form.Item name='matchType' rules={[{ required: true, message:'경기종류 선택 필요' }]}>
            <Select placeholder='경기 종류를 선택해주세요' allowClear disabled={isCanceled}>
              <Option value="LEAGUE">리그</Option>
              <Option value="TOURNAMENT">토너먼트</Option>
            </Select>
          </Form.Item>,
          span:2,
        },
        {
          key: '7',
          label: 
          <>
            <DescTitle>경기 그룹</DescTitle>
            <br/>
            <DescSubTitle>*최대 30자</DescSubTitle>
          </>,
          children: 
          <Form.Item name='groupName'>
            <Input placeholder='경기 그룹 입력해주세요' disabled={isCanceled}/>
          </Form.Item>,
          span:2,
        },
    ];
      
  return (
    <>
    {contextHolder}
    <PageTitle>
        <InvertImg src={images.icon.trophy} alt='' />
        <span onClick={goComp} className='pointer'>대회</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
        <BlueImg src={images.icon.arrow} alt='' />
        <span className='blue'>경기 정보 수정</span>
    </PageTitle>
    <TitleBox>
        <Title>경기 정보 수정</Title>
    </TitleBox>
    <SubTitle>대회 이름</SubTitle>
    <FlexBox>
      <CompTitle>{compName}</CompTitle>
      {adminType==='superUser'
      ?
      <Link to={`${window.location.pathname}/lineupEdit?compName=${encodeURIComponent(compName)}&homeTeamName=${encodeURIComponent(matchContent?.homeTeam.name)}&awayTeamName=${encodeURIComponent(matchContent?.awayTeam.name)}`}>
        <Button type='primary'>출전 선수 명단 관리</Button>
      </Link>
      : null}
    </FlexBox>
    

    <Form form={form} onFinish={onUpdateSubmit}>
        <Descriptions bordered items={items} style={{marginBottom:'16px'}} />
        <Container>
          <div style={{marginRight:'auto'}}>
            <CancelModal onOk={onCancelSubmit} inactive={isCanceled} />
          </div>
          <DeleteModal onOk={onDeleteSubmit} />
          <UpdateModal form={form} inactive={isCanceled}/>
        </Container>
    </Form>
    </>
  )
}

export default MatchDetailEdit

const Container = styled.div`
padding-right: 14px;
display: flex;
justify-content: flex-end;
width: 100%;
gap: 20px;
`; 

const DescTitle = styled.span`
padding-bottom: 15px;
`;

const DescSubTitle = styled.span`
color: #498AFF;
font-size: 9px;
`;

const FlexBox = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
padding-right: 14px;
`
