import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Memo = () => {

  return (
    <Wrapper>
        <Title>메모</Title>
        <Input.TextArea rows={13} style={{ height: 'calc(33vh - 55px)' }} bordered={false} spellCheck={false}/>
    </Wrapper>
  );
};

export default Memo;

const Wrapper = styled.div`
background-color: white;
width: 260px;
height: 33vh;
margin: 20px;
border-radius: 10px;
position: sticky;
top: calc(60vh + 40px);
overflow-y: auto;
filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
&::-webkit-scrollbar {
  width: 0.5em;
}

&::-webkit-scrollbar-thumb {
  background-color: transparent;
}
`

const Title = styled.div`
margin-top: 30px;
margin-bottom: 15px;
font-size: 16px;
text-align: center;
height: 10px;
`
