import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useGetPlayerStat() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [playerStat, setPlayerStat] = useState(null);
    
    const getPlayerStat = async (compId, groupName) => {
        try {
            const headers = {
                Authorization: `Bearer ${cookies[ACCESS_TOKEN]}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            const callUrl = groupName ? 
            `${baseUrl}/competition/${compId}/dashboard/member?groupName=${groupName}`
            : `${baseUrl}/competition/${compId}/dashboard/member` 
            
            const response = await axios.get(callUrl, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setPlayerStat(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    return { getPlayerStat, playerStat };
}

export default useGetPlayerStat;
