import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Descriptions, Switch, } from 'antd';

const RecordResult = ({ form, recordContent, home, away, createRecord, matchId }) => {
    const [forfeitureSwitch, setForfeitureSwitch] = useState(false);
    const [extraSwitch, setExtraSwitch] = useState(false);

    useEffect(()=>{
      if(recordContent) {
        if(recordContent.endType==='FORFEIT_LOSS') {
          setForfeitureSwitch(true)
        }
        if(recordContent.homeTeamExtraTimeGoal !== null) {
          setExtraSwitch(true)
        }
      }
    },[recordContent])

    const items = [
      {
        key: '1',
        label: '주심',
        children: recordContent?.referee || '',
        span:1,
      },
      {
        key: '2',
        label: '1부심',
        children: recordContent?.assistantReferee1 || '',
        span:1,
      },
      {
        key: '3',
        label: '2부심',
        children: recordContent?.assistantReferee2 || '',
        span:1,
      },
      {
        key: '4',
        label: '대기심',
        children: recordContent?.fourthOfficial || '',
        span:1,
      },
      {
        key: '5',
        label: '경기감독관',
        children: recordContent?.matchCommissioner || '',
        span:1,
      },
      {
        key: '6',
        label: '기록자',
        children: recordContent?.recorder || '',
        span:1,
      },
    ];

    const items2 = [
      {
        key: '1',
        label: '경기 시간',
        children: 
        <InputContainer>
          <InputHalfBox>
            <InputText>전반</InputText>
            <InputValue>{recordContent?.firstHalfTime>0 ? recordContent.firstHalfTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
          <InputHalfBox>
            <InputText>후반</InputText>
            <InputValue>{recordContent?.secondHalfTime>0 ? recordContent.secondHalfTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
        </InputContainer>,
        span:2,
      },
      {
        key: '2',
        label: '추가 시간',
        children: 
        <InputContainer>
          <InputHalfBox>
            <InputText>전반</InputText>
            <InputValue>{recordContent?.firstHalfAddedTime>0 ? recordContent.firstHalfAddedTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
          <InputHalfBox>
            <InputText>후반</InputText>
            <InputValue>{recordContent?.secondHalfAddedTime>0 ? recordContent.secondHalfAddedTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
        </InputContainer>,
        span:2,
      },
      {
        key: '3',
        label: '연장전 시간',
        children: 
        <InputContainer>
          <InputHalfBox>
            <InputText>전반</InputText>
            <InputValue>{recordContent?.firstHalfExtraTime>0 ? recordContent.firstHalfExtraTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
          <InputHalfBox>
            <InputText>후반</InputText>
            <InputValue>{recordContent?.secondHalfExtraTime>0 ? recordContent.secondHalfExtraTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
        </InputContainer>,
        span:2,
      },
      {
        key: '4',
        label: '연장전 추가 시간',
        children: 
        <InputContainer>
          <InputHalfBox>
            <InputText>전반</InputText>
            <InputValue>{recordContent?.firstHalfExtraAddedTime>0 ? recordContent.firstHalfExtraAddedTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
          <InputHalfBox>
            <InputText>후반</InputText>
            <InputValue>{recordContent?.secondHalfExtraAddedTime>0 ? recordContent.secondHalfExtraAddedTime + ' 분' : '-'}</InputValue>
          </InputHalfBox>
        </InputContainer>,
        span:2,
      },
    ];

    const items3 = [
      {
        key: '1',
        label: '비고',
        children: recordContent?.detail,
        span:3,
      },
    ];

  return (
    <Wrapper>
        <SectionTitle>
          <span>경기결과</span>
          <SwitchBox className='notPrint'>
            <SwitchText isactive={extraSwitch} >연장 경기</SwitchText>
            <Switch checked={extraSwitch} style={{marginRight:'16px'}} />
            <SwitchText isactive={forfeitureSwitch} >몰수 경기</SwitchText>
            <Switch checked={forfeitureSwitch}/>
          </SwitchBox>  
        </SectionTitle>
      
        <ResultContainer>
          <HalfBox>
            <HomeTeamBox>HOME</HomeTeamBox>
            {forfeitureSwitch 
            ? 
            <InputBox>
              <InputRow>
                <InputTitle className='check'>{recordContent?.homeTeamScore > 0 ? '몰수 승리' : '몰수 패배'}</InputTitle>
              </InputRow>
            </InputBox>
            : 
            <InputBox>
              <InputRow>
                <InputTitle>전반전</InputTitle>
                <ValueBox>{recordContent?.homeTeamFirstHalfTimeScore}</ValueBox>
              </InputRow>
              <InputRow>
                <InputTitle>후반전</InputTitle>
                <ValueBox>{recordContent?.homeTeamSecondHalfTimeGoal}</ValueBox>
              </InputRow>
              <InputRow>
                <InputTitle>연장전</InputTitle>
                <ValueBox>
                  {recordContent?.homeTeamExtraTimeGoal !== null
                  ? recordContent?.homeTeamExtraTimeGoal
                  : <GreyText>—</GreyText>}
                </ValueBox>
              </InputRow>
              <InputRow>
                <InputTitle>승부차기</InputTitle>
                <ValueBox>
                  {recordContent?.homeTeamShootoutScore !== null
                  ? recordContent?.homeTeamShootoutScore
                  : <GreyText>—</GreyText>}
                </ValueBox>
              </InputRow>
            </InputBox>
            }
            <ScoreBox>
              <ScoreNum>{recordContent?.homeTeamScore}</ScoreNum>
              <TeamName>{home}</TeamName>
            </ScoreBox>
          </HalfBox>
          
          <HalfBox>
          <ScoreBox>
              <ScoreNum>{recordContent?.awayTeamScore}</ScoreNum>
              <TeamName>{away}</TeamName>
            </ScoreBox>

            {forfeitureSwitch 
            ? 
            <InputBox>
              <InputRow>
                <InputTitle className='check'>{recordContent?.awayTeamScore > 0 ? '몰수 승리' : '몰수 패배'}</InputTitle>
              </InputRow>
            </InputBox>
            : 
            <InputBox>
              <InputRow>
                <InputTitle>전반전</InputTitle>
                <ValueBox>{recordContent?.awayTeamFirstHalfTimeScore}</ValueBox>
              </InputRow>
              <InputRow>
                <InputTitle>후반전</InputTitle>
                <ValueBox>{recordContent?.awayTeamSecondHalfTimeGoal}</ValueBox>
              </InputRow>
              <InputRow>
                <InputTitle>연장전</InputTitle>
                <ValueBox>
                  {recordContent?.awayTeamExtraTimeGoal !== null
                  ? recordContent?.awayTeamExtraTimeGoal
                  : <GreyText>—</GreyText>
                  }
                </ValueBox>
              </InputRow>
              <InputRow>
                <InputTitle>승부차기</InputTitle>
                <ValueBox>
                {recordContent?.awayTeamShootoutScore !== null
                  ? recordContent?.awayTeamShootoutScore 
                  : <GreyText>—</GreyText>
                  }
                </ValueBox>
              </InputRow>
            </InputBox>
            }
            <AwayTeamBox>AWAY</AwayTeamBox>
          </HalfBox>
        </ResultContainer>

        <SectionTitle>심판</SectionTitle>
        <Descriptions bordered items={items} />

        <SectionTitle>시간</SectionTitle>
        <Descriptions bordered items={items2} />

        <SectionTitle>비고</SectionTitle>
        <Descriptions bordered items={items3} />

    </Wrapper>
  )
}

export default RecordResult

const Wrapper = styled.div`
.ant-descriptions-item-label {
  width: 197px;
}
`;

const ResultContainer = styled.div`
display: flex;
flex-direction: row;
`;

const HalfBox = styled.div`
display: flex;
flex: 1;
height: 178px;
`;

const TeamBox = styled.div`
border: 1px solid #D9D9D9;
display: flex;
flex: 1;
justify-content: center;
align-items: center;
color: #FFF;
font-family: 'NanumSquareNeo900';
font-size: 22px;
font-weight: 900;
`;

const HomeTeamBox = styled(TeamBox)`
border-radius: 6px 0 0 6px;
background-color: #FF8667;
`;

const AwayTeamBox = styled(TeamBox)`
border-radius: 0 6px 6px 0;
background-color: #498AFF;
`;

const InputBox = styled.div`
border: 1px solid #D9D9D9;
border-left: none;
flex: 2;
/* background-color: yellow; */
padding-left: 18px;
padding-right: 18px;
`;

const ScoreBox = styled.div`
flex: 3;
border: 1px solid #D9D9D9;
border-left: none;
text-align: center;
`;

const InputRow = styled.div`
display: flex;
align-items: center;
margin-top: 10px;
.check {
  margin-right: 2px;
}
`;

const InputTitle = styled.span`
width: 45px;
white-space: nowrap;
color: #999; 
font-size: 12px;
margin-right: 22px;
`;

const GreyText = styled.span`
color: #999;
`;

const ScoreNum = styled.div`
margin-top: 45px;
margin-bottom: 31px;
color: #323232;
font-size: 56px;
line-height: 56px;
font-family: 'NanumSquareNeo800';
`;

const TeamName = styled.div`
color: #323232;
font-size: 16px;
`;

const InputContainer = styled.div`
display: flex;
width: 100%;
`;

const InputHalfBox = styled.div`
flex: 1;
`;

const InputText = styled.span`
margin-left: 9px;
margin-right: 5px;
white-space: nowrap;
color: #999;
    
`;

const InputValue = styled.span`
margin-left: 12px;
`;

const SectionTitle = styled.div`
display: flex;
color: #6A6A6A;
font-family: 'NanumSquareNeo800';
font-size: 16px;  
margin-top: 45px;
margin-bottom: 11px;
`;

const SwitchBox = styled.div`
font-size: 12px;
margin-left: auto;
`;

const SwitchText = styled.span`
color: ${props => props.isactive ? '#498AFF' : '#BFBFBF'};
margin-right: 5px;
`;

const ValueBox = styled.div`
width: 100%;
height: 30px;
line-height: 30px;
color: #1F1F1F;
font-size: 14px;
text-align: right;
`;