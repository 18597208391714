import React from 'react'
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation,  } from '@fortawesome/free-solid-svg-icons';


const PlayerRosterUpdateModal = ({okCheck}) => {
    const { confirm } = Modal;
    const showConfirm = () => {
      confirm({
        title: 
        <span>
          <FontAwesomeIcon icon={faCircleExclamation} style={{color:'#FF8667', marginRight:'12px'}} size='lg'/>
          선수 로스터를 업데이트 하시겠습니까?
        </span>,
        content: <span style={{marginLeft:'32px'}}>현재의 선수 리스트로 로스터가 업데이트 됩니다.</span>,
        icon: null, 
        okButtonProps: {key: 'submit', htmlType: 'submit'},
        okText: '확인',
        cancelText: '취소',
        onOk(){okCheck()},
        onCancel(){},
      });
    };

  return (
    <Button onClick={()=>{showConfirm();}}>로스터 업데이트</Button>
  );
};

export default PlayerRosterUpdateModal
