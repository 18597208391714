import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Form, InputNumber, Checkbox, Input, Descriptions, Switch, } from 'antd';
import StyleAtoms from '../../common/StyleAtoms';
import dayjs from 'dayjs';
import Notification from '../commons/Notification';
import {inputFieldNames, inputFieldExtraNames, inputFieldScoreNames} from '../../common/Data'


const RecordResultEdit = ({ form, recordContent, home, away, createRecord, matchId, setCompareGoalHome, setCompareGoalAway, forfeitureSwitch, setForfeitureSwitch  }) => {
    const {contextHolder, successNoti, warningNoti} = Notification();
    const {RedDot} = StyleAtoms();
    const [isCanceled, setIsCanceled] = useState(false);
    const [isAutoSaved, setIsAutoSaved] = useState(false);
    const [homeForfeiture, setHomeForfeiture] = useState(false);
    const [awayForfeiture, setAwayForfeiture] = useState(false);
    const [extraSwitch, setExtraSwitch] = useState(false);
    const [homeTotalScore, setHomeTotalScore] = useState(0);
    const [awayTotalScore, setAwayTotalScore] = useState(0);
    const allKeys = Object.keys(sessionStorage);
    const matchingKey = allKeys.find(key => key.startsWith(matchId));

    const calcScore = () => {
      const home1 = parseInt(form.getFieldValue('homeTeamFirstHalfTimeScore'));
      const home2 = parseInt(form.getFieldValue('homeTeamSecondHalfTimeGoal'));
      const home3 = parseInt(form.getFieldValue('homeTeamExtraTimeGoal'));

      const away1 = parseInt(form.getFieldValue('awayTeamFirstHalfTimeScore'));
      const away2 = parseInt(form.getFieldValue('awayTeamSecondHalfTimeGoal'));
      const away3 = parseInt(form.getFieldValue('awayTeamExtraTimeGoal'));

      const home123 = (home1 || 0) + (home2 || 0) + (extraSwitch ? (home3 || 0) : 0)
      const away123 = (away1 || 0) + (away2 || 0) + (extraSwitch ? (away3 || 0) : 0)

      setHomeTotalScore(home123);
      setAwayTotalScore(away123);

      setCompareGoalHome(home123)
      setCompareGoalAway(away123)
    }

    const cleanScore = (() => {
      form.setFieldsValue({ homeTeamFirstHalfTimeScore: 0 });
      form.setFieldsValue({ homeTeamSecondHalfTimeGoal: 0 });
      form.setFieldsValue({ awayTeamFirstHalfTimeScore: 0 });
      form.setFieldsValue({ awayTeamSecondHalfTimeGoal: 0 });
    })

    const onFinish = (values) => {
      if (matchId) {
        //수정
        createRecord(values, matchId)
      } else {
        //입력
        createRecord(values)
      }
    };

    const handleHomeForfeitChange = (e) => {
      setHomeForfeiture(e.target.checked);
      form.setFieldsValue({ awayFor: false });
      cleanScore();
      if (e.target.checked) {
        setAwayForfeiture(false);

        //어웨이 3점
        setHomeTotalScore(0);
        setAwayTotalScore(3);
        form.setFieldsValue({ endType : 'FORFEIT_LOSS' });
      } else {
        setHomeTotalScore(0);
        setAwayTotalScore(0);
        form.setFieldsValue({ endType : 'NORMAL' });
      }
    };
  
    const handleAwayForfeitChange = (e) => {
      setAwayForfeiture(e.target.checked);
      form.setFieldsValue({ homeFor: false });
      cleanScore();
      if (e.target.checked) {
        setHomeForfeiture(false);

        //홈 3점
        setHomeTotalScore(3);
        setAwayTotalScore(0);
        form.setFieldsValue({ endType : 'FORFEIT_LOSS' });
      } else {
        setHomeTotalScore(0);
        setAwayTotalScore(0);
        form.setFieldsValue({ endType : 'NORMAL' });
      }
    };

    const handleForfeitureSwitch = () => {
      setExtraSwitch(false);
      setForfeitureSwitch(!forfeitureSwitch);
    }

    const handleExtraSwitch = () => {
      setForfeitureSwitch(false);
      setExtraSwitch(!extraSwitch);
      sessionStorage.setItem(matchId + 'extraSwitch', !extraSwitch)
    }

    const saveToSessionStorage = (matchId, name) => (e) => {
      if(!recordContent) {
        const {value} = e.target;
        sessionStorage.setItem(matchId + name, value)  
      }
    }
    
    useEffect(()=>{
      if(isCanceled) {
        warningNoti(<>해당 경기는 취소된 경기입니다. <br/>확인 후 경기기록지를 입력해주세요.</>)
      }
    },[isCanceled])

    useEffect(()=>{
      if(isAutoSaved) {
        successNoti('자동저장 불러오기');
      }
    },[isAutoSaved])

    useEffect(()=>{ 
      //자동저장 불러오기 (수정페이지는 안불러옴, 입력페이지만 불러옴)
      if(!recordContent) {
        setExtraSwitch(JSON.parse(sessionStorage.getItem(matchId + 'extraSwitch')));
        if(matchingKey) {
          setIsAutoSaved(true);
          const ssEndAt = sessionStorage.getItem(matchId+'endAt');

          inputFieldNames.forEach((a, i) => {
            const ssValue = sessionStorage.getItem(matchId + a);
            form.setFieldsValue({ [a]: ssValue });
          });

          inputFieldScoreNames.forEach((a, i) => {
            const ssArrayValue = JSON.parse(sessionStorage.getItem(matchId + a));
            form.setFieldsValue({ [a]: ssArrayValue });
          });

          form.setFieldsValue({ 
            endAt: ssEndAt ? dayjs(ssEndAt) : null,
          })
        }
      }
      calcScore();
    },[matchId])

    useEffect(()=>{
      if(extraSwitch===false) {
        form.resetFields(inputFieldExtraNames);
        inputFieldExtraNames.forEach(a => {
          sessionStorage.removeItem(matchId + a);
        });
      } else if (extraSwitch===true && recordContent) {
        form.setFieldsValue({ 
          homeTeamExtraTimeGoal: recordContent.homeTeamExtraTimeGoal,
          awayTeamExtraTimeGoal: recordContent.awayTeamExtraTimeGoal,
          firstHalfExtraTime: recordContent.firstHalfExtraTime,
          secondHalfExtraTime: recordContent.secondHalfExtraTime,
          firstHalfExtraAddedTime: recordContent.firstHalfExtraAddedTime,
          secondHalfExtraAddedTime: recordContent.secondHalfExtraAddedTime,
        })
      }
      calcScore();
    },[extraSwitch])

    useEffect(()=>{
      calcScore()
      if(forfeitureSwitch === false) {
        setHomeForfeiture(false)
        setAwayForfeiture(false)

        form.setFieldsValue({
          homeFor: null,
          awayFor: null,
        });
      }
    },[forfeitureSwitch])

    useEffect(()=>{
      if(recordContent) {
        setHomeTotalScore(recordContent.homeTeamScore)
        setAwayTotalScore(recordContent.awayTeamScore)

        if(recordContent.endType === 'CANCEL') {
          setIsCanceled(true)
        }

        if(recordContent.homeTeamExtraTimeGoal !== null || recordContent.homeTeamShootoutScore !== null) {
          setExtraSwitch(true)
        }
        
        if(recordContent.endType === 'FORFEIT_LOSS') {
          setForfeitureSwitch(true)
        }

        inputFieldNames.forEach((a,i)=>(
          form.setFieldsValue({ 
            [a]: recordContent[a],
          })
        ))

        form.setFieldsValue({ 
          homeTeamScore: recordContent.homeTeamScore,
          awayTeamScore: recordContent.awayTeamScore,
          endAt: dayjs(recordContent?.endAt),
          homeFor: recordContent.endType === 'FORFEIT_LOSS' && recordContent.homeTeamScore === 0 ? true : null,
          awayFor: recordContent.endType === 'FORFEIT_LOSS' && recordContent.homeTeamScore === 3 ? true : null,
        });
      } else {
        form.resetFields();
      }
    },[recordContent])

    const items = [
      {
        key: '1',
        label: '주심',
        children: 
        <Form.Item name='referee'>
          <Input onChange={saveToSessionStorage(matchId, 'referee')} />
        </Form.Item>,
        span:1,
      },
      {
        key: '2',
        label: '1부심',
        children: 
        <Form.Item name='assistantReferee1'>
          <Input onChange={saveToSessionStorage(matchId, 'assistantReferee1')} />
        </Form.Item>,
        span:1,
      },
      {
        key: '3',
        label: '2부심',
        children: 
        <Form.Item name='assistantReferee2'>
          <Input onChange={saveToSessionStorage(matchId, 'assistantReferee2')} />
        </Form.Item>,
        span:1,
      },
      {
        key: '4',
        label: '대기심',
        children: 
        <Form.Item name='fourthOfficial'>
          <Input onChange={saveToSessionStorage(matchId, 'fourthOfficial')} />
        </Form.Item>,
        span:1,
      },
      {
        key: '5',
        label: '경기감독관',
        children: 
        <Form.Item name='matchCommissioner'>
          <Input onChange={saveToSessionStorage(matchId, 'matchCommissioner')} />
        </Form.Item>,
        span:1,
      },
      {
        key: '6',
        label: '기록자',
        children: 
        <Form.Item name='recorder'>
          <Input onChange={saveToSessionStorage(matchId, 'recorder')} />
        </Form.Item>,
        span:1,
      },
    ];

    const items2 = [
      {
        key: '1',
        label: <span>경기 시간<RedDot/></span>,
        children: 
        <InputContainer>
          <InputText>전반</InputText>
          <SFormItem name='firstHalfTime' rules={[{ required: true, message:'시간 입력 필요' }]}>
            <SInputNumber 
              max={100} 
              controls={false} 
              suffix="분" 
              onChange={(value) => saveToSessionStorage(matchId, 'firstHalfTime')({ target: { value } })}
            />
          </SFormItem>
          <InputText>후반</InputText>
          <SFormItem name='secondHalfTime' rules={[{ required: true, message:'시간 입력 필요' }]}>
            <SInputNumber 
              max={100} 
              controls={false} 
              suffix="분"
              onChange={(value) => saveToSessionStorage(matchId, 'secondHalfTime')({ target: { value } })}
            />
          </SFormItem>
        </InputContainer>,
        span:2,
      },
      {
        key: '2',
        label: '추가 시간',
        children: 
        <InputContainer>
          <InputText>전반</InputText>
          <SFormItem name='firstHalfAddedTime' >
            <SInputNumber 
              max={50} 
              controls={false} 
              suffix="분"
              onChange={(value) => saveToSessionStorage(matchId, 'firstHalfAddedTime')({ target: { value } })}
            />
          </SFormItem>
          <InputText>후반</InputText>
          <SFormItem name='secondHalfAddedTime' >
            <SInputNumber
              max={50}
              controls={false}
              suffix="분"
              onChange={(value) => saveToSessionStorage(matchId, 'secondHalfAddedTime')({ target: { value } })}
            />
          </SFormItem>
        </InputContainer>,
        span:2,
      },
      {
        key: '3',
        label: '연장전 시간',
        children: 
        <InputContainer>
          <InputText>전반</InputText>
          <SFormItem name='firstHalfExtraTime'>
            <SInputNumber 
              max={100} 
              controls={false} 
              disabled={!extraSwitch} 
              suffix="분"
              onChange={(value) => saveToSessionStorage(matchId, 'firstHalfExtraTime')({ target: { value } })}
            />
          </SFormItem>
          <InputText>후반</InputText>
          <SFormItem name='secondHalfExtraTime'>
            <SInputNumber 
              max={100} 
              controls={false} 
              disabled={!extraSwitch} 
              suffix="분"
              onChange={(value) => saveToSessionStorage(matchId, 'secondHalfExtraTime')({ target: { value } })}
            />
          </SFormItem>
        </InputContainer>,
        span:2,
      },
      {
        key: '4',
        label: '연장전 추가 시간',
        children: 
        <InputContainer>
          <InputText>전반</InputText>
          <SFormItem name='firstHalfExtraAddedTime' >
            <SInputNumber 
              max={50} 
              controls={false} 
              disabled={!extraSwitch} 
              suffix="분"
              onChange={(value) => saveToSessionStorage(matchId, 'firstHalfExtraAddedTime')({ target: { value } })}
            />
          </SFormItem>
          <InputText>후반</InputText>
          <SFormItem name='secondHalfExtraAddedTime' >
            <SInputNumber 
              max={50} 
              controls={false} 
              disabled={!extraSwitch} 
              suffix="분"
              onChange={(value) => saveToSessionStorage(matchId, 'secondHalfExtraAddedTime')({ target: { value } })}
            />
          </SFormItem>
        </InputContainer>,
        span:2,
      },
    ];

    const items3 = [
      {
        key: '1',
        label: 
        <>
        <DescTitle>
          비고
        </DescTitle><br/>
        <DescSubTitle>*최대 200자</DescSubTitle>
        </>,
        children: 
        <Form.Item name='detail'>
          <Input maxLength={200} onChange={saveToSessionStorage(matchId, 'detail')} />
        </Form.Item>,
        span:3,
      },
    ];

  return (
    <Wrapper>
    {contextHolder}
      <Form form={form} name='recordResultForm' onFinish={onFinish} autoComplete="off" className='record' >
        <SectionTitle>
          <span>경기결과</span>
          <SwitchBox className='notPrint'>
            <SwitchText isactive={extraSwitch} >연장 경기</SwitchText>
            <Switch checked={extraSwitch} onChange={handleExtraSwitch} style={{marginRight:'16px'}} />
            <SwitchText isactive={forfeitureSwitch} >몰수 경기</SwitchText>
            <Switch checked={forfeitureSwitch} onChange={handleForfeitureSwitch} />
          </SwitchBox>  
        </SectionTitle>
      
        <ResultContainer>
          <HalfBox>
            <HomeTeamBox>HOME</HomeTeamBox>
            {forfeitureSwitch 
            ? 
            <InputBox>
              <InputRow>
                <InputTitle className='check'>몰수패</InputTitle>
                <Form.Item name='homeFor' valuePropName="checked">
                  <Checkbox checked={homeForfeiture} onChange={handleHomeForfeitChange }/>
                </Form.Item>  

              </InputRow>
            </InputBox>
            : 
            <InputBox>
              <InputRow>
                <InputTitle>전반전<RedDot/></InputTitle>
                <Form.Item name="homeTeamFirstHalfTimeScore" rules={[{ required: true, message:'점수 입력 필요' }]}>
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    onChange={(value)=>{
                      calcScore(); 
                      saveToSessionStorage(matchId, 'homeTeamFirstHalfTimeScore')({ target: { value } });
                    }}
                  />
                </Form.Item>
              </InputRow>
              <InputRow>
                <InputTitle>후반전<RedDot/></InputTitle>
                <Form.Item name="homeTeamSecondHalfTimeGoal" rules={[{ required: true, message:'점수 입력 필요' }]}>
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    onChange={(value)=>{
                      calcScore(); 
                      saveToSessionStorage(matchId, 'homeTeamSecondHalfTimeGoal')({ target: { value } });
                    }}
                  />
                </Form.Item>
              </InputRow>
              <InputRow>
                <InputTitle>연장전</InputTitle>
                <Form.Item name="homeTeamExtraTimeGoal" rules={[{ required: extraSwitch, message:'점수 입력 필요' }]}>
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    disabled={!extraSwitch} 
                    onChange={(value)=>{
                      calcScore(); 
                      saveToSessionStorage(matchId, 'homeTeamExtraTimeGoal')({ target: { value } });
                    }}
                  />
                </Form.Item>
              </InputRow>
              <InputRow>
                <InputTitle>승부차기</InputTitle>
                <Form.Item name="homeTeamShootoutScore" >
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    disabled={!extraSwitch}
                    onChange={(value) => saveToSessionStorage(matchId, 'homeTeamShootoutScore')({ target: { value } })}
                  />
                </Form.Item>
              </InputRow>
            </InputBox>
            }
            <ScoreBox>
              <ScoreNum>{typeof homeTotalScore === 'number' && !isNaN(homeTotalScore) ? homeTotalScore : '0'}</ScoreNum>
              <TeamName>{home}</TeamName>
            </ScoreBox>
          </HalfBox>
          
          <HalfBox>
          <ScoreBox>
              <ScoreNum>{typeof awayTotalScore === 'number' && !isNaN(awayTotalScore) ? awayTotalScore : '0'}</ScoreNum>
              <TeamName>{away}</TeamName>
            </ScoreBox>

            {forfeitureSwitch 
            ? 
            <InputBox>
              <InputRow>
                <InputTitle className='check'>몰수패</InputTitle>
                <Form.Item name='awayFor' valuePropName="checked">
                  <Checkbox checked={awayForfeiture} onChange={handleAwayForfeitChange }/>
                </Form.Item>
              </InputRow>
            </InputBox>
            : 
            <InputBox>
              <InputRow>
                <InputTitle>전반전<RedDot/></InputTitle>
                <Form.Item name="awayTeamFirstHalfTimeScore" rules={[{ required: true, message:'점수 입력 필요' }]}>
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    onChange={(value)=>{
                      calcScore(); 
                      saveToSessionStorage(matchId, 'awayTeamFirstHalfTimeScore')({ target: { value } });
                    }}
                  />
                </Form.Item>
              </InputRow>
              <InputRow>
                <InputTitle>후반전<RedDot/></InputTitle>
                <Form.Item name="awayTeamSecondHalfTimeGoal" rules={[{ required: true, message:'점수 입력 필요' }]}>
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    onChange={(value)=>{
                      calcScore(); 
                      saveToSessionStorage(matchId, 'awayTeamSecondHalfTimeGoal')({ target: { value } });
                    }}
                  />
                </Form.Item>
              </InputRow>
              <InputRow>
                <InputTitle>연장전</InputTitle>
                <Form.Item name="awayTeamExtraTimeGoal" rules={[{ required: extraSwitch, message:'점수 입력 필요' }]}>
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    disabled={!extraSwitch} 
                    onChange={(value)=>{
                      calcScore(); 
                      saveToSessionStorage(matchId, 'awayTeamExtraTimeGoal')({ target: { value } });
                    }}
                  />
                </Form.Item>
              </InputRow>
              <InputRow>
                <InputTitle>승부차기</InputTitle>
                <Form.Item name="awayTeamShootoutScore" >
                  <SInputNumber 
                    min={0} 
                    max={20} 
                    disabled={!extraSwitch}
                    onChange={(value) => saveToSessionStorage(matchId, 'awayTeamShootoutScore')({ target: { value } })}
                  />
                </Form.Item>
              </InputRow>
            </InputBox>
            }
            <AwayTeamBox>AWAY</AwayTeamBox>
          </HalfBox>
        </ResultContainer>

        <SectionTitle>심판</SectionTitle>
        <Descriptions bordered items={items} />

        <SectionTitle>시간</SectionTitle>
        <Descriptions bordered items={items2} />

        <SectionTitle>비고</SectionTitle>
        <Descriptions bordered items={items3} />

      </Form>
    </Wrapper>
  )
}

export default RecordResultEdit

const Wrapper = styled.div`
  .ant-descriptions-item-label {
    width: 197px;
  }
`;

const ResultContainer = styled.div`
display: flex;
flex-direction: row;

`;

const HalfBox = styled.div`
display: flex;
flex: 1;
min-height: 178px;
`;

const TeamBox = styled.div`
border: 1px solid #D9D9D9;
display: flex;
flex: 1;
justify-content: center;
align-items: center;
color: #FFF;
font-family: 'NanumSquareNeo900';
font-size: 22px;
font-weight: 900;
`;

const HomeTeamBox = styled(TeamBox)`
border-radius: 6px 0 0 6px;
background-color: #FF8667;

`;

const AwayTeamBox = styled(TeamBox)`
border-radius: 0 6px 6px 0;
background-color: #498AFF;
`;

const InputBox = styled.div`
border: 1px solid #D9D9D9;
border-left: none;
flex: 2;
/* background-color: yellow; */
padding-left: 18px;
padding-right: 18px;
`;

const ScoreBox = styled.div`
flex: 3;
/* background-color: blue; */

border: 1px solid #D9D9D9;
border-left: none;
text-align: center;
`;

const InputRow = styled.div`
display: flex;
align-items: center;
margin-top: 10px;
margin-bottom: 10px;
.check {
  margin-right: 2px;
}
`;

const InputTitle = styled.span`
min-width: 43px;
width: 56px;
white-space: nowrap;
color: #999; 
font-size: 12px;
margin-right: 22px;
`;

const SInputNumber = styled(InputNumber)`
width: 100%;
`;

const ScoreNum = styled.div`
margin-top: 45px;
margin-bottom: 31px;
color: #323232;
font-size: 56px;
line-height: 56px;
font-family: 'NanumSquareNeo800';
`;

const TeamName = styled.div`
color: #323232;
font-size: 16px;
`;

const InputContainer = styled.div`
display: flex;
align-items: end;
width: 100%;
.first {
  margin-right: 23px;
}
.second {
  margin-right: 5px;
}
`;

const InputText = styled.span`
margin-left: 9px;
margin-right: 5px;
white-space: nowrap;
color: #999;
    
`;

const SFormItem = styled(Form.Item)`
width: 100%;
`;

const SectionTitle = styled.div`
display: flex;
color: #6A6A6A;
font-family: 'NanumSquareNeo800';
font-size: 16px;  
margin-top: 45px;
margin-bottom: 11px;
`;

const SwitchBox = styled.div`
font-size: 12px;
margin-left: auto;
`;

const SwitchText = styled.span`
color: ${props => props.isactive ? '#498AFF' : '#BFBFBF'};
margin-right: 5px;
`;

const DescTitle = styled.span`
padding-bottom: 15px;
`;

const DescSubTitle = styled.span`
color: #498AFF;
font-size: 9px;
`;