import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router-dom';
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useRosterUpdate() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN, REFRESH_TOKEN]);
    const { compId, teamId } = useParams();
    
    const updateRoster = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${cookies[ACCESS_TOKEN]}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };
            
            const response = await axios.post(`${baseUrl}/competition/${compId}/apply-team/${teamId}/roster`, {}, {headers});

            if(response.headers.authorization) {
                setCookie(ACCESS_TOKEN, response.headers.authorization); 
            }
            return response.status
            
        } catch (error) {
            console.error(error);
        }
    };

    return { updateRoster };
}

export default useRosterUpdate;
