import { useState } from 'react';
import axios from 'axios';
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs';

function useCreateCompFD() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const { compId } = useParams();
    const [compResult, setCompResult] = useState();
     
    const createComp = async (values, bannerFileList, detailFileList, bracketFileList,
        detailUpdate, bracketUpdate) => {
        const formData = new FormData();
        const startTimeFilter = (target) => {
            return target.set('hour', 0).set('minute', 0).valueOf();
        }
        const endTimeFilter = (target) => {
            return target.set('hour', 23).set('minute', 59).valueOf();
        }

        formData.append("name", values.name);
        formData.append("competitionType", values.competitionType);
        formData.append("detail", values.detail);
        formData.append("link", values.link ? values.link : '');
        formData.append("locationCity1", values.locationCity1);
        formData.append("locationCity2", values.locationCity2);
        formData.append("locationDetail", values.locationDetail);
        formData.append("price", values.priceType+' '+values.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+'원');
        formData.append("target", values.target ? values.target : '');
        formData.append("policy", values.policy); 
        formData.append("startAt", compId ? dayjs(values.startAt).valueOf() : startTimeFilter(dayjs(values.startAt)));
        formData.append("endAt", compId ? dayjs(values.endAt).valueOf() : endTimeFilter(dayjs(values.endAt)));
        formData.append("applyStartAt", compId ? dayjs(values.applyStartAt).valueOf() : startTimeFilter(dayjs(values.applyStartAt)));
        formData.append("applyEndAt", compId ? dayjs(values.applyEndAt).valueOf() : endTimeFilter(dayjs(values.applyEndAt)));
        formData.append("openAt", compId ? dayjs(values.openAt).valueOf() : startTimeFilter(dayjs(values.openAt)));
        formData.append("closeAt", compId ? dayjs(values.closeAt).valueOf() : endTimeFilter(dayjs(values.closeAt)));
        formData.append('competitionFirm', JSON.stringify(values.competitionFirm));
        formData.append('competitionConstraint', JSON.stringify(values.competitionConstraint));

        if(bannerFileList) {
            formData.append('bannerImage', bannerFileList);
        }

        detailFileList?.forEach((a, i) => {
            formData.append('detailImage', a.originFileObj);
        });

        bracketFileList?.forEach((a, i) => {
            formData.append('fixtureImage', a.originFileObj);
        });

        // detailUpdate?.forEach((a, i) => {
        //     formData.append('detailImage', a);
        // });


        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                'Content-Type': "multipart/form-data",
                'Authorization': `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            let response;
            if (compId) {
                // Update Comp
                response = await axios.put(`${baseUrl}/competition/${compId}`, formData, { headers });
                
            } else {
                // Create Comp
                response = await axios.post(`${baseUrl}/competition`, formData, { headers });
            }

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setCompResult(response.data.data);
        } catch (error) {
            console.error(error);
            setCompResult(false);
        }
    };

    return { compResult, createComp, setCompResult };
}

export default useCreateCompFD;
