import { notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const Notification = () => {
    const [api, contextHolder] = notification.useNotification();

    const messages = [
      `⚽️  축구공 바람 넣는 중 ..`,
      `🌱  잔디 키우는 중 ..`,
    ];

    const loadingNoti = () => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      const selectedMessage = messages[randomIndex];
        api.open({
          key: 'loading',
          message: selectedMessage,
          placement: 'top',
          duration: 6,
        });
      };

      const closeLoadingNoti = () => {
        api.destroy('loading');
      };

      const serverStatusNoti = (status) => {
        api.open({
          message: `${status} server ..`,
          placement: 'top',
          duration : 3,
        });
      };
    
      const successNoti = (text) => {
        api.success({
          message: `${text} 완료 !`,
          placement: 'top',
          duration : 2,
        });
      };
      const errorNoti = () => {
        api.error({
          message: '반영 실패 ! 다시한번 시도해 주세요',
          placement: 'top',
          duration : 2,
        });
      };
      const refreshNoti = () => {
        api.success({
          message: '새로고침 됐습니다 !',
          placement: 'top',
          duration : 2,
        });
      };
      const goalCheckNoti = () => {
        api.open({
          message: 
          <>
          <FontAwesomeIcon icon={faCircleQuestion} style={{color:'#f5222d'}} size='lg'/>
          <span>&nbsp;&nbsp;&nbsp;득점기록과 선수기록이 <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;일치하지 않습니다 !</span>
          </>,
          placement: 'topLeft',
          duration : 3,
        });
      };
      const recordCheckNoti = () => {
        api.error({
          message: <span>QR코드 검인한 선수가 없습니다 ! <br/>출전 선수 명단을 새로고침 해주세요</span>,
          placement: 'top',
          duration : 3,
        });
      };
      const recordValidateCheck = () => {
        api.error({
          message: '필수 값들을 채워 넣어주세요 !',
          placement: 'top',
          duration : 3,
        });
      };

      const loginErrorNoti = () => {
        api.error({
          message: '아이디와 비밀번호를 다시 확인해 주세요 !',
          placement: 'top',
          duration : 2,
        });
      };

      const didntDupCheck = () => {
        api.error({
          message: '아이디 중복체크를 해주세요 !',
          placement: 'top',
          duration : 2,
        });
      };

      const wrongFormat = () => {
        api.error({
          message: '형식에 맞지 않습니다 !',
          placement: 'top',
          duration : 2,
        });
      }

      const emptyPw = () => {
        api.error({
          message: '비밀번호를 입력해주세요 !',
          placement: 'top',
          duration : 2,
        });
      }

      const editSuccessNoti = () => {
        api.success({
          message: '성공적으로 변경 됐습니다 !',
          placement: 'top',
          duration : 2,
        });
      };

      const warningNoti = (text) => {
        api.warning({
          message: text,
          placement: 'top',
          duration : 4,
        });
      };

  return { contextHolder, loadingNoti, serverStatusNoti, successNoti, errorNoti, closeLoadingNoti, 
    refreshNoti, goalCheckNoti, recordCheckNoti, recordValidateCheck, loginErrorNoti,
    didntDupCheck, wrongFormat, emptyPw, editSuccessNoti, warningNoti }
}

export default Notification