import React from 'react'
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck,  } from '@fortawesome/free-solid-svg-icons';


const PlayerApproveModal = ({okCheck}) => {
    const { confirm } = Modal;
    const showConfirm = () => {
      confirm({
        title: 
        <span>
          <FontAwesomeIcon icon={faCircleCheck} style={{color:'#498AFF', marginRight:'12px'}} size='lg'/>
          전체 승인하시겠습니까?
        </span>,
        content: <span style={{marginLeft:'32px'}}>승인 알림이 팀리더에게 앱 알림을 통해 전송됩니다.</span>,
        icon: null, 
        okButtonProps: {key: 'submit', htmlType: 'submit'},
        okText: '확인',
        cancelText: '취소',
        onOk(){okCheck(true)},
        onCancel(){},
      });
    };

  return (
    <Button type='primary' onClick={()=>{showConfirm();}}>전체 승인</Button>
  );
};

export default PlayerApproveModal
