import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import { useForm } from 'react-hook-form'
import images from '../../common/images.js'
import useSignin from '../../hooks/Signin/useSignin.js'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import Notification from '../commons/Notification.js'

const Signin = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { login, loginResult, setLoginResult, logOut  } = useSignin();
  const {contextHolder, loginErrorNoti} = Notification();
  const [cookies, setCookie, removeCookie] = useCookies(['id']);
  const navigate = useNavigate();

  useEffect(()=>{
    if(cookies.id) {
      navigate('/') 
    } else {
      navigate('/signin') 
    }
  },[])

  const onSubmit = (data) => {
    login(data)
    setLoginResult(null)
  }

  useEffect(()=>{
    if(loginResult===false) {
      loginErrorNoti();
    }
  },[loginResult])

  return (
    <>
    <Wrapper>
      {contextHolder}
      <TitleContainer>
        <LogoImg src={images.main_logo} alt='' />
        <TitleBox>
          <Title>위디드 관리자 페이지</Title>
        </TitleBox>
      </TitleContainer>
      <Container>
        <FormBox>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input placeholder='아이디' spellCheck={false} autoComplete='false' {...register("id")} />
            <Input type='password' placeholder='비밀번호' autoComplete='false' {...register("password")} style={{marginTop:'29px'}} />
            <Button type="submit">확인</Button>
          </form>
        </FormBox>
      </Container>
    </Wrapper>
    </>
  )
}

export default Signin

const Wrapper = styled.div`
height: 507px;
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;
`;

const TitleContainer = styled.div`
height: 147px;
text-align: center;
`;

const Container = styled.div`
width: 482px;
height: 360px;
border-radius: 22px;
background-color: rgba(255,255,255,0.9);  
filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
`;

const TitleBox = styled.div`
display: flex;
font-family: 'NanumSquareNeo';
`;

const LogoImg = styled.img`
width: 200px;
`;

const Title = styled.div`
text-align: center;
font-size: 32px;
color: white;
text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
`;

const FormBox = styled.div`
padding-top: 40px;
text-align: center;
`;

const Input = styled.input`
width: 332px;
height: 70px;
border-radius: 10px;
border: 1px solid #E9E9E9;
padding-left: 30px;
padding-right: 30px;
font-size: 17px;
font-family: 'NanumSquareNeo';
  &::placeholder { 
      color: #CDCFDA; 
    }
`;

const Button = styled.button`
width: 394px;
height: 74px;
margin-top: 29px;
color: white;
border-radius: 10px;
border: 1px solid #498AFF;
font-size: 17px;
background-color: #498AFF;
font-weight: 700;
cursor: pointer;
font-family: 'NanumSquareNeo';
`;
