import React from 'react'
import { styled } from 'styled-components'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Form, Select } from 'antd';
import useGetLineup from '../../../hooks/Record/useGetLineup';
import useUpdatePlayerStatus from '../../../hooks/Match/SuperUser/useUpdatePlayerStatus';
import Message from '../../commons/Message';

const MatchAllLineup = ({homeTeamName, awayTeamName}) => {
    const { contextHolder, messageSuccess, messageError, 
        messageLoading, closeMessageLoading } = Message();
    const {lineupContent, getLineup} = useGetLineup();
    const [homeStarting, setHomeStarting] = useState([]);
    const [awayStarting, setAwayStarting] = useState([]);
    const [form] = Form.useForm();
    const { updatePlayerStatus } = useUpdatePlayerStatus();
    const { Option } = Select

    const shit1 = form.getFieldsValue()

    // console.log(shit1,'shit');


    const onSubmit = async (values) => {
        const formattedData = Object.keys(values).map(key => ({
            memberRecordId: parseInt(key.split('-')[1]),
            playerStatus: values[key]
        }));

        messageLoading();

        const updResult = await updatePlayerStatus(formattedData)
        if (updResult === 200) {
            closeMessageLoading();
            messageSuccess();
        } else {
            closeMessageLoading();
            messageError();
        }

        getLineup();
    };
    
    useEffect(() => { 
        const homePlayers = lineupContent
          ? lineupContent.filter((a) => a.isHomeTeam)
          : [];
        const awayPlayers = lineupContent
          ? lineupContent.filter((a) => !a.isHomeTeam)
          : [];
        setHomeStarting(homePlayers);
        setAwayStarting(awayPlayers);

        const playerStatusMap = {};
        lineupContent?.forEach(player => {
            playerStatusMap[`${player.isHomeTeam ? 'home' : 'away'}-${player.id}`] = player.playerStatus;
        });

        form.setFieldsValue(playerStatusMap);
      }, [lineupContent]);

    const lineupSpitter = (players) => { 
        return players
        .sort((a, b) => {
            const statusOrder = { "STARTING": 1, "CAPTAIN": 1, "BENCH": 2, "NONE": 3 };
            const statusCompare = statusOrder[a.playerStatus] - statusOrder[b.playerStatus];
            if (statusCompare !== 0) {
                return statusCompare;
            }
            const posOrder = { GK: 1, DF: 2, MF: 3, FW: 4 };
            const posCompare = posOrder[a.position] - posOrder[b.position];
            if (posCompare !== 0) {
                return posCompare;
            }
            return a.backNumber - b.backNumber;
        })
        .map((player) => (
            <ColRow key={player.id}>
                <ShortCol>
                    <SFormItem name={`${player.isHomeTeam ? 'home' : 'away'}-${player.id}`}>
                        <Select 
                            placeholder='구분' style={{width:'100%',}} 
                            // onChange={shit}
                        >
                            <Option value="NONE">ㅡ</Option>
                            <Option value="CAPTAIN">주장</Option>
                            <Option value="STARTING">선발</Option>
                            <Option value="BENCH">교체</Option>
                        </Select>
                    </SFormItem>
                </ShortCol>
                <ShortCol>{player.position}</ShortCol>
                <ShortCol>{player.backNumber}</ShortCol>
                <LongCol>{player.member.name}</LongCol>
            </ColRow>
        ));
    };

    const TableLabel = () => (
        <ColLabelRow>
            <ShortCol>구분</ShortCol>
            <ShortCol>POS</ShortCol>
            <ShortCol>NO</ShortCol>
            <LongCol>NAME</LongCol>
        </ColLabelRow>
    );

  return (
    <>
    {contextHolder}
    {homeStarting.length > 0 || awayStarting.length > 0 ? 
    <Form form={form} onFinish={onSubmit}>
        <Container className='lineup'>
            <HalfBox>
                <HomeTeamBox>
                    <span>HOME 출전 명단</span>
                    <TeamName>{homeTeamName}</TeamName>
                </HomeTeamBox>
                <Wrapper>
                    <LineupBox>
                        {TableLabel()}
                        {lineupSpitter(homeStarting)}
                    </LineupBox>
                </Wrapper>
            </HalfBox>

            <HalfBox>
                <AwayTeamBox>
                    <span>AWAY 출전 명단</span>
                    <TeamName>{awayTeamName}</TeamName>
                </AwayTeamBox>
                <Wrapper>
                    <LineupBox>
                        {TableLabel()}
                        {lineupSpitter(awayStarting)}
                    </LineupBox>
                </Wrapper>
            </HalfBox>
        </Container>
        <ButtonWrapper>
            <Button type="primary" htmlType="submit" >저장하기</Button>
        </ButtonWrapper>
        
    </Form>
    : <NullWrapper>출전 선수 명단 등록 전입니다</NullWrapper>}
    </> 
  )
}

export default MatchAllLineup

const Container = styled.div`
display: flex;
gap: 22px;
`;

const NullWrapper = styled.div`
color: #B6B6B6;
font-family: 'NanumSquareNeo800';
font-size: 18px;
height: 398px;
display: flex;
align-items: center;
justify-content: center;
`;

const HalfBox = styled.div`
flex: 1;
`;

const Wrapper = styled.div`
display: flex;
flex: 1;
padding-top: 14px;
padding-bottom: 14px;
`;

const LineupBox = styled.div`
flex: 53;
display: flex;
flex-direction: column;
font-size: 12px;
text-align: center;
`;


const ShortCol = styled.div`
display: flex;
flex: 10;
border-right: 1px solid #F0F0F0;
align-items: center;
justify-content: center; 
color: ${props => props.color===true ? '#FF8667' 
: props.color===false ? '#498AFF'
: ''};
`;

const LongCol = styled.div`
display: flex;
flex: 23;
align-items: center;
justify-content: center; 
border-right: 1px solid #F0F0F0;
`;

const Row = styled.div`
width: 100%;
height: 29px;
display: flex; 
`;

const ColLabelRow = styled(Row)`
font-family: 'NanumSquareNeo800';
color: #6A6A6A;  
border: 1px solid #F0F0F0;
background-color: #FAFAFA;
border-right: none;
`;

const ColRow = styled(Row)`
color: #303030;
border: 1px solid #F0F0F0;
border-top: none;
overflow: hidden;
border-right: none;
`;

const TeamBox = styled.div`
display: flex;
height: 37px;
border-radius: 6px;
align-items: center;
color: white;
justify-content: space-between;
padding-left: 22px;
padding-right: 22px;
`;

const HomeTeamBox = styled(TeamBox)`
background-color: #FF8667;
`;

const AwayTeamBox = styled(TeamBox)`
background-color: #498AFF;
`;

const TeamName = styled.div`
font-family: 'NanumSquareNeo800';
`;

const SFormItem = styled(Form.Item)`
width: 100%;
text-align: left;
.ant-select-selector {
    border: none !important;
}
`

const ButtonWrapper = styled.div`
display: flex;
justify-content: end;
padding-right: 14px;
margin-top: 17px;
`
