import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs';
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useCreateMatch() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const { matchId, compId } = useParams();
    const [matchResult, setMatchResult] = useState();
     
    const createMatch = async (values) => {
        const timeToZeroSecond = dayjs(values.startAt).set('second', 0).set('millisecond', 0).valueOf();
        
        const requestBody = {
            ...values,
            "competitionId": parseInt(compId, 10), //only create
            "startAt": timeToZeroSecond,
            "location": values.location,
            "groupName": values.groupName?.replace(/\s/g, ''), //띄어쓰기 무시
        }
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            let response;
            if (matchId) {
                // Update Match
                response = await axios.put(`${baseUrl}/match-schedule/${matchId}`, requestBody, { headers });
            } else {
                // Create Match
                response = await axios.post(`${baseUrl}/match-schedule`, requestBody, { headers });
            }

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setMatchResult(response.data.data);
        } catch (error) {
            console.error(error);
            setMatchResult(false);
        }
    };

    return { matchResult, createMatch, setMatchResult };
}

export default useCreateMatch;
