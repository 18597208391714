import React, { useRef, useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { Form, Typography, Descriptions, Button, } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import ReactToPrint from "react-to-print";
import RecordResult from '../../components/Record/RecordResult';
import Lineup from '../../components/Record/Lineup';
import Navigation from '../../common/Navigation';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useReadMatch from '../../hooks/Match/useReadMatch'; 
import useGetRecord from '../../hooks/Record/useGetRecord';
import useCreateRecord from '../../hooks/Record/useCreateRecord'; 
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import {대회종류번역기} from '../../common/util'
import useGetLineup from '../../hooks/Record/useGetLineup';

const Record = () => {
    const {PageTitle, BlueImg, TitleBox, Title, InvertImg } = StyleAtoms();
    const { Text } = Typography;
    const { compId, matchId } = useParams();
    const location = useLocation(); 
    const { goComp, goCompList, goMatchEdit } = Navigation();
    const [printContent, setPrintContent] = useState("");
    const ref = useRef();
    const [form] = Form.useForm();
    const { compName } = queryString.parse(location.search);
    const { matchContent } = useReadMatch(); 
    const { recordContent, getRecord } = useGetRecord();
    const { recordResult, createRecord } = useCreateRecord(); 
    const { lineupContent } = useGetLineup();
    const [createId, setCreateId] = useState(); 

    useEffect(() => {
      window.scrollTo(0, 0)
      getRecord();
    }, []);

    const items = [
      {
        key: 'recordReadItems1',
        label: '대회명',
        children: compName,
        span:2,
      },
      {
        key: 'recordReadItems2',
        label: '구분 / 경기명',
        children: <Text>{대회종류번역기(matchContent?.matchType)} / {matchContent?.name}</Text>,
        span:2,
      },
      {
        key: 'recordReadItems3',
        label: '경기 장소',
        children: <Text>{matchContent?.location}</Text>,
        span:2,
      },
      {
        key: 'recordReadItems4',
        label: '경기 기록 번호',
        children: <Text>{recordContent?.recordNumber || ''}</Text>,
        span:2,
      },
      {
        key: 'recordReadItems5',
        label: '경기 시작 일시',
        children: <Text>{dayjs(matchContent?.startAt).format('YYYY-MM-DD HH:mm')}</Text>,
        span:2,
      },
      {
        key: 'recordReadItems6',
        label: <span>경기 종료 일시</span>,
        children: <Text>{dayjs(matchContent?.endAt).format('YYYY-MM-DD HH:mm')}</Text>,
        span:2,
      },
      
    ];

  return (
    <Wrapper ref={ref} content={printContent} className='recordPrint'> 
      <PageTitle className='notPrint'>
          <InvertImg src={images.icon.trophy} alt='' />
          <span onClick={goComp} className='pointer'>대회</span>
          <img src={images.icon.arrow} alt='' />
          <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
          <img src={images.icon.arrow} alt='' />
          <span className='pointer' onClick={()=>{goMatchEdit(compId, matchId, compName)}}>
            {matchContent?.homeTeam.name} VS {matchContent?.awayTeam.name} {matchContent?.name
            ? <span>&#40;{matchContent?.name}&#41;</span> 
            : ''}
          </span>
          <BlueImg src={images.icon.arrow} alt='' />
          <span className='blue'>경기 기록지</span>
      </PageTitle>
      <TitleBox>
            <Title>경기 기록지</Title>
            <PrintButtonBox className='notPrint'>
            <ReactToPrint trigger={() => 
              <Button style={{backgroundColor:'#A3A3A3', color:'white'}} >
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={images.icon.print} alt='' style={{ marginRight: '8px' }} />
                  프린트
                </span>
              </Button>
              } content={() => ref.current}/>

            </PrintButtonBox>
            <ButtonBox className='notPrint'>
                <Link to={`/comp/${compId}/match/${matchId}/recordEdit?compName=${encodeURIComponent(compName)}`}><Button type='primary' danger>경기 기록지 수정</Button></Link>
            </ButtonBox>
        </TitleBox>

        <Descriptions bordered items={items} style={{marginBottom:'16px'}}/>

        <RecordResult form={form} recordContent={recordContent} matchId={createId}
          home={matchContent?.homeTeam.name} away={matchContent?.awayTeam.name} 
          createRecord={createRecord} recordResult={recordResult}
        />

        <Lineup
          type='read'
          homeUniform={matchContent?.homeTeamUniform} awayUniform={matchContent?.awayTeamUniform}
          home={matchContent?.homeTeam.name} away={matchContent?.awayTeam.name}
          form={form} matchId={createId} recordContent={recordContent?.record} createRecord={createRecord}
          matchName={matchContent?.name} matchTime={matchContent?.startAt} matchLocation={matchContent?.location}
          lineupContent={lineupContent}
        />   
    </Wrapper>
  )
}

export default Record

const Wrapper = styled.div`
.ant-descriptions-item-label {
  width: 197px;
}
`;

const PrintButtonBox = styled.div`
margin-left: auto;
`;

const ButtonBox = styled.div`
margin-right: 8px;
`;