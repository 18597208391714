import React from 'react'
import { Button, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const CancelModal = ({onOk, inactive}) => {
    const { confirm } = Modal;
    const buttonString = inactive ? '취소된 경기' : '경기 취소하기'

    const showConfirm = () => {
      confirm({
        title: '취소 하시겠습니까?',
        content: 
        <>
        경기를 취소하면 정보를 되돌릴 수 없습니다. <br/>정말 취소하시겠습니까?
        </>,
        icon: <ExclamationCircleFilled />, 
        okText: '확인',
        okType: 'danger',
        cancelText: '취소',
        onOk(){ onOk() },
        onCancel(){},
      });
    };

  return (
    <Button danger type='text' disabled={inactive} onClick={showConfirm}>{buttonString}</Button>
  );
};

export default CancelModal
