import React from 'react'
import { Button, Descriptions, Image, Modal } from 'antd';
import { styled } from 'styled-components'
import { ExclamationCircleFilled } from '@ant-design/icons';


const ProfileModal = ({ open, setOpen, profile }) => {
    const handleCancel = () => {
        setOpen(false);
    };  

  return (
    <Modal
      open={open}
      centered
      title={
        <span>
          선수 프로필 사진 상세 확인
        </span>
      }
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleCancel}>확인</Button>
      ]}
    > 
      <Wrapper>
        <ImgBox><Image src={profile} alt='' /></ImgBox>
      </Wrapper>
    </Modal>
  )
}

export default ProfileModal

const Wrapper = styled.div`
margin-top: 32px;
  svg{
    margin-right: 8px;
  }
`;

const ImgBox = styled.div`
width: 300px;
height: 300px;
margin: auto;
position: relative;
display: flex;
align-items: center; 
overflow-y: hidden;
border-radius: 12px;
img {
    width: 100%;
    height: 100%;
}
`;