import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'antd';
import { styled } from 'styled-components'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import QR from 'qrcode.react';
import ReactToPrint from "react-to-print";
import images from '../../common/images';

const QRModal = ({ open, setOpen, qrCodeValue, matchContent, compName }) => {
    const [printContent, setPrintContent] = useState("");
    const ref = useRef();
    const printRef = useRef();

    const startDate = dayjs(matchContent?.startAt);
    const formattedDate = startDate.format(`YYYY년 MM월 DD일 (dd) HH:mm 경기`);

    const handleCancel = () => {
        setOpen(false);
    };  

    const QRBox = () => (
        <QR className='qrBox'
            id='샘플임'
            value={qrCodeValue}
            size={350}
            level={"H"}
            // includeMargin={true}
            fgColor={"#111111"}
        />
    );

    useEffect(() => { 
      if (qrCodeValue) {
        handlePrint();
      }
    }, [qrCodeValue]);

    const handlePrint = () => { 
      if (printRef.current) {
        printRef.current.click();  
      }
    };

  return (
    <Modal
      open={open}
      centered
      title=''
      onCancel={handleCancel}
      footer={[
        <ButtonBox>
        <ReactToPrint trigger={() => 
          <Button style={{backgroundColor:'#A3A3A3', color:'white'}} ref={printRef}>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={images.icon.print} alt='' style={{ marginRight: '8px' }} />
          프린트
          </span>
        </Button>} content={() => ref.current}/>
        <Button type="primary" onClick={handleCancel}>닫기</Button>
        </ButtonBox>
      ]}
    >
      {qrCodeValue ? 
      <Wrapper>
        <QRBox/>

        <div style={{display:'none'}}>
            <PrintWrapper ref={ref} className='qrPrint'>
                <PrintTitle>
                  <ImgBoxMain src={images.main_logo} alt=''/>
                  <LogoTitle>내 스포츠 활동 기록의 시작</LogoTitle>
                  <SubLogoTitle>대회 신청부터 선수 검인, 경기 참가 인증, 경기 기록 확인까지 WEDID로!</SubLogoTitle>
                </PrintTitle>
                <CompTitle>{compName}</CompTitle>
                <CompDate>{formattedDate}</CompDate>
                <CompNameBox>{matchContent?.name}</CompNameBox>
                <QRBox/>
                <InfoText>
                  <div>경기 시작 시간 이후 3시간 까지 QR코드가 유효 합니다</div>
                  <div>WEDID APP 실행 → 홈 화면에서 경기 시작 버튼</div>
                </InfoText>
                <TeamWrapper>
                    <TeamContainer>
                        <TeamBox>HOME</TeamBox>
                        <TeamName>{matchContent?.homeTeam.name}</TeamName>
                    </TeamContainer>
                    <VSBox>VS</VSBox>
                    <TeamContainer>
                        <TeamBox>AWAY</TeamBox>
                        <TeamName>{matchContent?.awayTeam.name}</TeamName>
                    </TeamContainer>
                </TeamWrapper>
                <ImgBox src={images.logo_with} alt=''/>
            </PrintWrapper>
        </div>
      </Wrapper> 
      : <Wrapper></Wrapper>}
    </Modal>
  )
}

export default QRModal

const Wrapper = styled.div`
padding-top: 50px;
padding-bottom: 50px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
`;

const PrintWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100vw;
text-align: center;
justify-content: center;
align-items: center;
`;

const PrintTitle = styled.div`
width: 100%;
height: 4.75cm;
background-color: black;
`;

const CompTitle = styled.div`
max-width: 80vw;
margin-top: 8.9vw;
color: #000;
font-family: 'NanumSquareNeo900';
font-size: 3.3vw;  
line-height: 23px;
`;

const CompDate = styled.div`
margin-top: 1.8vw;
font-size: 2.68vw; 
line-height: 23px; 
`;

const CompNameBox = styled.div`
font-size: 3vw; 
margin-top: 1.8vw;
margin-bottom: 3vw;
`

const InfoText = styled.div`
margin-top: 3vw;
font-size: 1.8vw;
:last-child {
  margin-top: 1vw;
}
`;

const TeamWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top: 8.4vw;
margin-bottom: 3vw;
`;

const TeamContainer = styled.div`
width: 33vw;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
justify-content: center;
`;

const TeamBox = styled.div`
background: #000;
width: 13.8vw;
height: 5.78vw;
border-radius: 1.7vw;
color: white;
font-size: 3.2vw;
line-height: 5.78vw;
font-family: 'NanumSquareNeo900';
align-items: center;
`;

const TeamName = styled.div`
margin-top: 3vw;
color: #000;
font-size: 3.2vw;
font-family: 'NanumSquareNeo900';
`;

const VSBox = styled.div`
font-size: 3.1vw;
font-family: 'NanumSquareNeo900';
/* margin-left: 9.6vw;
margin-right: 9.6vw; */
/* margin-left: auto;
margin-right: auto; */
/* width: 24vw; */
margin-top: 0.8vw;
`;

const ImgBox = styled.img`
width: 14vw;
margin-top: 8vw;
`;

const ImgBoxMain = styled.img`
width: 27.5vw;
margin-top: 3vw;
`;

const LogoTitle = styled.div`
color: white;
font-size: 1.6vw;
margin-top: -1.5vw;
`;

const SubLogoTitle = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 48.5vw;
height: 3vw;
border-radius: 1.83vw;
background-color: white;
font-size: 1.46vw;
margin-left: auto;
margin-right: auto;
margin-top: 1.89vw;
`;

const ButtonBox = styled.div`
display: flex;
justify-content: flex-end;
`;