import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useGetCompList() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN, REFRESH_TOKEN]);
    const [compList, setCompList] = useState(null);
    
    const getCompList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${cookies[ACCESS_TOKEN]}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };
            
            const response = await axios.get(`${baseUrl}/competition?sortBy=COMPETITION_STATE,CREATED_AT&orderBy=desc,desc`, { headers });
            
            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setCompList(response.data.data);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(cookies[ACCESS_TOKEN]) {
            getCompList();
        }
    }, []);

    return { compList };
}

export default useGetCompList;
