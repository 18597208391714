import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import StyleAtoms from '../../common/StyleAtoms';
import StyleAtomsRead from '../../common/StyleAtomsRead';
import images from '../../common/images';
import { Image, Button, Collapse} from 'antd';
import { Link, useParams } from 'react-router-dom';
import useReadComp from '../../hooks/Comp/useReadComp';
import {대회상태번역기, 대회종류번역기} from '../../common/util'
import dayjs from 'dayjs';
import Navigation from '../../common/Navigation';
import ReactToPrint from "react-to-print";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination } from 'swiper/modules';



const CompDetailRead = () => {
    const [printContent, setPrintContent] = useState("");
    const ref = useRef();
    const {PageTitle, Title, TitleBox, InvertImg, BlueImg, ButtonBox } = StyleAtoms();
    const { Container, BannerBox, DetailBoxContainer, DetailBox, InfoContainer, 
        Box, Row, Col, ColLong, InfoTextContainer, } = StyleAtomsRead();
    const { compId } = useParams();
    const { compContent } = useReadComp();
    const {goComp} = Navigation();
    const hostList = compContent?.competitionFirm.filter(item => item.firmType === "HOST");
    const orgaList = compContent?.competitionFirm.filter(item => item.firmType === "ORGANIZER");
    const sponList = compContent?.competitionFirm.filter(item => item.firmType === "SPONSOR");

    const moreItems = [
        {
            key: '1',
            label: '더보기',
            children: 
            <>
            <InfoTextContainer>
                <Box>
                    <Row>
                        <Col>주최</Col>
                        <ColLong>
                            {hostList?.map((a,i)=>(
                            <span key={a.firm.id}>
                                {a.firm.name}
                                {i < hostList.length - 1 ? ', ' : ''}
                            </span>
                            ))}
                        </ColLong>
                    </Row>
                </Box>
                <Box>
                    <Row>
                    <Col>주관</Col>
                    <ColLong>
                        {orgaList?.map((a,i)=>(
                            <span key={a.firm.id}>
                                {a.firm.name}
                                {i < orgaList.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </ColLong>
                    </Row>
                </Box>
                <Box>
                    <Row>
                        <Col>후원</Col>
                        <ColLong>{sponList?.map((a,i)=>(
                            <span key={a.firm.id}>
                                {a.firm.name}
                                {i < sponList.length - 1 ? ', ' : ''}
                            </span>
                            ))}</ColLong>
                    </Row>
                </Box>
            </InfoTextContainer>
            <InfoTextContainer>
                <Box>
                    <Row>
                        <Col2>참고 링크</Col2>
                        <ColLong2>
                            <ColLong3>
                                <Link to={compContent?.link} target="_blank" rel="noopener noreferrer">
                                    {compContent?.link}
                                </Link>
                            </ColLong3>
                        </ColLong2>
                    </Row>
                </Box>
            </InfoTextContainer>
            <InfoTextContainer>
                <Box>
                    <Row>
                        <Col2>대회 제약 조건</Col2>
                        <ColLong2>
                            <ColLong3>
                                {compContent?.competitionConstraint?.map((a,i)=>(
                                <span key={i}>
                                    {a.constraint.korTitle} : {a.constraint.korTitle === '문서발급일' ? 
                                    dayjs(a.value).format('YY.MM.DD(dd)')
                                    : a.value
                                    }
                                    {i < compContent?.competitionConstraint?.length - 1 ? `, ` : ''} 
                                </span>
                                ))}
                            </ColLong3>
                        </ColLong2>
                    </Row>
                </Box>
            </InfoTextContainer>
            <InfoTextContainer>
                <Box>
                    <Row>
                        <Col2>대회 상세 정보</Col2>
                        <ColLong2>
                            <ColLong3>{compContent?.detail}</ColLong3>
                        </ColLong2>
                    </Row>
                </Box>
            </InfoTextContainer>
            <InfoTextContainer>
                <Box>
                    <Row>
                        <Col2>대회 정책</Col2>
                        <ColLong2>
                            <ColLong3>{compContent?.policy}</ColLong3>
                        </ColLong2>
                    </Row>
                </Box>
            </InfoTextContainer>
            </>
        },
    ]
    const detailImages = compContent?.detailImage.concat(compContent?.fixtureImage) || [];

    const skeletonCount = Math.max(0, 3 - detailImages.length);
  return (
    <>
    <Wrapper ref={ref} content='printContent' className='compPrint'>
        <PageTitle className='notPrint'>
            <InvertImg src={images.icon.trophy} alt='' />
            <span onClick={goComp} className='pointer'>대회</span>
            <BlueImg src={images.icon.arrow} alt='' />
            <span className='blue'>{compContent?.name}</span>
        </PageTitle>
        <TitleBox>
            <Title>대회 정보</Title>
            <ButtonBox className='notPrint'>
                <ReactToPrint trigger={() => 
                    <Button style={{backgroundColor:'#A3A3A3', color:'white'}} >
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={images.icon.print} alt='' style={{ marginRight: '8px' }} />
                    프린트
                    </span>
                    </Button>} content={() => ref.current}
                />
                <Link to={`/comp/${compId}/team?compName=${encodeURIComponent(compContent?.name)}`}>
                    <Button type='primary'>참가 팀 리스트</Button>
                </Link>
                <Link to={`/comp/${compId}/compEdit`}>
                    <Button type='primary' danger>정보 수정</Button>
                </Link>
            </ButtonBox>
        </TitleBox>
        <Container>
            <ImgContainer>
                <BannerBox>
                    <Image src={compContent?.bannerImage} alt=''/>
                </BannerBox>
                {/* <DetailBoxContainer>
                    <DetailBox>
                        {compContent?.detailImage[0] 
                        ? <Image src={compContent?.detailImage[0]} alt=''/>
                        : <img src={images.skeletone.detail} alt='' />}
                    </DetailBox>
                    <DetailBox>
                        {compContent?.detailImage[1] 
                        ? <Image src={compContent?.detailImage[1]} alt=''/>
                        : <img src={images.skeletone.detail} alt='' />}
                    </DetailBox>
                    <DetailBox>
                        {compContent?.fixtureImage[0] 
                        ? <Image src={compContent?.fixtureImage[0]} alt=''/>
                        : <img src={images.skeletone.detail} alt='' />}
                    </DetailBox>
                </DetailBoxContainer> */}

                <Swiper
                    spaceBetween={13}
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                >
                    {[...detailImages, ...Array(skeletonCount).fill(null)].map((image, index) => (
                        <SwiperSlide key={index}>
                            {image ? 
                            <Image src={image} alt='' /> 
                            : <DetailBox><img src={images.skeletone.detail} alt='' /></DetailBox>}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </ImgContainer>
            
            <InfoContainer>
                <CompTitle>{compContent?.name}</CompTitle>
                <InfoTextContainer>
                    <Box>
                        <Row>
                            <Col>대회 종류</Col>
                            <ColLong>{대회종류번역기(compContent?.competitionType)}</ColLong>
                        </Row>
                        <Row>
                            <Col>대회 일정</Col>
                            <ColLong>
                                {dayjs(compContent?.startAt).format('YY.MM.DD(dd)')} ~ {dayjs(compContent?.endAt).format('YY.MM.DD(dd)')}
                            </ColLong>
                        </Row>
                        <Row>
                            <Col>지역</Col>
                            <ColLong>{compContent?.locationCity1} {compContent?.locationCity2}</ColLong>
                        </Row>
                    </Box>                    
                    <Box>
                        <Row>
                            <Col>대회 상태</Col>
                            <ColLong>{대회상태번역기(compContent?.competitionState)}</ColLong>
                        </Row>
                        <Row>
                            <Col>접수 일정</Col>
                            <ColLong>{dayjs(compContent?.applyStartAt).format('YY.MM.DD(dd)')} ~ {dayjs(compContent?.applyEndAt).format('YY.MM.DD(dd)')}</ColLong>
                        </Row>
                        <Row>
                            <Col>대회 장소</Col>
                            <ColLong>{compContent?.locationDetail}</ColLong>
                        </Row>
                    </Box>
                    <Box>
                        <Row>
                            <Col>참가 비용</Col>
                            <ColLong>{compContent?.price}</ColLong>
                        </Row>
                        <Row>
                            <Col>포스팅 일정</Col>
                            <ColLong>{dayjs(compContent?.openAt).format('YY.MM.DD(dd)')} ~ {dayjs(compContent?.closeAt).format('YY.MM.DD(dd)')}</ColLong>
                        </Row>
                        <Row>
                            <Col>참가 대상 범위</Col>
                            <ColLong>{compContent?.target}</ColLong>
                        </Row>
                    </Box>
                </InfoTextContainer>
                <Collapse  ghost style={{marginTop:'11px'}} items={moreItems} />
            </InfoContainer>
        </Container>
    </Wrapper>
    </>
  )
}

export default CompDetailRead

const Wrapper = styled.div`
margin-bottom: 50px;
.ant-collapse-header {
    padding: 12px 0px !important;
    color: #818181 !important;
}
.ant-collapse-expand-icon {
    margin-left: 0 !important;
}
.ant-collapse-content-box {
    padding: 0px 0px !important;
}
.swiper-wrapper {
    height: 152px;
}
`;

const CompTitle = styled.div`
color: #323232;
font-family: 'NanumSquareNeo800';
font-size: 18px;
`;

const ImgContainer = styled.div`
width: 31.5%;
max-width: 279px;
`;

const Col2 = styled.div`
width: 11%;
color: #818181;
`

const ColLong2 = styled.div`
width: 89%;
`

const ColLong3 = styled.div`
max-width: 100%;
white-space: pre-line;
a {
    text-decoration: none;
    color: #3D3D3D;
}
`