import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: "wedid-350512",
  storageBucket: "wedid-350512.appspot.com",
  messagingSenderId: "708581610830",
  appId: "1:708581610830:web:cd5dc95db70bf60d353a1f",
  measurementId: "G-K9KBZ3C247"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
