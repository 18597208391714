
export const 아이디규칙 = '• 아이디: 3~15자의 영문 대소문자, 숫자와 특수기호(_)만 사용 가능합니다.'
export const 비번규칙 = '• 비밀번호: 5~15자의 영문 대소문자, 숫자와 특수기호(_)만 사용 가능합니다.'
export const 아이디안됨 = '• 사용 불가한 아이디입니다.'
export const 아이디됨 = '• 사용 가능한 아이디입니다!'
export const 비번됨 = '• 사용 가능한 비밀번호입니다!'


export const 대회상태안내문 = (
  <>
    • 준비: 대회 준비 중인 상태, 대회가 시작하기 전에 필요한 일들을 처리하는 중<br />
    • 접수: 신청 접수 중<br />
    • 대기: 신청 접수가 끝나고 시작하기 전<br />
    • 진행: 대회 진행 중<br />
    • 종료: 대회 종료<br />
    • 취소: 대회가 취소된 상태
  </>
);

export const 배너이미지안내문 = '*16:9비율(393*221) 이미지가 최적의 사이즈입니다';
export const 상세이미지안내문 = '*3:4비율(345*459) 이미지가 최적의 사이즈입니다';

export const 기본배너이미지 = 'https://wedid-prod.s3.ap-northeast-2.amazonaws.com/competition/banner/default.png';

export const 가이드북관리자 = 'https://firebasestorage.googleapis.com/v0/b/wedid-350512.appspot.com/o/admin%2FguideBook%2FWEDID%20%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3%E1%84%87%E1%85%AE%E1%86%A8%20%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5%E1%84%8C%E1%85%A1%E1%84%8B%E1%85%AD%E1%86%BC.pdf?alt=media&token=d3946e1e-ee30-4af7-b79a-946abf0ee882';
export const 가이드북유저 = 'https://firebasestorage.googleapis.com/v0/b/wedid-350512.appspot.com/o/admin%2FguideBook%2FWEDID%20%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3%E1%84%87%E1%85%AE%E1%86%A8%20%E1%84%8B%E1%85%B2%E1%84%8C%E1%85%A5%E1%84%8B%E1%85%AD%E1%86%BC.pdf?alt=media&token=42022843-2d7f-4444-86bd-fa4864386323';

export const inputFieldNames = [
  'homeTeamFirstHalfTimeScore',
  'awayTeamFirstHalfTimeScore',
  'homeTeamSecondHalfTimeGoal',
  'awayTeamSecondHalfTimeGoal',
  'homeTeamExtraTimeGoal',
  'awayTeamExtraTimeGoal',
  'detail',
  'recorder',
  'recordNumber',
  'referee',
  'assistantReferee1',
  'assistantReferee2',
  'fourthOfficial',
  'matchCommissioner',
  'firstHalfTime',
  'secondHalfTime',
  'firstHalfAddedTime',
  'secondHalfAddedTime',
  'firstHalfExtraTime',
  'secondHalfExtraTime',
  'firstHalfExtraAddedTime',
  'secondHalfExtraAddedTime',
  'homeTeamShootoutScore',
  'awayTeamShootoutScore',
];

export const inputFieldExtraNames = [
  'homeTeamExtraTimeGoal',
  'awayTeamExtraTimeGoal',
  'homeTeamShootoutScore',
  'awayTeamShootoutScore',
  'firstHalfExtraTime',
  'secondHalfExtraTime',
  'firstHalfExtraAddedTime',
  'secondHalfExtraAddedTime'
];

export const inputFieldScoreNames = [
  'goal_home',
  'goal_away',
  'ownGoal_home',
  'ownGoal_away',
  'shootOut_home',
  'shootOut_away',
  'substitute_home',
  'substitute_away',
  'penalty_home',
  'penalty_away'
];
