import React, { useEffect, useState } from 'react'
import { Button, Descriptions, Modal, Input, Form } from 'antd';
import { styled } from 'styled-components'
import { SettingOutlined, LockOutlined, UserOutlined, } from '@ant-design/icons';
import { 아이디규칙, 아이디됨, 아이디안됨, 비번규칙, 비번됨 } from '../../common/Data'
import useDuplicateCheck from '../../hooks/Signin/useDuplicateCheck';
import Notification from '../commons/Notification';
import useEditAccount from '../../hooks/Signin/useEditAccount';

const EditAccountModal = ({ open, setOpen }) => {
    const [form] = Form.useForm();
    const { dupCheck, dupCheckResult, setDepCheckResult } = useDuplicateCheck();
    const { editAccount, editAccountResult } = useEditAccount();
    const { contextHolder, didntDupCheck, wrongFormat, emptyPw, errorNoti, editSuccessNoti } = Notification();
    const [idText, setIdText] = useState(아이디규칙);
    const [pwText, setPwText] = useState(비번규칙);
    const [idColor, setIdColor] = useState('');
    const [pwColor, setPwColor] = useState('');

    const handleCancel = () => {
      form.setFieldsValue({ 
        id: null,
        pw: null
      })
      setDepCheckResult(null)
      setIdText(아이디규칙);
      setPwText(비번규칙);
      setIdColor('');
      setPwColor('');
      setOpen(false);
    };

    const infoChangeId = (value) => {
      setDepCheckResult(null);
      setIdText(아이디규칙);
      setIdColor('black');
    }

    const infoChangePw = (value) => {
      const pattern = /^[\w]{5,15}$/;
      if (!pattern.test(value) && value.length > 0) {
          setPwText(비번규칙)
          setPwColor('red');
      } else if(pattern.test(value) && value.length > 0) {
          setPwText(비번됨)
          setPwColor('green');
      } else {
          setPwText(비번규칙)
          setPwColor('black');
      }
    }

    const idCheck = () => {
      setDepCheckResult(null)
      const pattern = /^[\w]{3,15}$/;
      const idString = form.getFieldValue('id');
      if(pattern.test(idString) && idString?.length > 0) {
        dupCheck(idString)
      } else {
        setIdText(아이디규칙)
        setIdColor('red');
      }
    }

    const onSubmit = (values) => {
      const pwString = form.getFieldValue('pw');
      if(dupCheckResult === true && pwText === 비번됨) {
        editAccount(values)
      } else if(dupCheckResult !== true) {
        didntDupCheck();
      } else if (pwString && pwText === 비번규칙) {
        wrongFormat();
      } else if(!pwString) {
        emptyPw();
      }
    }

    useEffect(()=>{
      if(dupCheckResult === true) {
        setIdText(아이디됨);
        setIdColor('green');
      } else if(dupCheckResult === false) {
        setIdText(아이디안됨);
        setIdColor('red');
      } else {
        setIdText(아이디규칙);
      }
    },[dupCheckResult])
    
    useEffect(()=>{
      if(editAccountResult === true) {
        editSuccessNoti();
        handleCancel();
      } else if (editAccountResult === false) {
        errorNoti();
      }
    },[editAccountResult])

    const items = [
      {
        key: '1',
        label:
        <>
        <UserOutlined />
        <span>ID</span>
        </>,
        children:
        <Flex>
            <Form.Item name='id' style={{width:'100%'}}>
                <Input onChange={(e)=>{infoChangeId(e.target.value)}} />
            </Form.Item>
            <Button onClick={idCheck}>중복확인</Button>
        </Flex>,
        span:3,
      },
      {
        key: '2',
        label: 
        <>
        <LockOutlined />
        <span>PW</span>
        </>,
        children:
        <Form.Item name='pw'>
            <Input.Password onChange={(e)=>{infoChangePw(e.target.value)}}  />
        </Form.Item>
        ,
        span:3,
      },
    ];

  return (
    <Modal
      open={open}
      centered
      title={
        <span>
          <SettingOutlined style={{ marginRight: '8px', color: "#498AFF",}} />
          아이디 및 비밀번호 변경
        </span>
      }
      onCancel={handleCancel}
      footer={[
        <>
        <Button danger onClick={handleCancel}>취소</Button>
        <Button type="primary" onClick={form.submit}>확인</Button>
        </>
      ]}
    >
      <Wrapper>
        {contextHolder}
        <Form form={form} onFinish={onSubmit}>
            <Descriptions bordered items={items} />
        </Form>
        <InfoBox>
            <IdInfo color={idColor}>{idText}</IdInfo>
            <PwInfo color={pwColor}>{pwText}</PwInfo>
        </InfoBox>
      </Wrapper>
    </Modal>
  )
}

export default EditAccountModal

const Wrapper = styled.div`
margin-top: 32px;
margin-bottom: 32px;
  svg{
    margin-right: 8px;
  }
`;

const InfoBox = styled.div`
margin-top: 12px;
height: 44px;
`;

const IdInfo = styled.div`
color: ${props => props.color === 'red' ? '#FF4D4F' 
: props.color === 'green' ? '#20D167' : '#999'};
`;

const PwInfo = styled.div`
color: ${props => props.color === 'red' ? '#FF4D4F' 
: props.color === 'green' ? '#20D167' : '#999'};
`;

const Flex = styled.div`
display: flex;
gap: 10px;
`;