import { useNavigate } from 'react-router-dom';


const Navigation = () => {
    const navigate = useNavigate(); 

    const goComp = () => {
      navigate(`/comp`);
    }

    const goError = () => {
      navigate(`/error`);
    }
    
    const goCompList = (compId, compName) => {
      navigate(`/comp/${compId}?compName=${compName}`);
    }

    const goRecordRead = (compId, matchId, compName) => {
      navigate(`/comp/${compId}/match/${matchId}/record?compName=${compName}`);
    }

    const goTeamList = (compId, compName) => {
      navigate(`/comp/${compId}/team?compName=${compName}`);
    }

    const goMatchEdit = (compId, matchId, compName) => {
      navigate(`/comp/${compId}/match/${matchId}/matchEdit?compName=${compName}`);
    }

  return (
    {goComp, goError, goCompList, goRecordRead, goTeamList, goMatchEdit}
  )
}

export default Navigation