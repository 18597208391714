import React, { useEffect, useState } from 'react'
import { Table, Tag, Radio } from 'antd';
import dayjs from 'dayjs';
import useGetApplyMember from '../../hooks/Comp/useGetApplyMember';
import useValidateApplyMember from '../../hooks/Comp/useValidateApplyMember';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import styled from 'styled-components';
import Navigation from '../../common/Navigation';
import useRosterUpdate from '../../hooks/Comp/useRosterUpdate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import PlayerApproveModal from '../../components/Comp/Player/PlayerApproveModal';
import PlayerRejectModal from '../../components/Comp/Player/PlayerRejectModal';
import PlayerRosterUpdateModal from '../../components/Comp/Player/PlayerRosterUpdateModal';
import DocuModal from '../../components/Comp/Player/DocuModal';
import { GenderFormatter } from '../../common/util';
import ProfileModal from '../../components/Comp/Player/ProfileModal';
import { 선출번역기 } from '../../common/util';
import Message from '../../components/commons/Message';

const PlayerList = () => {
  const {
    PageTitle, SearchBox, SSearch, GreyText, BlueImg, InvertImg, 
    TitleBox, ButtonBox, SumBox, SumCol, SumColBl, Title2
  } = StyleAtoms();
  const { Column } = Table;
  const { compId, teamId } = useParams();
  const location = useLocation();
  const { compName, teamName } = queryString.parse(location.search);
  const navigate = useNavigate();
  const { contextHolder, messageSuccess, messageError, messageLoading, closeMessageLoading } = Message();
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [docuOpen, setDocuOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState('');
  const [selectedDocu, setSelectedDocu] = useState('');
  const [selectedDocuId, setSelectedDocuId] = useState('');  
  const [dataSource, setDataSource] = useState([]);  
  const { memberList, getApplyMember } = useGetApplyMember();
  const { validateApplyMember } = useValidateApplyMember();
  const {goComp, goCompList, goTeamList} = Navigation();
  const { updateRoster } = useRosterUpdate();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(8);

  const rowClassName = (record, index) => {
    if (index % 2 !== 0) {
      // 짝수 로우 클래스
      return 'even-row';
    }
    return '';
  };

  const calculateAge = (birthDate) => {
    const birthYear = new Date(birthDate).getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  const showDocuModal = (docu, id) => {
    if(docu) {
      setSelectedDocu(docu);
      setSelectedDocuId(id);
      setDocuOpen(true);
    }
  };

  const showProfileModal = (profile, valid) => {
    if(valid === 'VALID') {
      setSelectedProfile(profile);
      setProfileOpen(true);
    }
  };

  const handleSearch = () => {
    const searchTextLower = searchText.toLowerCase();  
    const filtered = dataSource.filter(item => item.name.toLowerCase().includes(searchTextLower));
    setFilteredData(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const docuSorter = (a, b) => {
    if (a.document && !b.document) {
      return -1;
    } else if (!a.document && b.document) {
      return 1;
    }
    return 0;
  };

  const validSorter = (a, b) => {
    const orderMap = {
      'INVALID': 0,
      'NOT_YET': 1,
      'VALID': 2,
    };
  
    const aOrder = orderMap[a.formerAthleteValidType] || 0;
    const bOrder = orderMap[b.formerAthleteValidType] || 0;
  
    if (aOrder < bOrder) {
      return -1;
    } else if (aOrder > bOrder) {
      return 1;
    }
  
    const aProfileOrder = orderMap[a.profileValidType] || 0;
    const bProfileOrder = orderMap[b.profileValidType] || 0;
  
    if (aProfileOrder < bProfileOrder) {
      return -1;
    } else if (aProfileOrder > bProfileOrder) {
      return 1;
    }
  
    return 0;
  };
 
  const onRadioChange = async (id, value) => {
    const result = await validateApplyMember(id, value);
    if (result !== false) {
      messageSuccess();
      getApplyMember();
    } else {
      messageError();
    }
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const checkAll = async (check) => {
    messageLoading();
    const acceptRequest = memberList?.map((a,i)=>{
      return ({
        'id': a.id,
        'isValidated': check
      })
    })

    const result = await validateApplyMember(null, null, acceptRequest)
    if (result !== false) {
      closeMessageLoading();
      messageSuccess();
      getApplyMember();
    } else {
      closeMessageLoading();
      messageError();
    }
  }

  const onRosterUpdate = async () => {
    messageLoading();

    const updResult = await updateRoster()
    if (updResult === 200) {
      closeMessageLoading();
      messageSuccess();
    } else {
      closeMessageLoading();
      messageError();
    }
  }


  useEffect(() => { 
    const initData = memberList?.map(item => ({
      ...item,
      name: item.member.name,
      gender: item.member.gender,
      birthDay: dayjs(item.member.birthDay).format('YYYY/MM/DD'),
      address: item.member.address,
      subAddress: item.member.subAddress,
      age: calculateAge(item.member.birthDay),
      profileValidType: item.teamValid.profileValidType,
      formerAthleteValidType: item.teamValid.formerAthleteValidType,
    }));
    setDataSource(initData);
  }, [memberList]); 

  return (
    <>
    {contextHolder}
    <PageTitle>
      <InvertImg src={images.icon.trophy} alt='' />
      <span onClick={goComp} className='pointer'>대회</span>
      <img src={images.icon.arrow} alt='' />
      <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
      <img src={images.icon.arrow} alt='' />
      <span className='pointer' onClick={()=>{goTeamList(compId, compName)}}>참가 팀 리스트</span>
      <BlueImg src={images.icon.arrow} alt='' />
      <span className='blue'>{teamName}</span>
    </PageTitle>
    <SearchBox>
      <SSearch
        placeholder="선수 이름을 검색해 보세요"
        onChange={handleSearchChange}
        onSearch={handleSearch}
        value={searchText}
      />
    </SearchBox>

    <Title2>참가 선수 리스트</Title2>
    <TitleBox>
      <SumBox>
        <SumColBl style={{textAlign:'center', display:'block'}}>
          <span>{teamName}</span>  
        </SumColBl>
        <SumColBl>
          <span>전체</span>  
          <span>{memberList?.length}</span>  
        </SumColBl>
        <SumCol>
          <span>선출</span>  
          <span>{memberList?.filter(item => item.teamValid.formerAthleteValidType === 'VALID').length}</span>  
        </SumCol>
        <SumCol>
          <span>승인</span>  
          <span>{memberList?.filter(item => item.isValidated === true).length}</span>  
        </SumCol>
        <SumCol>
          <span>대기</span>  
          <span>{memberList?.filter(item => item.isValidated === null).length}</span>  
        </SumCol>
        <SumCol>
          <span>거절</span>  
          <span>{memberList?.filter(item => item.isValidated === false).length}</span>  
        </SumCol>
      </SumBox>
      <ButtonBox>
        <PlayerApproveModal okCheck={checkAll} />
        <PlayerRejectModal okCheck={checkAll} />
        <PlayerRosterUpdateModal okCheck={onRosterUpdate} />
      </ButtonBox>
    </TitleBox>
    
    <Table dataSource={filteredData || dataSource} 
      pagination={{defaultPageSize: 8, position: ['bottomRight'], hideOnSinglePage: false, showSizeChanger:true, onChange:(page, pageSize)=>{onPageChange(page, pageSize)}}}
      rowClassName={rowClassName}
      rowKey={(record)=>record.id}
    >
      <Column title="#" key="order"
        render={(text, record, index) =>
          (index + 1) + currentPageSize * (currentPage - 1)
        }
      />
      <Column title="선수 이름" dataIndex="name" key="name" colSpan={1}/>
      <Column title={<span>생년월일 <GreyText>&#40;만나이&#41;</GreyText></span>} key="birthDay" 
      render={(record)=>(
        <span>{record.birthDay} &#40;{record.age}&#41;</span>
      )}/>
      <Column title="성별" dataIndex="gender" key="gender" 
        render={(gender)=>(GenderFormatter(gender))} />
      <Column title="주소" key="address" 
      render={(record)=>(
        <span>{record.address} {record.sub}</span>
      )}/>
      <Column title="선출 구분" dataIndex="formerAthleteValidType" key="formerAthleteValidType" sorter={validSorter}
        render={(text, record)=>(
          record.teamValid.formerAthleteType === 'NONE' 
          ? <Tag color={'red'} key={record}>비선출</Tag>
          : record.teamValid.formerAthleteValidType === 'NOT_YET' 
          ? <Tag color={'gold'} key={record}>확인 전</Tag> 
          :<Tag color={'blue'} key={record}>{선출번역기(record.teamValid.formerAthleteType)}</Tag> )}
      />
      <Column title="프로필 사진 체크" dataIndex="profileValidType" key="profileValidType" sorter={validSorter}
        render={(a)=>(
          a==='VALID' ? 
          <Tag color={'blue'} key={a}>일치</Tag> :
          a==='NOT_YET' ? 
          <Tag color={'gold'} key={a}>확인 전</Tag> :
          <Tag color={'red'} key={a}>불일치</Tag>)}
      />
      <Column title="프로필 사진 상세 확인" dataIndex="document" key="document" render={(text, record) => (
        <span onClick={() => showProfileModal(record.member.image, record.teamValid.profileValidType)} style={{cursor:'pointer'}}>
          <ActiveText isactive={record.teamValid.profileValidType==='VALID' ? true : null}>사진 상세 정보</ActiveText>
        </span>
      )} />
      <Column title="서류 등록" dataIndex="document" key="document" sorter={docuSorter}
        render={(document)=>(document ?
          <Tag color={'blue'} key={document}>
            <FontAwesomeIcon icon={faCircle} style={{verticalAlign:'middle'}} size='xs' />
          </Tag> :
          <Tag color={'red'} key={document}>
            <FontAwesomeIcon icon={faXmark} style={{verticalAlign:'middle'}}/>
          </Tag>
        )}
      />
      <Column title="서류 상세 확인" dataIndex="document" key="document" render={(text, record) => (
        <span onClick={() => showDocuModal(record.document, record.id)} style={{cursor:'pointer'}}>
          <ActiveText isactive={record.document}>서류 상세 정보</ActiveText>
        </span>
      )} />

      <Column
          title='참가 승인 여부'
          dataIndex="isValidated"
          key="isValidated"
          render={(isValidated, record) => (
            <SRadioGroup
              value={isValidated}
              onChange={(e) => onRadioChange(record.id, e.target.value)}
            >
              <Radio.Button checked={isValidated===true} value={true} >승인</Radio.Button>
              <Radio.Button checked={isValidated} value={null} >대기</Radio.Button>
              <Radio.Button checked={isValidated} value={false} >거부</Radio.Button>
            </SRadioGroup>
          )}
      />
    </Table>
    <DocuModal open={docuOpen} setOpen={setDocuOpen} docu={selectedDocu}/>
    <ProfileModal open={profileOpen} setOpen={setProfileOpen} profile={selectedProfile}/>
    </>
  )
}

export default PlayerList

const SRadioGroup = styled(Radio.Group)`
margin-top: -5px;
margin-bottom: -5px;
`;

const ActiveText = styled.span`
color: ${props => props.isactive !==null ? '#498AFF' : '#BFBFBF'};
margin-right: 5px;
`;