import React from 'react'
import CompDetailRead from '../../components/Comp/CompDetailRead'
import MatchList from '../../components/Match/MatchList'

const CompDetail = () => {
  return (
    <>
    <CompDetailRead/>
    <MatchList/>
    </>
  )
}

export default CompDetail