import React from 'react'
import { Button, Descriptions, Image, Modal } from 'antd';
import { styled } from 'styled-components'
import { DateDotFormatter, GenderFormatter, PlayerStatusFormatter, 선출번역기 } from '../../../common/util';
import dayjs from 'dayjs';

const PlayerDetailModal = ({ open, setOpen, detail, matchName, matchTime, matchLocation, home, away }) => {
  
    const handleCancel = () => {
        setOpen(false);
    };

  return (
    <Modal
      open={open}
      centered
      width={'1021px'}
      onCancel={handleCancel}
      footer={[
        <SButton key="submit" onClick={handleCancel}>확인</SButton>
      ]}
    >
      <TopTitle>QR 인식한 선수 정보</TopTitle>
      {detail ? 
      <Wrapper>
        <ImgBox>
          <Image src={detail.member.image} alt='' style={{borderRadius:'14px'}}/>
          <ImgOverlay validcheck={detail.teamValid.profileValidType}>
            {detail.teamValid.profileValidType === 'VALID' 
            ? '사진 일치' 
            : detail.teamValid.profileValidType === 'NOT_YET' 
            ? '' 
            : '사진 불일치'}
          </ImgOverlay>
        </ImgBox>
        <Box1>
          <Row>
            <Title>이름</Title>
            <Info>{detail.member.name}</Info>
          </Row>
          <Row>
            <Title>생년월일 / 성별</Title>
            <Info>{DateDotFormatter(detail.member.birthDay)} / {GenderFormatter(detail.member.gender)}</Info>
          </Row>
          <Row>
            <Title>선출 여부</Title>
            <Info>
              {detail.teamValid.formerAthleteType === 'NONE' 
              ? '비선출'
              : detail.teamValid.formerAthleteValidType === 'NOT_YET' 
              ? '확인 전'
              : 선출번역기(detail.teamValid.formerAthleteType)}
            </Info>
          </Row>
        </Box1>
        <Box2>
          <Row>
            <Title>소속팀</Title>
            <Info>{detail.isHomeTeam ? home : away}</Info>
          </Row>
          <Row>
            <Title>포지션 / 등번호 / 출전 정보</Title>
            <Info>{detail.position} / {detail.backNumber} / {PlayerStatusFormatter(detail.playerStatus)}</Info>
          </Row>
          <Row>
            <Title>Home vs Away</Title>
            <Info>{home} vs {away}</Info>
          </Row>
        </Box2>
        <Box2>
          <Row>
            <Title>경기명</Title>
            <Info>{matchName}</Info>
          </Row>
          <Row>
            <Title>경기 일시</Title>
            <Info>{dayjs(matchTime).format('YYYY.MM.DD(dd) HH:mm')}</Info>
          </Row>
          <Row>
            <Title>경기 장소</Title>
            <Info>{matchLocation}</Info>
          </Row>
        </Box2>
      </Wrapper> 
      : '정보 없음 !'}
    </Modal>
  )
}

export default PlayerDetailModal

const Wrapper = styled.div`
padding: 13px;
display: flex;
`;

const TopTitle = styled.div`
padding-left: 13px;
margin-bottom: 12px;
color: #1F1F1F; 
font-size: 20px; 
font-weight: 800;
`;

const ImgBox = styled.div`
flex: 22;
margin-right: 37px;
position: relative;
width: 200px;
height: 200px;
display: flex;
align-items: center; 
overflow-y: hidden;
border-radius: 12px;
`;

const Box1 = styled.div`
flex: 24;
`;

const Box2 = styled.div`
flex: 27;
`;

const Row = styled.div`

`;

const Title = styled.div`
color: #898989; 
font-size: 14px; 
font-weight: 400;
margin-bottom: 5px;
`

const Info = styled.div`
color: #1F1F1F; 
font-size: 18px; 
font-weight: 700;
margin-bottom: 15px;
max-width: 237px;
overflow-x: hidden; 
text-overflow: ellipsis; 
white-space: nowrap;  
`;

const SButton = styled.button`
width: 945px;
height: 58px;
font-size: 16px;
background-color: #2D2D2D;
border-radius: 12px;
color: #FFF;
border: none;
margin-right: 14px;
margin-bottom: 10px;
cursor: pointer;
`;

const ImgOverlay = styled.div`
position: absolute;
bottom: 0;
left: 0;
width: 100%;
color: #fff;
border-radius: 0 0 12px 12px;  
background-color: ${props => props.validcheck === 'VALID' ? '#498AFF' : props.validcheck === 'NOT_YET' ? '' :'#FF8667'};

height: 35px;
line-height: 35px;
text-align: center;
`;