import base64 from 'base-64';
import { ACCESS_TOKEN } from './api';

export const 지역리스트 = [
    {
      "cd": "11", 
      "addr_name": "서울특별시"
    },
    {
      "cd": "21",
      "addr_name": "부산광역시"
    },
    {
      "cd": "22",
      "addr_name": "대구광역시"
    },
    {
      "cd": "23",
      "addr_name": "인천광역시"
    },
    {
      "cd": "24",
      "addr_name": "광주광역시"
    },
    {
      "cd": "25",
      "addr_name": "대전광역시"
    },
    {
      "cd": "26",
      "addr_name": "울산광역시"
    },
    {
      "cd": "29",
      "addr_name": "세종특별자치시"
    },
    {
      "cd": "31",
      "addr_name": "경기도"
    },
    {
      "cd": "32",
      "addr_name": "강원도"
    },
    {
      "cd": "33",
      "addr_name": "충청북도"
    },
    {
      "cd": "34",
      "addr_name": "충청남도"
    },
    {
      "cd": "35",
      "addr_name": "전라북도"
    },
    {
      "cd": "36",
      "addr_name": "전라남도"
    },
    {
      "cd": "37",
      "addr_name": "경상북도"
    },
    {
      "cd": "38",
      "addr_name": "경상남도"
    },
    {
      "cd": "39",
      "addr_name": "제주특별자치도"
    }
  ];

export const 지역필터 = 지역리스트.map((a,i)=>({ text:a.addr_name, value:a.addr_name }));

export const 대회종류필터 = [
  {text:'리그',value:'LEAGUE'},
  {text:'토너먼트',value:'TOURNAMENT'},
  {text:'복합',value:'COMPLEX'}
];

export const 경기종류필터 = [
  {text:'리그',value:'LEAGUE'},
  {text:'토너먼트',value:'TOURNAMENT'},
];

export const 경기상태필터 = [
  {text:'경기 전',value:'BEFORE'},
  {text:'경기 종료',value:'END'},
  {text:'경기 취소',value:'CANCEL'},
  {text:'기록 입력 완료',value:'INSERT'}
];

export const 대회상태필터 = [
  {text:'준비',value:'READY'},
  {text:'접수',value:'REGISTRATION'},
  {text:'대기',value:'UPCOMING'},
  {text:'진행',value:'ONGOING'},
  {text:'종료',value:'CLOSED'},
  {text:'취소',value:'CANCELED'},
];

export const 포지션필터 = [
  {text:'FW',value:'FW'},
  {text:'MF',value:'MF'},
  {text:'DF',value:'DF'},
  {text:'GK',value:'GK'}
];

export const 경기그룹필터 = (matchList) => (
  Array.from(new Set(matchList?.map(a => a.groupName)))
  .filter(groupName => groupName?.length > 0)
  .map(groupName => ({ text: groupName, value: groupName }))
)
  

export const 대회상태번역기 = (state) => (
  state==='READY' ? 
  '준비'
  : state === 'REGISTRATION' ?
  '접수'
  : state === 'UPCOMING' ?
  '대기'
  : state === 'ONGOING' ?
  '진행'
  : state === 'CLOSED' ?
  '종료'
  : state === 'CANCELED' ?
  '취소'
  : null
);

export const 대회종류번역기 = (type) => (
  type==='LEAGUE' ? 
  '리그'
  : type === 'TOURNAMENT' ?
  '토너먼트'
  : type === 'COMPLEX' ?
  '복합'
  : null
);

export const 경기상태번역기 = (type) => (
  type==='BEFORE' ? 
  '경기 전'
  : type === 'END' ?
  '경기 종료'
  : type === 'CANCEL' ?
  '경기 취소'
  : type === 'INSERT' ?
  '기록 입력 완료'
  : null
);

export const 선출번역기 = (string) => (
  string === 'ELEMENTARY' ? 
  '초등 선출'
  : string === 'MIDDLE' ?
  '중등 선출'
  : string === 'HIGH' ?
  '고등 선출'
  : string === 'UNIVERSITY' ?
  '대학 선출'
  : null
);


//Record AutoSave
// export const saveToSessionStorage = (matchId, name) => (e) => {
//   const {value} = e.target;
//   sessionStorage.setItem(matchId + name, value)
// }
// export const saveDateToSessionStorage = (matchId, name, date) => {
//   const timestamp = dayjs(date?.valueOf())
//   sessionStorage.setItem(matchId + name, timestamp)
// }
// export const saveArrayToSessionStorage = (matchId, name, value) => {
//   const updatedValues = [...value];
//   sessionStorage.setItem(matchId + name, JSON.stringify(updatedValues));
// }


//StopWatch
export const formatStopWatchTime = (time) => {
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time % 60000) / 1000);

  const pad = (value) => (value < 10 ? `0${value}` : value);

  return `${pad(minutes)} : ${pad(seconds)}`;
};

export const DateDotFormatter = (string) => {
  const formattedString = string?.replace(/\//g, '.');
  return formattedString;
}

export const GenderFormatter = (string) => {
  const gender = string === 'MALE' ? '남' : string === 'FEMALE' ? '여' : '미입력'
  return gender;
}

export const PlayerStatusFormatter = (string) => {
  const starting = string === 'STARTING' || string === 'CAPTAIN' ? '선발' : string === 'BENCH' ? '후보' : '비선발'
  return starting;
}


export const PhoneNumberFormatter = (phoneNumber) => {
  return phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
} 

export const adminTypeChecker = (cookies) => {
  const jwtToken = cookies[ACCESS_TOKEN];
  let payload = jwtToken.substring(jwtToken.indexOf('.')+1,jwtToken.lastIndexOf('.'));
  let decodingInfo = base64.decode(payload);
  let decodingInfoJson = JSON.parse(decodingInfo);
  let adminType = decodingInfoJson.roles.includes("ROLE_ADMIN_SUPER") ? 'superUser' : 'commonUser';
  return adminType
}


// record edit 페이지 소팅 로직
export const inputSort = (a, b) => {
  if (a.recordTime < b.recordTime) {
    return -1;
  } else if (a.recordTime > b.recordTime) {
    return 1;
  } else {
    if (a.recordExtraTime < b.recordExtraTime) {
      return -1;
    } else if (a.recordExtraTime > b.recordExtraTime) {
      return 1;
    } else if (a.substituteMemberRecordId) {
      return a.substituteMemberRecordId - b.substituteMemberRecordId;
    }
  }
};
export const shootOutSort = (a, b) => {
  if (a.recordOrder < b.recordOrder) {
    return -1;
  } else if (a.recordOrder > b.recordOrder) {
    return 1;
  } 
};