import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useCancelMatch() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [cancelMatchResult, setCancelMatchResult] = useState(null);
    const { matchId } = useParams();
    
    const cancelMatch = async () => {
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            const response = await axios.put(`${baseUrl}/match-schedule/${matchId}/cancel`, null, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setCancelMatchResult(response.data.data);
        } catch (error) {
            console.error(error);
            setCancelMatchResult(false)
        }
    };
    return { cancelMatchResult, cancelMatch, setCancelMatchResult };
}

export default useCancelMatch;
