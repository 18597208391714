import React, { useEffect, useState } from 'react'
import { Button, Table, Tag,  } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { adminTypeChecker, 지역필터 } from '../../../common/util';
import useGetApplyTeam from '../../../hooks/Comp/useGetApplyTeam';
import StyleAtoms from '../../../common/StyleAtoms';
import images from '../../../common/images';
import { styled } from 'styled-components'
import Navigation from '../../../common/Navigation';
import { PhoneNumberFormatter } from '../../../common/util';
import Message from '../../../components/commons/Message';
import useDeleteTeam from '../../../hooks/Comp/SuperUser/useDeleteTeam'
import { useCookies } from 'react-cookie';
import { ACCESS_TOKEN } from '../../../common/api';

const TeamListEdit = () => {
  const {PageTitle, SearchBox, SSearch, GreyText, BlueImg, InvertImg, 
    TitleBox, Title2, SumBox, SumCol, SumColBl, ButtonBox } = StyleAtoms();
  const { Column } = Table;
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { compId } = useParams();
  const location = useLocation();
  const { compName } = queryString.parse(location.search);
  const { teamList, getApplyTeam } = useGetApplyTeam();
  const {goComp, goError, goCompList, goTeamList} = Navigation();
  const { contextHolder, messageSuccess, messageError, 
    messageLoading, closeMessageLoading, messageEmptySelection } = Message();
  const { deleteTeam } = useDeleteTeam();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(8);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
  const adminType = adminTypeChecker(cookies);

  const rowClassName = (record, index) => {
    if (index % 2 !== 0) {
      // 짝수 로우 클래스
      return 'even-row';
    }
    return '';
  };

  const handleSearch = () => {
    const searchTextLower = searchText.toLowerCase();  
    const filtered = teamList.filter(item => item.name.toLowerCase().includes(searchTextLower));
    setFilteredData(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onDeleteTeam = async () => {
    if(selectedRowKeys.length === 0) {
      messageEmptySelection();
    } else {
      messageLoading();
      const updResult = await deleteTeam(selectedRowKeys)
      if (updResult === 200) {
        closeMessageLoading();
        messageSuccess();
      } else {
        closeMessageLoading();
        messageError();
      }
      getApplyTeam(compId);
      setSelectedRowKeys([])
    }
  }
  
  useEffect(()=>{
    if(adminType==='commonUser') {
      goError();
    } else {
      getApplyTeam(compId);
    }
  },[])

  return (
    <Container>
      {contextHolder}
      <PageTitle>
        <InvertImg src={images.icon.trophy} alt='' />
        <span onClick={goComp} className='pointer'>대회</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goTeamList(compId, compName)}}>참가 팀 리스트</span>
        <BlueImg src={images.icon.arrow} alt='' />
        <span className='blue'>참가 팀 리스트 수정</span>
      </PageTitle>
      <SearchBox>
        <SSearch
          placeholder="팀 이름을 검색해 보세요"
          onChange={handleSearchChange}
          onSearch={handleSearch}
          value={searchText}
        />
      </SearchBox>
      <Title2>참가 팀 리스트 수정</Title2>

      <TitleBox>
        <SumBox>
          <SumColBl>
            <span>전체</span>  
            <span>{teamList?.length}</span>  
          </SumColBl>
          <SumCol>
            <span>선택</span>
            <span>{selectedRowKeys.length}</span>  
          </SumCol>
        </SumBox>
        <ButtonBox>
            <Button type='primary' danger onClick={onDeleteTeam}>선택 팀 삭제</Button>
        </ButtonBox>
      </TitleBox>
      
      <Table dataSource={filteredData || teamList} 
        pagination={{defaultPageSize: 8, position: ['bottomRight'], hideOnSinglePage: false, showSizeChanger:true, onChange:(page, pageSize)=>{onPageChange(page, pageSize)}}}
        rowClassName={rowClassName}
        rowKey={(record)=>record.id}
        rowSelection={rowSelection}
      >
        <Column title="신청 순서" key="order"
          render={(text, record, index) =>
            (index + 1) + currentPageSize * (currentPage - 1)
          }
          className='orderCol'
        />
        <Column title="팀 이름" dataIndex="name" key="name"/>
        <Column title="팀 지역" key="location" 
          filters={지역필터} onFilter={(value, record) => record.location.startsWith(value)} filterSearch={true} 
          render={(record)=>(
            <span>{record.location} {record.subLocation}</span>
        )}/>
        <Column title="신청자 연락처" key="represent" 
          render={(record)=>(
            <span>{record.represent.name}&nbsp;&nbsp;&nbsp;&nbsp;{PhoneNumberFormatter(record.represent.phone)}</span>
        )}/>
        
        <Column title={<span>인원 수 <GreyText>&#40;승인 인원수&#47;신청 인원수&#41;</GreyText></span>} 
          key="applyMemberCount"
          render={(record) => (
            <span>{record.applyMemberValidatedCount}/{record.applyMemberCount}</span>  
          )}
        />
        <Column title="팀 승인 여부" dataIndex="applyResult" key="applyResult" 
          render={(applyResult)=>(
            applyResult === 'ACCEPT'
            ? <Tag color={'blue'} key={document}>승인</Tag>
            : applyResult === 'WAIT'  
            ? <Tag color={'gold'} key={document}>대기</Tag>
            : <Tag color={'red'} key={document}>거절</Tag>)} 
        />
        
        <Column
          title="참가 선수 리스트"
          key="player"
          render={(record) => (
            <Link to={`/comp/${compId}/team/${record.id}/player?compName=${encodeURIComponent(compName)}&teamName=${encodeURIComponent(record.name)}`}>참가 선수 리스트</Link>
          )}
        />
      </Table>
    </Container>
  )
}

export default TeamListEdit

const Container = styled.div`
.orderCol {
  max-width: 60px;
}
`


