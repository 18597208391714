import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router-dom';
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useUpdateTeamApplication() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN, REFRESH_TOKEN]);
    const { compId } = useParams();
    
    const updateTeamApplication = async (teamId) => {
        try {
            const headers = {
                'Authorization': `Bearer ${cookies[ACCESS_TOKEN]}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
                'Content-type':'application/json'
            };

            const requestBody = {
                'teamId': teamId
            }

            const response = await axios(`${baseUrl}/competition/${compId}/apply-team/${teamId}/apply-member`, {
                method:'POST',
                headers,
                data:JSON.stringify(requestBody),
            });

            if(response.headers.authorization) {
                setCookie(ACCESS_TOKEN, response.headers.authorization); 
            }
            return response.status
            
        } catch (error) {
            console.error(error);
        }
    };

    return { updateTeamApplication };
}

export default useUpdateTeamApplication;
