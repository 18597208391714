import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { Input, Button, Form, Descriptions, Image, DatePicker, Tooltip, Space, Select, Upload, Checkbox, InputNumber } from 'antd';
import { MinusCircleOutlined, MinusOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import UpdateModal from '../../components/commons/UpdateModal';
import DeleteModal from '../../components/commons/DeleteModal';
import { Link, useNavigate } from 'react-router-dom';
import useReadComp from '../../hooks/Comp/useReadComp';
import useGetAddress from '../../hooks/useGetAddress';
import useGetFirm from '../../hooks/useGetFirm';
import useCreateComp from '../../hooks/Comp/useCreateComp';
import useCreateCompFD from '../../hooks/Comp/useCreateCompFD';
import useDeleteComp from '../../hooks/Comp/useDeleteComp';
import useUploadImg from '../../hooks/useUploadImg';
import useUploadDetailImg from '../../hooks/useUploadDetailImg';
import Notification from '../../components/commons/Notification';
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import Navigation from '../../common/Navigation';
import { 배너이미지안내문, 상세이미지안내문 } from '../../common/Data';


const CompDetailEdit = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const navigate = useNavigate(); 
  const { goComp, goCompList } = Navigation();
  const { deleteCompResult, deleteComp, setDeleteCompResult } = useDeleteComp();
  const { compContent } = useReadComp();
  const { regionList, cityList, getAddress, getCity } = useGetAddress();
  const { firmList } = useGetFirm();
  const { compResult, createComp, setCompResult } = useCreateCompFD();
  // const { uploadRequest } = useUploadImg();
  // const { uploadDetailRequest } = useUploadDetailImg();
  const [bannerThumb, setBannerThumb] = useState();
  const [detailThumb, setDetailThumb] = useState(); // 디테일 원래저장된거, 서버에서 오는거
  const [bracketThumb, setBracketThumb] = useState(); // 디테일 원래저장된거, 서버에서 오는거
  const [bannerUpdateThumb, setBannerUpdateThumb] = useState();
  const [detailUpdateThumb, setDetailUpdateThumb] = useState();
  const [bracketUpdateThumb, setBracketUpdateThumb] = useState();
  const [thumbVisible, setThumbVisible] = useState(false);
  const [detailThumbVisible, setDetailThumbVisible] = useState(false);
  const [bracketThumbVisible, setBracketThumbVisible] = useState(false);
  const [detailRemoveStatus, setDetailRemoveStatus] = useState([]);
  const [bracketRemoveStatus, setBracketRemoveStatus] = useState([]);
  const { contextHolder, loadingNoti, successNoti, errorNoti, closeLoadingNoti } = Notification();
  const { PageTitle, TitleBox, Title,  InvertImg, BlueImg, RedDot } = StyleAtoms();
  const [constraintValuePlaceholder, setConstraintValuePlaceholder] = useState('');

  const filteredDetailThumb = detailThumb?.filter((_, index) => !detailRemoveStatus.includes(index));
  const filteredBracketThumb = bracketThumb?.filter((_, index) => !bracketRemoveStatus.includes(index));


  const downloadImg = async (imgUrl) => {
    try {
      const response = await fetch(imgUrl);
      if(!response.ok) {
        throw new Error('이미지 다운로드 실패')
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
        console.error('오류 발생:', error);
    }
  }

  const onUpdateSubmit = async (values) => {
    window.scrollTo(0, 0)
    loadingNoti()
    let detailImageBlobs = [];
    let bracketImageBlobs = [];

    if(filteredDetailThumb) {
      detailImageBlobs = await Promise.all(filteredDetailThumb.map(async (detailImgUrl) => {
        const blob = await downloadImg(detailImgUrl);
        return new File([blob], 'detail_image.jpg', { type: blob.type });
      }));
    }

    if(filteredBracketThumb) {
      bracketImageBlobs = await Promise.all(filteredBracketThumb.map(downloadImg))
    }
    

    try {
      await createComp(values, bannerUpdateThumb, detailUpdateThumb, bracketUpdateThumb, detailImageBlobs, bracketImageBlobs);
    } catch (error) {
      console.error('오류 발생:', error);
    }
  };

  const onDeleteSubmit = () => {
    loadingNoti()
    deleteComp()
  };

  const handleRegionChange = (value) => {
    if(regionList) {
      const selectedRegion = regionList.find((region) => region.addr_name === value);
      getCity(selectedRegion?.cd);
    }
  };

  const handleBannerImgChange = (imgFile) => {
    if (imgFile?.fileList?.length > 0) {
      setThumbVisible(false); 
      setBannerUpdateThumb(imgFile.fileList)
    } else {
      setThumbVisible(true); 
      setBannerUpdateThumb(null)
    }

    setBannerUpdateThumb(imgFile.file.originFileObj)
  };

  const handleDetailImgChange = (imgFile, index) => {
    if (imgFile?.fileList?.length > 0) {
      setDetailUpdateThumb(imgFile.fileList)
    } else {
      setDetailUpdateThumb(null)
    }

    setDetailRemoveStatus((prevStatus) => {
      const indexToRemove = prevStatus.indexOf(index);
      if (indexToRemove === -1) {
        return [...prevStatus, index];
      } else {
        prevStatus.splice(indexToRemove, 1);
        return [...prevStatus];
      }
    });
  };

  const handleBracketImgChange = (imgFile, index) => {
    if (imgFile?.fileList?.length > 0) {
      setBracketUpdateThumb(imgFile.fileList)
    } else {
      setBracketUpdateThumb(null)
    }

    setBracketRemoveStatus((prevStatus) => {
      const indexToRemove = prevStatus.indexOf(index);
      if (indexToRemove === -1) {
        return [...prevStatus, index];
      } else {
        prevStatus.splice(indexToRemove, 1);
        return [...prevStatus];
      }
    });
  };

  const selectPriceType = (
    <Form.Item name='priceType'>
      <Select style={{ width: 85 }}>
        <Option value="팀당">팀당</Option>
        <Option value="개인당">개인당</Option>
      </Select>
    </Form.Item>
  );

  useEffect(()=>{ 
    const priceNumber = compContent?.price.match(/\d+/g);

    form.setFieldsValue({ 
      name: compContent?.name, 
      startAt: dayjs(compContent?.startAt), 
      endAt: dayjs(compContent?.endAt), 
      competitionType: compContent?.competitionType, 
      applyStartAt: dayjs(compContent?.applyStartAt), 
      applyEndAt: dayjs(compContent?.applyEndAt), 
      openAt: dayjs(compContent?.openAt),
      closeAt: dayjs(compContent?.closeAt),
      priceType: compContent?.price.includes('개인') ? '개인당' : '팀당',
      price: priceNumber,
      locationCity1: compContent?.locationCity1, 
      locationCity2: compContent?.locationCity2, 
      locationDetail: compContent?.locationDetail, 
      target: compContent?.target, 
      link: compContent?.link, 
      detail: compContent?.detail, 
      policy: compContent?.policy, 
      competitionFirm: compContent?.competitionFirm.map(item => {
        return {
          firmType: item.firmType,
          firmId: item.firm.id,
        };
      }),
      competitionConstraint: compContent?.competitionConstraint.map(item => {
        return {
          title: item.constraint.title,
          value: item.constraint.title === 'DOCUMENT_EXPIRE' 
          ? parseInt(dayjs.unix(item.value).format('YYYYMMDD'))
          : item.value,
        };
      }),
    });
    if(compContent?.bannerImage.length>0) { 
      setThumbVisible(true)  
      setBannerThumb(compContent.bannerImage)
    }

    if(compContent?.detailImage.length>0) { 
      setDetailThumbVisible(true)  
      setDetailThumb(compContent.detailImage)
    }

    if(compContent?.fixtureImage?.length>0) { 
      setBracketThumbVisible(true)  
      setBracketThumb(compContent.fixtureImage)
    }
    handleRegionChange(compContent?.locationCity1);
    
  },[compContent])

  useEffect(()=>{
    if (compResult === false) {
      closeLoadingNoti();
      errorNoti();
    } else if (compResult === true) {
      closeLoadingNoti();
      successNoti('수정'); 
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
    setCompResult(null);
  }, [compResult])

  useEffect(()=>{
    if (deleteCompResult === false) {
      closeLoadingNoti()
      errorNoti();
      setDeleteCompResult(null)
    } else if (deleteCompResult === true) {
      closeLoadingNoti()
      navigate(`/actionResult?compId=${encodeURIComponent('comp')}`, { replace: true });
    }
  }, [deleteCompResult])

  const items = [
    {
      key: '1',
      label: <DescTitle>대회 이름<RedDot/></DescTitle>,
      children: 
      <Form.Item name='name' rules={[{ required: true, message:'대회 이름 입력 필요' }]}>
        <Input placeholder='대회 이름을 입력해 주세요' />
      </Form.Item>,
      span:2,
    },
    {
      key: '2',
      label: <DescTitle>대회 종류<RedDot/></DescTitle>,
      children: 
      <Form.Item name='competitionType' rules={[{ required: true, message:'대회 종류 입력 필요' }]}>
        <Select placeholder='대회 종류를 선택해 주세요'>
          <Select.Option value="LEAGUE">리그</Select.Option>
          <Select.Option value="TOURNAMENT">토너먼트</Select.Option>
          <Select.Option value="COMPLEX">복합</Select.Option>
        </Select>
      </Form.Item>,
      span:1,
    },
    {
      key: '3',
      label: <DescTitle>대회 시작일 - 종료일<RedDot/></DescTitle>,
      children: 
      <InputContainer>
        <SFormItem name='startAt' rules={[{ required: true, message:'시작일 입력 필요' }]}>
          <DatePicker locale={locale} style={{width:'100%'}} placeholder='시작일' showTime format={'YYYY-MM-DD HH:mm'}/>
        </SFormItem>
        <InputText className='first'>부터</InputText>
        <SFormItem name='endAt' rules={[{ required: true, message:'종료일 입력 필요' }]}>
          <DatePicker locale={locale} style={{width:'100%'}} placeholder='종료일' showTime format={'YYYY-MM-DD HH:mm'}/>
        </SFormItem>
        <InputText>까지</InputText>
      </InputContainer>,
      span:2,
    },
    {
      key: '4',
      label: <DescTitle>대회 참가 비용<RedDot/></DescTitle>,
      children: 
      <InputContainer>
        <SFormItem name='price' rules={[{ required: true, message:'금액 입력 필요' }]}>
          <SInputNumberPrice
            addonBefore={selectPriceType}
            suffix='원'
            controls={false}
            formatter={(value) => {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }}
            parser={(value) => {
              return value.replace(/(,*)/g, '');
            }}
            placeholder="금액을 입력해 주세요"
          />
        </SFormItem>
      </InputContainer>,
      span:1,
    },
    {
      key: '5',
      label: <DescTitle>접수 시작일 - 종료일<RedDot/></DescTitle>,
      children:
      <InputContainer>
        <SFormItem name='applyStartAt' rules={[{ required: true, message:'시작일 입력 필요' }]}>
          <DatePicker locale={locale} style={{width:'100%'}} placeholder='접수 시작일' showTime format={'YYYY-MM-DD HH:mm'}/>
        </SFormItem>
        <InputText className='first'>부터</InputText>
        <SFormItem name='applyEndAt' rules={[{ required: true, message:'종료일 입력 필요' }]}>
          <DatePicker locale={locale} style={{width:'100%'}} placeholder='접수 종료일' showTime format={'YYYY-MM-DD HH:mm'}/>
        </SFormItem>
        <InputText>까지</InputText>
      </InputContainer>,
      span:2,
    },
    {
      key: '6',
      label: '참가 대상 범위',
      children:
      <Form.Item name='target'>
        <Input placeholder='나이, 학년 등' />
      </Form.Item>,
      span:1,
    },
    {
      key: '7',
      label: <DescTitle>포스팅 시작일 - 종료일<RedDot/></DescTitle>,
      children: 
      <InputContainer>
        <SFormItem name='openAt' rules={[{ required: true, message:'시작일 입력 필요' }]}>
          <DatePicker locale={locale} style={{width:'100%'}} placeholder='포스팅 시작일' showTime format={'YYYY-MM-DD HH:mm'}/>
        </SFormItem>
        <InputText className='first'>부터</InputText>
        <SFormItem name='closeAt' rules={[{ required: true, message:'종료일 입력 필요' }]}>
          <DatePicker locale={locale} style={{width:'100%'}} placeholder='포스팅 종료일' showTime format={'YYYY-MM-DD HH:mm'}/>
        </SFormItem>
        <InputText>까지</InputText>
      </InputContainer>,
      span:2,
    },
    {
      key: '8',
      label: '참고 링크',
      children:
      <Form.Item name='link'>
        <Input placeholder='www.projectwith.io' />
      </Form.Item>,
      span:1,
    },
    {
      key: '9',
      label: <DescTitle>지역<RedDot/></DescTitle>,
      children: 
      <InputContainer>
        <InputText className='second'>시/도</InputText>
        <SFormItem name='locationCity1' rules={[{ required: true, message:'지역 선택 필요' }]}>
          <Select onChange={handleRegionChange} style={{width:'100%'}} placeholder='지역을 선택해 주세요'>
            {regionList?.map((a,i)=>{return <Option key={a.cd} value={a.addr_name}>{a.addr_name}</Option> })}
          </Select>
        </SFormItem>
        <InputText className='second'>시군구</InputText>
        <SFormItem name='locationCity2' rules={[{ required: true, message:'지역 선택 필요' }]}>
          <Select placeholder='지역을 선택해 주세요'>
            {cityList?.map((a,i)=>{return <Option value={a.addr_name}>{a.addr_name}</Option> })}
          </Select>
        </SFormItem>
        <InputText className='second'>상세주소</InputText>
        <SFormItem name='locationDetail' rules={[{ required: true, message:'장소 입력 필요' }]}>
          <Input placeholder='대회 장소를 입력해 주세요' />
        </SFormItem>
      </InputContainer>,
      span:3,
    },
  ];

  const items2 = [
    {
      key: '13',
      label: 
      <>
      <DescTitle>
        대회 상세 정보
        <RedDot/>
      </DescTitle><br/>
      <DescSubTitle>* 최대 2000자</DescSubTitle>
      </>,
      children: 
      <SFormItemLong name='detail' rules={[{ required: true, message:'상세 정보 입력 필요' }]} style={{minWidth:'491px'}}>
        <Input.TextArea rows={5} maxLength={2000} placeholder='상세 정보를 입력해 주세요' showCount={true} />
      </SFormItemLong>,
      span:3,
    },
  ];

  const items3 = [
    {
      key: '14',
      label:
      <>
      <DescTitle>
        대회 정책
        <RedDot/>
      </DescTitle><br/>
      <DescSubTitle>* 최대 1000자</DescSubTitle>
      </>,
      children: 
      <SFormItemLong name='policy' rules={[{ required: true, message:'정책 입력 필요' }]} style={{minWidth:'380px'}}>
        <Input.TextArea rows={4} maxLength={1000} placeholder='대회 정책을 입력해 주세요' showCount={true} />
      </SFormItemLong>,
      span:3,
    },
  ];

  return (
    <Wrapper>
        {contextHolder}
        <PageTitle>
          <InvertImg src={images.icon.trophy} alt='' />
          <span onClick={goComp} className='pointer'>대회</span>
          <img src={images.icon.arrow} alt='' />
          <span onClick={()=>{goCompList(compContent.id, compContent.name)}} className='pointer'>
            {compContent?.name}
          </span>
          <BlueImg src={images.icon.arrow} alt='' />
          <span className='blue'>대회 정보 수정</span>
        </PageTitle>
        <TitleBox>
          <Title>대회 정보 수정</Title>
        </TitleBox>
        <Form form={form} onFinish={onUpdateSubmit}>
          <Descriptions bordered items={items} className='botGap'/>
          <Descriptions bordered items={items2} className='botGap'/>
          <Descriptions bordered items={items3} className='botGap'/>

          <Descriptions bordered className='botGap'>
            <Descriptions.Item label='대회 제약조건' span={3}>
              <Form.List name="competitionConstraint" >
                {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} style={{ display: 'flex', marginBottom: 16 }} align="baseline">
                            <Form.Item
                              {...restField}
                              name={[name, 'title']}
                              rules={[{ required: true, message: '조건 선택 필요' }]}
                              style={{width:'100%', minWidth:'350px'}}
                            >
                              <Select
                              placeholder='제약 조건 구분'
                              onChange={(value) => {
                                if (value === 'DOCUMENT_EXPIRE') {
                                  setConstraintValuePlaceholder((prevState) => ({
                                    ...prevState,
                                    [key]: '날짜 입력 ex)20230101',
                                  }));
                                } else {
                                  setConstraintValuePlaceholder((prevState) => ({
                                    ...prevState,
                                    [key]: '조건 값을 숫자로 입력해주세요',
                                  }));
                                }
                              }}>
                                <Option value="MIN_AGE">최저연령</Option>
                                <Option value="MAX_AGE">최고연령</Option>
                                <Option value="DOCUMENT_EXPIRE">문서발급일</Option>
                                <Option value="MIN_COMPETITION_APPLY_MEMBER_COUNT">최소 대회 신청인원</Option>
                                <Option value="MAX_COMPETITION_APPLY_MEMBER_COUNT">최대 대회 신청인원</Option>
                                <Option value="MIN_STARTING_LINEUP_MEMBER_COUNT">최소 출전명단 인원</Option>
                                <Option value="MAX_STARTING_LINEUP_MEMBER_COUNT">최대 출전명단 인원</Option>
                                <Option value="STARTING_LINEUP_VALID_MEMBER_COUNT">선발선수 인원</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'value']}
                              rules={[{ required: true, message: '조건 값 입력 필요' }]}
                              style={{width:'100%', minWidth:'350px'}}
                            >
                              <SInputNumber placeholder={constraintValuePlaceholder[key]} controls={false} style={{width:'100%', minWidth:'320px'}}/>
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <SButton type="dashed" style={{width:'50%', minWidth:'700px'}} onClick={() => add()} block icon={<PlusOutlined />}>조건 추가</SButton>
                        </Form.Item>
                      </>
                  )}
              </Form.List>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions bordered className='botGap'>
            <Descriptions.Item label={
              <>
              <DescTitle>
                기관
              </DescTitle><br/>
              <DescSubTitle>*선택하고자 하는 기관이 없다면 문의해주세요<br/>*문의주소 : contact@projectwith.io</DescSubTitle>
              </>}
            >
              <Form.List name="competitionFirm" >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display:'flex', marginBottom: 16 }} align="baseline">
                        <SFormItemLong
                          {...restField}
                          name={[name, 'firmType']}
                          rules={[{ required: true, message: '기관 구분 선택 필요' }]}
                        >
                          <Select placeholder='단체 구분' style={{width:'100%', minWidth:'350px'}}>
                            <Select.Option value="HOST">주최</Select.Option>
                            <Select.Option value="ORGANIZER">주관</Select.Option>
                            <Select.Option value="SPONSOR">후원</Select.Option>
                          </Select>
                        </SFormItemLong>
                        <SFormItemLong
                          {...restField}
                          name={[name, 'firmId']}
                          rules={[{ required: true, message: '기관 선택 필요' }]}
                        >
                          <Select placeholder='기관 선택' style={{width:'100%', minWidth:'350px'}}>
                            {firmList?.map((a,i)=>{return <Option value={a.id} key={a.id}>{a.name}</Option> })}
                          </Select>
                        </SFormItemLong>
                        <MinusCircleOutlined onClick={() => remove(name)} style={{color:'#999'}} />
                      </Space>
                    ))}
                    <Form.Item>
                      <SButton type="dashed" style={{width:'50%', minWidth:'700px'}}
                      onClick={() => add()} block icon={<PlusOutlined />}>기관 추가</SButton>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions bordered className='botGap'>
            <Descriptions.Item label={
              <>
              <DescTitle>
                배너 이미지
              </DescTitle><br/>
              <DescSubTitle>*1장만 업로드 가능합니다</DescSubTitle><br/>
              <DescSubTitle>*업로드 하지 않을 시 기본이미지가 적용됩니다</DescSubTitle><br/>
              <DescSubTitle>{배너이미지안내문}</DescSubTitle>
              </>} span={3}
            >
              <ImgContainer>
                <BannerImgBox visible={thumbVisible}>
                  <Image src={bannerThumb} alt=''
                  style={{
                    maxWidth:'90px',
                    maxHeight:'90px'
                    }} />
                </BannerImgBox>
                <Upload listType="picture-card" maxCount={1} onChange={handleBannerImgChange} onRemove={handleBannerImgChange}>
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </ImgContainer>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions bordered className='botGap'>
            <Descriptions.Item label={
              <>
              <DescTitle>
                상세 이미지
              </DescTitle><br/>
              <DescSubTitle>{상세이미지안내문}</DescSubTitle>
              </>} span={3}
            >
              <ImgContainer>
                {detailThumb?.map((a,i)=>(
                  <>
                  <DetailImgBox visible={detailRemoveStatus.includes(i) ? false : detailThumbVisible}>
                    <Image src={a} alt=''
                    style={{
                      maxWidth:'90px',
                      maxHeight:'90px'
                      // filter: removeStatus.includes(i) ? 'grayscale(100%) brightness(30%)' : 'none',
                    }}
                    />
                  </DetailImgBox>
                  <RemoveButton
                    onClick={() => handleDetailImgChange(null, i)}>
                    {detailRemoveStatus.includes(i) ? <UndoOutlined /> : <MinusOutlined />}
                  </RemoveButton>
                  </>
                  ))}
                  <Upload listType="picture-card" onChange={handleDetailImgChange} onRemove={handleDetailImgChange}>
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </ImgContainer>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions bordered className='botGap'>
            <Descriptions.Item label={
              <>
              <DescTitle>
                대회 대진표
              </DescTitle><br/>
              <DescSubTitle>{상세이미지안내문}</DescSubTitle>
              </>} span={3}
            >
              <ImgContainer>
                {bracketThumb?.map((a,i)=>(
                  <>
                  <DetailImgBox visible={bracketRemoveStatus.includes(i) ? false : bracketThumbVisible}>
                    <Image src={a} alt=''
                    style={{
                      maxWidth:'90px',
                      maxHeight:'90px'
                      // filter: bracketRemoveStatus.includes(i) ? 'grayscale(100%) brightness(30%)' : 'none',
                    }}
                    />
                  </DetailImgBox>
                  <RemoveButton
                    onClick={() => handleBracketImgChange(null, i)}>
                    {bracketRemoveStatus.includes(i) ? <UndoOutlined /> : <MinusOutlined />}
                  </RemoveButton>
                  </>
                  ))}
                  <Upload listType="picture-card" onChange={handleBracketImgChange} onRemove={handleBracketImgChange}>
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </ImgContainer>
            </Descriptions.Item>
          </Descriptions>
        </Form>

        <Container>
          <DeleteModal onOk={onDeleteSubmit} />
          <UpdateModal form={form} />
        </Container>
    </Wrapper>
  )
}

export default CompDetailEdit

const Wrapper = styled.div`
.botGap {
  margin-bottom: 16px;
}
.ant-descriptions-item-label {
  width: 259px;
}
`;

const Container = styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
    button{
        margin-right: 10px;
    }
`;

const ImgContainer = styled.div`
display: flex;
/* text-align: center; */
    input{
        /* width: 80%; */
        margin-right: 8px;
        margin-top: 32px;
    }
`;

const ImgBox = styled.div`
justify-content: center;
align-items: center; 
width: 102px;
height: 102px;
border: 1px solid #C4C4C4;
border-radius: 10px;
margin-right: 8px;
padding: 8px;
`;

const BannerImgBox = styled(ImgBox)`
min-width: 100px;
min-height: 100px;
display: ${props => (props.visible ? 'flex' : 'none')};
`;

const DetailImgBox = styled(ImgBox)`
min-width: 100px;
min-height: 100px;
display: ${props => (props.visible ? 'flex' : 'none')};
`;

const RemoveButton = styled.span`
height: 100%;
align-items: center;
margin-top: auto;
margin-bottom: auto;
margin-right: 16px;
padding: 6px 8px;
border-radius: 8px;
border: 1px solid #C4C4C4;
cursor: pointer;
`;

const InputContainer = styled.div`
display: flex;
align-items: end;
width: 100%;
.first {
  margin-right: 23px;
}
.second {
  margin-right: 15px;
}
`;

const InputText = styled.span`
margin-left: 9px;
white-space: nowrap;
color: #999;
`;

const SFormItem = styled(Form.Item)`
width: 100%;
  .ant-input-number {
      height: 32px;
    }
`;

const DescTitle = styled.span`
padding-bottom: 15px;
`;

const DescSubTitle = styled.span`
color: #498AFF;
font-size: 9px;
`;

const SFormItemLong = styled(Form.Item)`
width: 100%;
`;

const SInputNumber = styled(InputNumber)`
width: 150px;
`;

const SInputNumberPrice = styled(InputNumber)`
width: 100%;
`;

const SButton = styled(Button)`
color: #999;
`;
