import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../common/api';

function useGetQr() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [qrCode, setQrCode] = useState(null);

    const getQr = async (matchId) => {
        try {
            const token = cookies[ACCESS_TOKEN];
            const headers = {
                Authorization: `Bearer ${token}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };

            const response = await axios.get(`${baseUrl}/match-schedule/${matchId}/qr`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setQrCode(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }; 

    return { getQr, qrCode};
}

export default useGetQr;
