import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Input, Button, Form, Descriptions, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR'; 
import useGetApplyTeam from '../../hooks/Comp/useGetApplyTeam';
import useCreateMatch from '../../hooks/Match/useCreateMatch'; 
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Notification from '../../components/commons/Notification';
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import Navigation from '../../common/Navigation';

const MatchCreate = () => {
    const {PageTitle, BlueImg, TitleBox, Title, SubTitle, SSearch, InvertImg, CompTitle, RedDot } = StyleAtoms();
    const navigate = useNavigate(); 
    const { compId } = useParams();
    const location = useLocation();
    const { compName } = queryString.parse(location.search);
    const { Option } = Select;
    const [teamNameList, setTeamNameList] = useState([]);
    const { teamList, getApplyTeam } = useGetApplyTeam();
    const [selectedValue, setSelectedValue] = useState(null);
    const [form] = Form.useForm();
    const { matchResult, createMatch, setMatchResult } = useCreateMatch();
    const { contextHolder, successNoti, errorNoti, loadingNoti, closeLoadingNoti } = Notification();
    const {goComp, goCompList} = Navigation();


    const onSubmit = (values) => {
        window.scrollTo(0, 0)
        loadingNoti()
        createMatch(values);
    };

      const onTeamSelection = (values) => {
        if (form.getFieldValue('awayTeamId') === form.getFieldValue('homeTeamId')) {
          form.setFieldsValue({
            awayTeamId: null, 
          });
        }
      }

      useEffect(()=>{
        setSelectedValue(compId)
      },[])

      useEffect(() => {
        if (selectedValue) {
          getApplyTeam(selectedValue);
        } 
      }, [selectedValue]);
      
      useEffect(() => {
        if (teamList) {
          const validatedTeams = teamList?.filter((a)=>(a.applyResult === 'ACCEPT'))
          setTeamNameList(validatedTeams);
        }
      }, [teamList]);

      useEffect(()=>{
        if (matchResult === false) {
          closeLoadingNoti()
          errorNoti();
        } else if ( matchResult === true) {
          closeLoadingNoti()
          successNoti('생성');
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
        setMatchResult(null);
      }, [matchResult])

      const items = [
        {
          key: '1',
          label: 
          <>
            <DescTitle>경기명<RedDot/></DescTitle>
            <br/>
            <DescSubTitle>*최대 50자</DescSubTitle>
          </>,
          children: 
          <Form.Item name='name' rules={[{ required: true, message:'경기명 입력 필요' }]}>
            <Input placeholder='경기명을 입력해주세요' />
          </Form.Item>,
          span:4,
        },
        {
          key: '2',
          label: <span>HOME<RedDot/></span>,
          children:
          <Form.Item name='homeTeamId' rules={[{ required: true, message:'홈팀 선택 필요' }]}>
            <Select placeholder="홈팀을 선택해주세요" allowClear onChange={onTeamSelection}
              showSearch 
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teamNameList?.map((a,i)=>(<Option value={a.id} key={a.id}>{a.name}</Option>))}
            </Select>
          </Form.Item>,
          span:2,
        },
        {
          key: '3',
          label: <span>AWAY<RedDot/></span>,
          children:
          <Form.Item name='awayTeamId' rules={[{ required: true, message:'어웨이팀 선택 필요' }]}>
            <Select placeholder="어웨이팀을 선택해주세요" allowClear onChange={onTeamSelection}
              showSearch 
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teamNameList?.map((a,i)=>(<Option value={a.id} key={a.id}>{a.name}</Option>))}
            </Select>
          </Form.Item>,
          span:2,
        },
        {
          key: '4',
          label: <span>경기 시작 일시<RedDot/></span>,
          children: 
          <Form.Item name='startAt' rules={[{ required: true, message:'시작시간 입력 필요' }]}>
            <DatePicker showTime format='YYYY-MM-DD HH:mm' locale={locale} style={{width:'100%'}}/>
          </Form.Item>,
            span:2,
        },
        {
          key: '5',
          label: 
          <>
            <DescTitle>경기 장소<RedDot/></DescTitle>
            <br/>
            <DescSubTitle>*최대 50자</DescSubTitle>
          </>,
          children: 
          <Form.Item name='location' rules={[{ required: true, message:'경기 장소 입력 필요' }]}>
            <Input placeholder='경기장을 입력해주세요' />
          </Form.Item>,
          span:2,
        },
        {
          key: '6',
          label: <span>경기 종류<RedDot/></span>,
          children: 
          <Form.Item name='matchType' rules={[{ required: true, message:'경기종류 선택 필요' }]}>
            <Select placeholder='경기 종류를 선택해주세요' allowClear >
              <Option value="LEAGUE">리그</Option>
              <Option value="TOURNAMENT">토너먼트</Option>
            </Select>
          </Form.Item>,
          span:2,
        },
        {
          key: '7',
          label:
          <>
            <DescTitle>경기 그룹</DescTitle>
            <br/>
            <DescSubTitle>*최대 30자</DescSubTitle>
          </>,
          children: 
          <Form.Item name='groupName'>
            <Input placeholder='경기 그룹을 입력해주세요' />
          </Form.Item>,
          span:2,
        },
    ];
      
  return (
    <>
    {contextHolder}
    <PageTitle>
        <InvertImg src={images.icon.trophy} alt='' />
        <span onClick={goComp} className='pointer'>대회</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
        <BlueImg src={images.icon.arrow} alt='' />
        <span className='blue'>경기 생성</span>
    </PageTitle>
    <TitleBox>
        <Title>경기 생성</Title>
    </TitleBox>
    <SubTitle>대회 이름</SubTitle>
    <CompTitle>{compName}</CompTitle>
    <Form form={form} onFinish={onSubmit} >
        
        <Descriptions bordered items={items} style={{marginBottom:'16px'}}/>
        <Container>
            <Form.Item>
            <Button type="primary" htmlType="submit">생성하기</Button>
            </Form.Item>
        </Container>
    </Form>
    </>
  )
}

export default MatchCreate

const Container = styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
    button{
        margin-right: 10px;
    }
`; 

const DescTitle = styled.span`
padding-bottom: 15px;
`;

const DescSubTitle = styled.span`
color: #498AFF;
font-size: 9px;
`;
