import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../common/api';


function useGetAddress() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [regionList, setRegionList] = useState();
    const [cityList, setCityList] = useState();

    const token = cookies[ACCESS_TOKEN];
    const headers = {
        Authorization: `Bearer ${token}`,
        'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
    };
    
    const getAddress = async () => {
        try {
            const response = await axios.get(`${baseUrl}/address`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setRegionList(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }; 

    const getCity = async (cd) => {
        try {
            const response = await axios.get(`${baseUrl}/address?cd=${cd}`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setCityList(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        getAddress();
    },[])

    return { regionList, cityList, getAddress, getCity };
}

export default useGetAddress;
