import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import Signin from './pages/Signin';
import Main from './pages/Main';
import Nav from './components/commons/Nav';
import CompList from './pages/Comp/CompList';
import CompCreate from './pages/Comp/CompCreate';
import CompDetail from './pages/Comp/CompDetail';
import MatchCreate from './pages/Match/MatchCreate';
import CompDetailEdit from './pages/Comp/CompDetailEdit';
import TeamList from './pages/Comp/TeamList';
import PlayerList from './pages/Comp/PlayerList';
import MatchDetailEdit from './pages/Match/MatchDetailEdit';
import Record from './pages/Record/Record';
import RecordEdit from './pages/Record/RecordEdit';
import StatMain from './pages/Stat/ StatMain';
import ActionResult from './pages/ActionResult';
import Error from './pages/Error';
import TeamListAdd from './pages/Comp/SuperUser/TeamListAdd';
import TeamListEdit from './pages/Comp/SuperUser/TeamListEdit';
import MatchLineupEdit from './pages/Match/SuperUser/MatchLineupEdit';
 
function App() {
  dayjs.locale('ko'); 

  return (
    <BrowserRouter>
          <Routes>
            <Route path="/signin" element={<Signin />} />
            <Route path="/" exact={true} element={<Nav />}>
              <Route path="/" element={<Main />} />

              <Route path="/comp" element={<CompList />} />
              <Route path="/comp/compCreate" element={<CompCreate />} /> 

              <Route path="/comp/:compId" element={<CompDetail/>} /> 
              <Route path="/comp/:compId/matchCreate" element={<MatchCreate />} /> 

              <Route path="/comp/:compId/compEdit" element={<CompDetailEdit />} />
              <Route path="/comp/:compId/team" element={<TeamList />} /> 
              <Route path="/comp/:compId/team/:teamId/player" element={<PlayerList />} />
              
              <Route path="/comp/:compId/team/add" element={<TeamListAdd />} /> 
              <Route path="/comp/:compId/team/edit" element={<TeamListEdit />} /> 

              <Route path="/comp/:compId/match/:matchId/matchEdit" element={<MatchDetailEdit />} /> 
              <Route path="/comp/:compId/match/:matchId/matchEdit/lineupEdit" element={<MatchLineupEdit />} /> 
              
              <Route path="/comp/:compId/match/:matchId/record" element={<Record />} /> 
              <Route path="/comp/:compId/match/:matchId/recordEdit" element={<RecordEdit />} /> 

              <Route path="/stat" element={<StatMain />} /> 


              <Route path="/actionResult" element={<ActionResult />} />
              
            </Route>
            <Route path="/*" element={<Error />} />
          </Routes>
    </BrowserRouter>
  );
}

export default App;