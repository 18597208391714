import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import images from '../../common/images';
import { Layout, Menu, theme, ConfigProvider } from 'antd';
import { Outlet, Link, useLocation } from 'react-router-dom';
import useSignin from '../../hooks/Signin/useSignin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { ACCESS_TOKEN } from '../../common/api';
import StopWatch from '../Record/StopWatch';
import { useAppContext } from '../../contexts/AppContext';
import Memo from '../Record/Memo';

const Nav = () => {
    const { Sider, Content } = Layout;
    const { token: { colorBgContainer },} = theme.useToken();
    const { logOut, } = useSignin();
    const [currentMenuItem, setCurrentMenuItem] = useState('');
    const location = useLocation();
    const { state, dispatch } = useAppContext();

    const menuItems = [
      {
        key: 'menuItem1',
        icon: <Link to="/"><NavIcon><img src={images.icon.home} alt=''/><div>홈</div></NavIcon></Link>,
      },
      {
        key: 'menuItem2',
        icon: <Link to="/comp"><NavIcon><img src={images.icon.trophy} alt=''/><div>대회</div></NavIcon></Link>,
      },
      {
        key: 'menuItem3',
        icon: <Link to="/stat"><NavIcon><FontAwesomeIcon icon={faChartSimple} /><div>통계</div></NavIcon></Link>,
      }
    ]

    function getCookie(name) {
      const cookieString = document.cookie;
      const cookies = cookieString.split(';');
      for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=').map(item => item.trim());
        if (cookieName === name) {
          return decodeURIComponent(cookieValue);
        }
      }
      return null;
    }

    useEffect(()=>{
      const idValue = getCookie(ACCESS_TOKEN); 
      if(!idValue) { 
        logOut()
      }
    },[location])

    useEffect(() => {
      const path = window.location.pathname;
      const isCompPath = path.includes('comp');
      const isStatPath = path.includes('stat');
      const selectedMenuItem = isCompPath ? 'menuItem2' : isStatPath ? 'menuItem3' : 'menuItem1';

      setCurrentMenuItem(selectedMenuItem);
    }, [location]);

  return (
    <ConfigProvider
        theme={{
            token: {
                colorPrimary:'#498AFF',
                colorError:'#FF8667',
                colorSuccess:'#20D167',
                fontFamily:'NanumSquareNeo'
            },
            components: {
                "Menu": {
                  "subMenuItemBg": "rgb(43, 43, 43)",
                  "itemColor": "rgba(255, 255, 255, 0.88)",
                  "itemHoverColor": "rgba(255, 255, 255, 0.88)",
                  "itemSelectedBg": "rgb(73, 138, 255)",
                  "itemSelectedColor": "rgb(255, 255, 255)",
                  "colorTextDescription": "rgb(255, 255, 255)",
                  "itemActiveBg": "rgb(73, 138, 255)",
                  "itemBg": "rgb(31, 31, 31)",
                  "itemBorderRadius":23,
                  "itemMarginInline":16,
                  "itemMarginBlock":22,
                },
                "Descriptions": {
                  "colorTextSecondary": "#999"
                },
                "Cascader": {
                  "dropdownHeight": 110,
                },
                "Upload": {
                  "colorError": "#C4C4C4"
                },
                "Table": {
                  "rowHoverBg": "rgb(245, 245, 245)"//antd버그인듯 적용 안됨
                }, 
              }
        }}
    >
    <SLayout style={{minHeight:'100vh'}}>
      <Sider trigger={null}  collapsed={true} style={{ background:'#1F1F1F' }} className='leftLayout'>
        <LogoBox>
            <img src={images.logo} alt=''/>
        </LogoBox>
        <Menu
          selectedKeys={currentMenuItem}
          style={{ background:'#1F1F1F' }}
          mode="inline"
          items={menuItems}
        />
        <LogOutButton onClick={logOut}>
          <img src={images.icon.logout} alt=''/>
          <div>로그아웃</div>
        </LogOutButton>
      </Sider> 
      <Layout style={{minWidth: '1300px'}}>
        <Layout style={{ flexDirection: 'row-reverse' }}>
          <Sider 
            trigger={null} 
            collapsible 
            collapsed={state.collapsed} 
            collapsedWidth={0} 
            width={300}
            style={{ background:'#FAFAFA' }}
          >
            <StopWatch/>
            <Memo/>
          </Sider>
          <Content
            style={{
              padding: 36,
              background: colorBgContainer,
            }}
          >
              <Outlet />
          </Content>
        </Layout>
      </Layout>
    </SLayout>
    </ConfigProvider>
  )
}

export default Nav

const SLayout = styled(Layout)`
.leftLayout {
  flex: 0 0 111px !important;
  max-width: 111px !important;
}

.ant-menu-item {
  width: 80px !important;
  height: 80px !important;
  padding-right: 25px !important;
  padding-left: 25px !important;
  padding-top: 14px;
}

//Date Picker에서 글로벌 테마 폰트를 무시해서 추가함
.ant-picker-input input {
  font-family: 'NanumSquareNeo';
}

//antd table row hover color 버그로 추가함
.ant-table-tbody > tr.ant-table-row:hover > td {
    background: rgb(245,245,245) !important;
}
`;

const LogoBox = styled.div`
width: 49px;
height: 51px;
margin: 47px auto;
  img {
    width: 100%;
    height: 100%;
  }
`;

const LogOutButton = styled.button`
font-family: 'NanumSquareNeo';
font-size: 12px;
color: white;
cursor: pointer;
border: none;
background-color: rgba(0,0,0,0);
position: fixed;
margin-left: 27px;
bottom: 1vh;
margin-bottom: 42px;
left: 0;
  img {
    width: 30px;
    margin-bottom: 8px;
  }

  @media (max-height: 700px) {
    position: absolute;
    bottom: 0;
    overflow-y: auto;
  }
`;

const NavIcon = styled.div`
display: flex;
flex-direction: column;
font-size: 12px;
font-family: 'NanumSquareNeo';

div{
    margin-top: 6px;
}
svg {
    width: 30px;
    height: 30px;
}
`;
