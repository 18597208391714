import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const Message = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const messageSuccess = () => {
        messageApi.open({
            type: 'success',
            content: '반영 완료',
            duration:2,
        });
    };

    const messageError = () => {
        messageApi.open({
            type: 'error',
            content: '서버에러! 반영 실패',
            duration:2,
        });
    };

    const messageEmptySelection = () => {
        messageApi.open({
            type: 'warning',
            content: '선택된 항목이 없습니다!',
            duration: 2,
        });
    };

    const messageLoading = () => {
        messageApi.open({
            key:'loading',
            type: 'loading',
            content: '반영중 ...',
            duration: 0,
        });
    };

    const closeMessageLoading = () => {
        messageApi.destroy('loading');
    }


  return { contextHolder, messageSuccess, messageError, messageLoading, closeMessageLoading, messageEmptySelection }
}

export default Message