import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, } from '@fortawesome/free-solid-svg-icons';
import images from '../../common/images';

const RecordScoreAway = ({ form, recordContent, homeStarting, homeSub, awayStarting, awaySub }) => {
    const homeTotal = [...homeStarting, ...homeSub];
    const awayTotal = [...awayStarting, ...awaySub];
    const [awayGoalList, setAwayGoalList] = useState();
    const [awayShootOutGoalList, setAwayShootOutGoalList] = useState();
    const [awaySubList, setAwaySubList] = useState();
    const [awayCardList, setAwayCardList] = useState();
    const sortedAwayGoalList = awayGoalList?.slice().sort((a, b) => a.recordTime - b.recordTime);
    const sortedAwayShootOutGoalList = awayShootOutGoalList?.slice().sort((a, b) => a.recordOrder - b.recordOrder);
    const sortedAwaySubList = awaySubList?.slice().sort((a, b) => a.recordTime - b.recordTime);
    const sortedAwayCardList = awayCardList?.slice().sort((a, b) => a.recordTime - b.recordTime);

    useEffect(()=>{
      if(recordContent) {
        const awayGoals = recordContent.goal?.filter((goal) => !goal.isHomeTeam);
        const awayOwnGoals = recordContent.ownGoal?.filter((goal) => goal.isHomeTeam);
        const awayShootOutGoals = recordContent.penaltyShootout?.filter((goal) => !goal.isHomeTeam);
        const awaySubs = recordContent.substitute?.filter((substitue) => !substitue.isHomeTeam);
        const awayCards = recordContent.penalty?.filter((penalty) => !penalty.isHomeTeam);

        const awayGoalsFilter = awayGoals.map((goal) => {
          const matchingPlayer = awayTotal.find((player) => player.id === goal.memberRecordId);
          const matchingAssistPlayer = awayTotal.find((player) => player.id === goal.assistMemberRecordId);
          const goalInfo = {
            recordTime: goal.recordTime,
            recordExtraTime: goal.recordExtraTime,
            goalBackNo: matchingPlayer?.backNumber,
            goalMember: matchingPlayer?.member.name + (goal.goalType ? ` (${goal.goalType})` : ''),
            assiBackNo: matchingAssistPlayer?.backNumber || '',
            assiMember: matchingAssistPlayer?.member.name || '',
          };
          return goalInfo;
        });

        const awayOwnGoalsFilter = awayOwnGoals.map((goal) => {
          const matchingPlayer = homeTotal.find((player) => player.id === goal.memberRecordId);
          const goalInfo = {
            recordTime: goal.recordTime,
            recordExtraTime: goal.recordExtraTime,
            goalBackNo: matchingPlayer?.backNumber,
            goalMember: matchingPlayer?.member.name + ' (OG)',
            assiBackNo: null,
            assiMember: null,
          };
          return goalInfo;
        });

        const awayShootOutGoalsFilter = awayShootOutGoals?.map((goal) => {
          const matchingPlayer = awayTotal.find((player) => player.id === goal.memberRecordId);
          const goalInfo = {
            recordOrder: goal.recordOrder,
            goalBackNo: matchingPlayer?.backNumber,
            goalMember: matchingPlayer?.member.name,
            isGoal: goal.isGoal,
          };
          return goalInfo;
        });

        const awaySubsFilter = awaySubs.reduce((result, subs, index, array) => {
          if (subs.substituteType === "IN") {
            const matchingPlayerIn = awayTotal.find((player) => player.id === subs.memberRecordId);
            const matchingPlayerOut = array.find(
              (sub) =>
                sub.substituteMemberRecordId ?
                (sub.substituteType === "OUT" &&
                sub.substituteMemberRecordId === subs.memberRecordId)
                : (sub.substituteType === "OUT" &&
                sub.recordTime === subs.recordTime)
            );
        
            if (matchingPlayerIn && matchingPlayerOut) {
              const matchingPlayerOutInfo = awayTotal.find((player) => player.id === matchingPlayerOut.memberRecordId);
              result.push({
                recordTime: subs.recordTime,
                recordExtraTime: subs.recordExtraTime,
                inBackNo: matchingPlayerIn?.backNumber,
                inMember: matchingPlayerIn?.member.name,
                outBackNo: matchingPlayerOutInfo?.backNumber,
                outMember: matchingPlayerOutInfo?.member.name,
                substituteMemberRecordId: matchingPlayerOut.substituteMemberRecordId,
              });
            }
          }
        
          return result;
        }, []);

        const awayCardsFilter = awayCards.map((card) => {
          const matchingPlayer = awayTotal.find((player) => player.id === card.memberRecordId);
          const cardInfo = {
            recordTime: card.recordTime,
            recordExtraTime: card.recordExtraTime,
            cardType: card.cardType,
            cardBackNo: matchingPlayer?.backNumber,
            cardMember: matchingPlayer?.member.name,
          };
          return cardInfo;
        });
        setAwayGoalList([...awayGoalsFilter, ...awayOwnGoalsFilter]);
        setAwayShootOutGoalList(awayShootOutGoalsFilter);
        setAwaySubList(awaySubsFilter);
        setAwayCardList(awayCardsFilter);
        }
      
    },[recordContent])

  return (
    <>

        {/* 교체기록ㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇ */}
        <SText>교체 기록</SText>
        <Container>
          <LabelRow>
            <TimeCol>시간</TimeCol>
            <InfoCol>
              <Info1>IN</Info1>
              <Info2/>
              <Info1>OUT</Info1>
            </InfoCol>
          </LabelRow>
          {sortedAwaySubList?.map((a,i)=>(
            <Row>
              <TimeCol>{a.recordTime}&apos; {a.recordExtraTime ? ` +${a.recordExtraTime}` : null}</TimeCol>
              <InfoCol>
                <Info1>{a.inBackNo}. {a.inMember}</Info1>
                <Info2><img src={images.icon.subsArrow} alt=''/></Info2>
                <Info1>{a.outBackNo}. {a.outMember}</Info1>
              </InfoCol>
            </Row>
          ))}
        </Container>

        {/* 득점기록ㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇ */}
        <SText>득점 기록</SText>
        <Container>
          <LabelRow>
            <TimeCol>시간</TimeCol>
            <InfoCol>
              <Info1>득점</Info1>
              <Info2/>
              <Info1>도움</Info1>
            </InfoCol>
          </LabelRow>
          {sortedAwayGoalList?.map((a,i)=>(
            <Row>
              <TimeCol>{a.recordTime}&apos; {a.recordExtraTime ? ` +${a.recordExtraTime}` : null}</TimeCol>
              <InfoCol>
                <Info1>{a.goalBackNo}. {a.goalMember}</Info1>
                <Info2/>
                <Info1>{a.assiBackNo ? a.assiBackNo+'.' : null} {a.assiMember ? a.assiMember : null}</Info1>
              </InfoCol>
            </Row>
          ))}
        </Container>

        {/* 승부차기기록ㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇ */}
        {awayShootOutGoalList?.length > 0 ? 
        <>
        <SText>승부차기 기록</SText>
        <Container>
          <LabelRow>
            <TimeCol>순서</TimeCol>
            <InfoCol>
              <Info1>선수</Info1>
              <Info2/>
              <Info1>성공 여부</Info1>
            </InfoCol>
          </LabelRow>
          {sortedAwayShootOutGoalList.map((a,i)=>(
            <Row>
              <TimeCol>{a.recordOrder}</TimeCol>
              <InfoCol>
                <Info1>{a.goalBackNo}. {a.goalMember}</Info1>
                <Info2/>
                <Info1>
                  {a.isGoal === true ? 
                  <SSpan><img src={images.icon.o} alt=''/> 성공</SSpan> 
                  : <SSpan><img src={images.icon.x} alt=''/> 실패</SSpan>}
                </Info1>
              </InfoCol>
            </Row>
          ))}
        </Container>
        </>
        : null}

        <SText>경고 기록</SText>
        <Container>
          <LabelRow>
            <TimeCol>시간</TimeCol>
            <InfoCol>
              <Info1>선수</Info1>
              <Info2/>
              <Info1>구분</Info1>
            </InfoCol>
          </LabelRow>
          {sortedAwayCardList?.map((a,i)=>(
            <Row>
              <TimeCol>{a.recordTime}&apos; {a.recordExtraTime ? ` +${a.recordExtraTime}` : null}</TimeCol>
              <InfoCol>
                <Info1>{a.cardBackNo}. {a.cardMember}</Info1>
                <Info2/>
                <Info1>{a.cardType==='YELLOW'? 
                <span><FontAwesomeIcon icon={faSquare} size='lg' style={{ color: "#fadb14" }}/> 경고</span> 
                : <span><FontAwesomeIcon icon={faSquare} size='lg' style={{ color: "#f5222d" }}/> 퇴장</span>}</Info1>
              </InfoCol>
            </Row>
          ))}
        </Container>
    </>
  )
}

export default RecordScoreAway


const SText = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 32px;
border-radius: 6px;
border: 1px solid #498AFF;
font-size: 12px;  
color: #498AFF;  
margin-top: 51px;
`;

const Container = styled.div`
/* display: flex; */
`;

const LabelRow = styled.div`
display: flex;
flex: 1;
color: #C4C4C4;
`;

const Row = styled.div`
display: flex;
flex: 1;
color: #1F1F1F;
`;

const Col = styled.div`
font-size: 10px;
height: 35px;
text-align: center;
line-height: 35px;
border-bottom: 1px solid #EAEAEA;
`;

const TimeCol = styled(Col)`
flex: 1;
`;

const InfoCol = styled(Col)`
flex: 3;
display: flex;
padding-right: 20px;
`;

const Info1 = styled.span`
width: 40px;
flex: 11;
white-space: nowrap;  
overflow: hidden;   
text-overflow: ellipsis;  
`;

const Info2 = styled.span`
flex: 3;
`;

const SSpan = styled.span`
line-height: 17px;
img {
  width: 17px;
  height: 17px;
  vertical-align: middle;
}
`;