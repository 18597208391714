import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Button, Form, Select, InputNumber, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, } from '@fortawesome/free-solid-svg-icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import images from '../../common/images';
import { inputSort, shootOutSort } from '../../common/util';

const RecordScoreEdit = (props) => {
    const { 
      form, recordContent, createRecord, matchId, 
      homeStarting, homeSub, awayStarting, awaySub, 
      setCompareScoreHome,
    } = props;
    const { Option } = Select;
    const [ownGoalSwitch, setOwnGoalSwitch] = useState(false);
    const [shootOutSwitch, setShootOutSwitch] = useState(false);
    const [goalFormState, setGoalFormState] = useState(false);
    const homeTotal = [...homeStarting, ...homeSub].filter((a)=>(a.isParticipated))
    const awayTotal = [...awayStarting, ...awaySub].filter((a)=>(a.isParticipated))
    const qrHomeSub = homeSub?.filter((a)=>(a.isParticipated))
    const [updatedSubList, setUpdatedSubList] = useState();

    const saveArrayToSessionStorage = (matchId, name, value) => {
      if(!recordContent) {
        const updatedValues = [...value];
        sessionStorage.setItem(matchId + name, JSON.stringify(updatedValues));
      }
    }

    const onFieldChange = (index, listName, key, value) => {
      if(!recordContent) {
        const fieldArray = form.getFieldValue(listName);
        fieldArray[index][key] = value;
        saveArrayToSessionStorage(matchId, listName, fieldArray);
      }
    };

    const onFieldRemove = (index, listName) => {
      const fieldArray = form.getFieldValue(listName);
      const updatedFieldArray = [...fieldArray];
      updatedFieldArray.splice(index, 1);
      saveArrayToSessionStorage(matchId, listName, updatedFieldArray);
    }

    //중복선택 방지
    const onAssistSelect = (index) => {
      const goalArray = form.getFieldValue('goal_home');
      if (goalArray[index].memberRecordId === goalArray[index].assistMemberRecordId) {
        const updatedGoalArray = [...goalArray];
        updatedGoalArray[index] = {
          ...updatedGoalArray[index],
          assistMemberRecordId: null,
        };
        form.setFieldsValue({
          'goal_home': updatedGoalArray,
        });
      }
    }

    //중복선택 방지
    const onSubSelect = (index) => {
      const subArray = form.getFieldValue('substitute_home');
      if (subArray[index].memberRecordId_IN === subArray[index].memberRecordId_OUT) {
        const updatedSubArray = [...subArray];
        updatedSubArray[index] = {
          ...updatedSubArray[index],
          memberRecordId_OUT: null,
        };
        setUpdatedSubList(updatedSubArray);
        form.setFieldsValue({
          'substitute_home': updatedSubArray,
        });
      }
    }

    useEffect(()=>{
      form.setFieldsValue({
        'substitute_home': updatedSubList,
      });
    },[updatedSubList])

    useEffect(()=>{
      if (recordContent) {
        if (recordContent.ownGoal?.filter((goal) => !goal.isHomeTeam).length > 0) { 
          setOwnGoalSwitch(true) 
        }
        if (recordContent.penaltyShootout?.filter((goal) => goal.isHomeTeam).length > 0) { 
          setShootOutSwitch(true)
        }
        
        const homeGoals = recordContent.goal?.filter((goal) => goal.isHomeTeam);
        const homeOwnGoals = recordContent.ownGoal?.filter((goal) => !goal.isHomeTeam);
        const homeShootOutGoals = recordContent.penaltyShootout?.filter((goal) => goal.isHomeTeam);

        const homeSubsIn = recordContent.substitute?.filter((substitue) => substitue.isHomeTeam && substitue.substituteType === 'IN');
        const homeSubsOut = recordContent.substitute?.filter((substitue) => substitue.isHomeTeam && substitue.substituteType === 'OUT');
        const homeCards = recordContent.penalty?.filter((penalty) => penalty.isHomeTeam);

        const homeOutFromIn = homeSubsOut.map((sub) => ({
          ...sub,
          memberRecordId_OUT: 
          sub.substituteMemberRecordId !== null ?
          homeSubsOut.find((inSub) => inSub.substituteMemberRecordId === sub.substituteMemberRecordId)?.memberRecordId 
          : homeSubsOut.find((inSub) => inSub.recordTime === sub.recordTime)?.memberRecordId,
        }));

        homeSubsIn.sort(inputSort);
        homeGoals.sort(inputSort);
        homeOwnGoals.sort(inputSort);
        homeCards.sort(inputSort);
        homeShootOutGoals?.sort(shootOutSort);

        form.setFieldsValue({
          'goal_home': homeGoals?.map((a) => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime,
            memberRecordId: a.memberRecordId,
            assistMemberRecordId: a.assistMemberRecordId,
            goalType: a.goalType
          })),

          'ownGoal_home': homeOwnGoals?.map((a) => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime,
            memberRecordId: a.memberRecordId,
          })),

          'shootOut_home': homeShootOutGoals?.map((a) => ({
            recordOrder: a.recordOrder,
            memberRecordId: a.memberRecordId,
            isGoal: a.isGoal,
          })),

          'substitute_home': homeSubsIn?.map((a) => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime,
            memberRecordId_IN: a.memberRecordId,
            memberRecordId_OUT: homeOutFromIn.find((b) => b.substituteMemberRecordId === a.memberRecordId)?.memberRecordId_OUT,
          })),

          'penalty_home': homeCards?.map((a) => ({
            recordTime: a.recordTime,
            recordExtraTime: a.recordExtraTime,
            memberRecordId: a.memberRecordId,
            cardType: a.cardType,
          })),
        });

        setCompareScoreHome(recordContent.goal?.filter((goal) => goal.isHomeTeam).length +
        recordContent.ownGoal?.filter((goal) => !goal.isHomeTeam).length);
      }
    },[recordContent])

    const handleOwnGoalSwitch = () => {
      setOwnGoalSwitch(!ownGoalSwitch);
      sessionStorage.setItem(matchId + 'ownGoalSwitch_home', !ownGoalSwitch);
      if (ownGoalSwitch===false) {
        form.setFieldsValue({
          'ownGoal_home': [],
        });
      }
    }

    const handleShootOutSwitch = () => {
      setShootOutSwitch(!shootOutSwitch);
      sessionStorage.setItem(matchId + 'shootOutSwitch_home', !shootOutSwitch);
      if (shootOutSwitch===false) {
        form.setFieldsValue({
          'shootOut_home': [],
        });
      }
    }

    const handleGoalForm = () => {
      setGoalFormState(!goalFormState)
    }

    const onFinish = (values) => {
      if (matchId) {
        //수정
        createRecord(values, matchId)
      } else {
        //입력
        createRecord(values)
      }
    };

    useEffect(() => {
      const goalField = form.getFieldValue('goal_home');
      const ownGoalField = form.getFieldValue('ownGoal_home');
      const count = goalField || ownGoalField ? goalField?.length + ownGoalField?.length : 0;
      setCompareScoreHome(count);
      
    }, [goalFormState]); 

    useEffect(()=>{ 
      //자동저장 불러오기
      if(!recordContent) {
        setOwnGoalSwitch(JSON.parse(sessionStorage.getItem(matchId + 'ownGoalSwitch_home')));
        setShootOutSwitch(JSON.parse(sessionStorage.getItem(matchId + 'shootOutSwitch_home')));
      }
    },[matchId])


    const PlayerSelect = (placeholder, target, onChange) => {
      const sortedTarget = target.sort((a, b) => a.backNumber - b.backNumber);
      return (
        <Select allowClear placeholder={placeholder} onChange={onChange}>
          {sortedTarget.map((a, i)=>(<Option value={a.id}>{a.backNumber+' '+a.member.name}</Option>))}
        </Select>
      )
    };

  return (
    <>
      <Form form={form} name='recordScoreForm' autoComplete="off" className='record' onFinish={onFinish}> 

        {/* 교체기록ㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇ */}
        <SText>교체 기록</SText>
        <Container>
          <Box>
            <Form.List name={'substitute_home'} >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <FormRow key={field.key}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'recordTime']}
                        rules={[{ required: true, message: '시간 필요' }]}
                        style={{ flex: 2 }}
                      >
                        <SInputNumber 
                          style={{width:'100%', minWidth:'100px'}}
                          placeholder="시간" 
                          controls={false} 
                          suffix="분"
                          onChange={(value) => {onFieldChange(index, 'substitute_home', 'recordTime', value)}}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'recordExtraTime']}
                        style={{ flex: 1 }}
                      >
                        <SInputNumber 
                          style={{width:'100%', minWidth:'67px'}}
                          placeholder="추가" 
                          controls={false} 
                          suffix="분"
                          onChange={(value) => {onFieldChange(index, 'substitute_home', 'recordExtraTime', value)}}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'memberRecordId_IN']}
                        rules={[{ required: true, message: 'IN 필요' }]}
                        style={{ flex: 2 }}
                      >
                        {PlayerSelect(
                          'IN',
                          qrHomeSub, 
                          (value) => {
                            onSubSelect(index); 
                            onFieldChange(index, 'substitute_home', 'memberRecordId_IN', value);
                          }
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'memberRecordId_OUT']}
                        rules={[{ required: true, message: 'OUT 필요' }]}
                        style={{ flex: 2 }}
                      >
                        {PlayerSelect(
                          'OUT', 
                          homeTotal, 
                          (value) => {
                            onSubSelect(index); 
                            onFieldChange(index, 'substitute_home', 'memberRecordId_OUT', value);
                          }
                        )}
                      </Form.Item>
                      <MinusCircleOutlined onClick={
                        () => {
                          remove(field.name);
                          onFieldRemove(index, 'substitute_home')
                        }} 
                        style={{color:'#989898'}}
                      />
                    </FormRow>
                  ))}
                  <Form.Item>
                    <SButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      교체 기록 추가
                    </SButton>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Box>
          
        </Container>

        {/* 득점기록ㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇㅇ */}
        <SwitchBox className='notPrint'>
          <SwitchText isactive={ownGoalSwitch} >자책골</SwitchText>
          <Switch checked={ownGoalSwitch} onChange={handleOwnGoalSwitch} style={{marginRight:'16px'}}/>
          <SwitchText isactive={shootOutSwitch} >승부차기</SwitchText>
          <Switch checked={shootOutSwitch} onChange={handleShootOutSwitch}/>
        </SwitchBox>
        <STextScore>득점 기록</STextScore>
        
        <Container>
          <Box>
            <Form.List name={'goal_home'} >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                      <FormRow key={field.key}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'recordTime']}
                          rules={[{ required: true, message: '시간 필요' }]}
                          style={{ flex: 20 }}
                        >
                          <SInputNumber 
                            style={{ width: '100%', minWidth:'100px' }}
                            placeholder="시간" 
                            controls={false} 
                            suffix="분" 
                            onChange={(value) => onFieldChange(index, 'goal_home', 'recordTime', value)}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'recordExtraTime']}
                          style={{ flex: 10 }}
                        >
                          <SInputNumber 
                            style={{width:'100%', minWidth:'67px'}}
                            placeholder="추가" 
                            controls={false} 
                            suffix="분"
                            onChange={(value) => {onFieldChange(index, 'goal_home', 'recordExtraTime', value)}}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'memberRecordId']}
                          rules={[{ required: true, message: '득점 선수 필요' }]}
                          style={{ flex: 16 }}
                        >
                          {PlayerSelect(
                            '득점', 
                            homeTotal, 
                            (value) => {
                              onAssistSelect(index); 
                              onFieldChange(index, 'goal_home', 'memberRecordId', value);
                            }
                          )}
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'assistMemberRecordId']}
                          onChange={(value) => {
                            onFieldChange(index, 'goal_home', 'assistMemberRecordId', value);
                          }}
                          style={{ flex: 16 }}
                        >
                          {PlayerSelect('도움', homeTotal, () => onAssistSelect(index))}
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, 'goalType']}
                          onChange={(value) => {
                            onFieldChange(index, 'goal_home', 'goalType', value);
                          }}
                          style={{ flex: 8 }}
                        >
                          <Select 
                            allowClear 
                            style={{ width: '100%' }} 
                            placeholder='필드 골'
                            onChange={(value) => onFieldChange(index, 'goal_home', 'goalType', value)}
                          >
                            <Option value="FK">프리킥 골</Option>
                            <Option value="PK">페널티킥 골</Option>
                          </Select>
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => {
                          remove(field.name); 
                          handleGoalForm(); 
                          onFieldRemove(index, 'goal_home')}} 
                          style={{color:'#989898'}}
                        />
                      </FormRow>
                  ))}
                  <Form.Item>
                    <SButton type="dashed" onClick={() => {add(); handleGoalForm();}} block icon={<PlusOutlined />}>
                      득점 기록 추가
                    </SButton>
                  </Form.Item>
                </>
              )}
            </Form.List> 
            {ownGoalSwitch?
            <OtherGoal>
            <Form.List name={'ownGoal_home'}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                      <FormRow key={field.key}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'recordTime']}
                          rules={[{ required: true, message: '시간 필요' }]}
                          style={{ flex:2 }}
                        >
                          <SInputNumber 
                            style={{width:'100%', minWidth:'100px'}}
                            placeholder="시간" 
                            controls={false} 
                            suffix="분"
                            onChange={(value) => onFieldChange(index, 'ownGoal_home', 'recordTime', value)}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'recordExtraTime']}
                          style={{ flex: 1 }}
                        >
                          <SInputNumber 
                            style={{width:'100%', minWidth:'67px'}}
                            placeholder="추가" 
                            controls={false} 
                            suffix="분"
                            onChange={(value) => {onFieldChange(index, 'ownGoal_home', 'recordExtraTime', value)}}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'memberRecordId']}
                          rules={[{ required: true, message: '득점 선수 필요' }]}
                          style={{ flex:4 }}
                        >
                          {PlayerSelect(
                            '득점', 
                            awayTotal, 
                            (value) => {onFieldChange(index, 'ownGoal_home', 'memberRecordId', value);}
                          )}
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => {
                            remove(field.name); 
                            handleGoalForm();
                            onFieldRemove(index, 'ownGoal_home')
                          }} 
                          style={{color:'#989898'}}
                        />
                      </FormRow>
                  ))}
                  <Form.Item>
                    <SButton type="dashed" onClick={() => {add(); handleGoalForm();}} block icon={<PlusOutlined />}>
                      자책골 기록 추가
                    </SButton>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </OtherGoal> : null} 

          {shootOutSwitch?
            <OtherGoal>
              <Form.List name={'shootOut_home'}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                        <FormRow key={field.key}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'recordOrder']}
                            rules={[{ required: true, message: '순서 필요' }]}
                            style={{flex:3}}
                            initialValue={index+1}
                          >
                            <SInputNumber
                              style={{width:'100%'}}
                              placeholder="순서" 
                              controls={false} 
                              onChange={(value) => onFieldChange(index, 'shootOut_home', 'recordOrder', value)}
                              suffix='번 키커'
                            />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'memberRecordId']}
                            rules={[{ required: true, message: '선수 선택 필요' }]}
                            style={{flex:2}}
                          >
                            {PlayerSelect(
                              '선수', 
                              homeTotal, 
                              (value) => {onFieldChange(index, 'shootOut_home', 'memberRecordId', value);}
                            )}
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'isGoal']}
                            rules={[{ required: true, message: '성공 여부 선택 필요' }]}
                            style={{ flex: 2 }}
                          >
                            <Select 
                              allowClear 
                              style={{ width: '100%' }} 
                              placeholder='성공 여부'
                              onChange={(value) => onFieldChange(index, 'shootOut_home', 'isGoal', value)}
                            >
                              <Option value={true}>
                                <SSpan><img src={images.icon.o} alt=''/> 성공</SSpan> 
                              </Option>
                              <Option value={false}>
                                <SSpan><img src={images.icon.x} alt=''/> 실패</SSpan>
                              </Option>
                            </Select>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => {
                              remove(field.name);
                              onFieldRemove(index, 'shootOut_home')
                            }} 
                            style={{color:'#989898'}}
                          />
                        </FormRow>
                    ))}
                    <Form.Item>
                      <SButton type="dashed" onClick={() => {add();}} block icon={<PlusOutlined />}>
                        승부차기 기록 추가
                      </SButton>                                          
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </OtherGoal> : null} 

          </Box>
        </Container>

        <SText>경고 기록</SText>
        <Container>
          <Box>
            <Form.List name={'penalty_home'}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <FormRow key={field.key}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'recordTime']}
                        rules={[{ required: true, message: '시간 필요' }]}
                        style={{ flex:2 }}
                      >
                        <SInputNumber 
                          style={{width:'100%', minWidth:'100px'}}
                          placeholder="시간" 
                          controls={false} 
                          suffix="분"
                          onChange={(value) => onFieldChange(index, 'penalty_home', 'recordTime', value)}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'recordExtraTime']}
                        style={{ flex: 1 }}
                      >
                        <SInputNumber 
                          style={{width:'100%', minWidth:'67px'}}
                          placeholder="추가" 
                          controls={false} 
                          suffix="분"
                          onChange={(value) => {onFieldChange(index, 'penalty_home', 'recordExtraTime', value)}}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'memberRecordId']}
                        rules={[{ required: true, message: '선수 선택 필요' }]}
                        style={{ flex:2 }}
                      >
                        {PlayerSelect(
                          '선수', 
                          homeTotal, 
                          (value) => onFieldChange(index, 'penalty_home', 'memberRecordId', value)
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'cardType']}
                        rules={[{ required: true, message: '구분 필요' }]}
                        style={{ flex:2 }}
                      >
                        <Select 
                          allowClear 
                          style={{ width: '100%' }} 
                          placeholder='구분'
                          onChange={(value) => onFieldChange(index, 'penalty_home', 'cardType', value)}
                        >
                          <Option value="YELLOW"><FontAwesomeIcon icon={faSquare} style={{ color: "#fadb14" }}/> 경고</Option>
                          <Option value="RED"><FontAwesomeIcon icon={faSquare} style={{ color: "#f5222d" }}/> 퇴장</Option>
                        </Select>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => {
                          remove(field.name);
                          onFieldRemove(index, 'penalty_home')
                        }} 
                        style={{color:'#989898'}}
                      />
                    </FormRow>
                  ))}
                  <Form.Item>
                    <SButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      경고 기록 추가
                    </SButton>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Box>
        </Container>
      </Form>
    </>
  )
}

export default RecordScoreEdit

const SInputNumber = styled(InputNumber)`
height: 32px;
`;

const SText = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 32px;
border-radius: 6px;
border: 1px solid #FF8667;
font-size: 14px;  
color: #FF8667;  
margin-top: 49px;
margin-bottom: 12px;
`;

const STextScore = styled(SText)`
margin-top: 10px;
`;

const Container = styled.div`
display: flex;
gap: 10px;
`;

const Box = styled.div`
flex: 1;
`;

const OtherGoal = styled.div`
margin-top: 16px;
`;

const SButton = styled(Button)`
font-size: 12px;
color: #A6A6A6;
`;

const SwitchBox = styled.div`
margin-top: 49px;
font-size: 12px;
display: flex;
justify-content: flex-end; 
`;

const SwitchText = styled.span`
color: ${props => props.isactive ? '#498AFF' : '#BFBFBF'};
margin-right: 5px;
`;

const FormRow = styled.div`
display: flex;
gap: 10px;
margin-bottom: 16px;
`;

const SSpan = styled.span`
line-height: 18px;
img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
`;