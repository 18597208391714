import React from 'react'
import { Button, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const UpdateModal = ({form, scoreAlertNoti, inactive, onCreateSubmit}) => {
    const { confirm } = Modal;
    const showConfirm = () => {
      confirm({
        title: '수정하시겠습니까?',
        icon: <ExclamationCircleFilled />, 
        okButtonProps: {key: 'submit', htmlType: 'submit'},
        okText: '확인',
        cancelText: '취소',
        onOk(){
          window.scrollTo(0, 0);
          if(onCreateSubmit) {
            onCreateSubmit();
          } else {
            form.submit()
          }
        },
        onCancel(){},
      });
    };

  return (
    <Button 
      type='primary' 
      danger
      disabled={inactive} 
      onClick={()=>{
        showConfirm(); 
        if(scoreAlertNoti) {
          scoreAlertNoti();
        }
      }}
    >
      수정하기
    </Button>
  );
};

export default UpdateModal
