import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Space, Input, Button, Form, Descriptions, DatePicker, Select, Tooltip, Upload, Checkbox, InputNumber } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { 기본배너이미지, 배너이미지안내문, 상세이미지안내문 } from '../../common/Data'
import useGetAddress from '../../hooks/useGetAddress';
import useGetFirm from '../../hooks/useGetFirm';
import useUploadImg from '../../hooks/useUploadImg';
import useUploadDetailImg from '../../hooks/useUploadDetailImg';
import useCreateComp from '../../hooks/Comp/useCreateComp';
import useCreateCompFD from '../../hooks/Comp/useCreateCompFD';
import Notification from '../../components/commons/Notification';
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../common/Navigation';

const CompCreate = () => {
  const navigate = useNavigate(); 
  const {PageTitle, SearchBox, TitleBox, Title,  InvertImg, BlueImg, RedDot } = StyleAtoms();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { regionList, cityList, getAddress, getCity } = useGetAddress();
  const { firmList } = useGetFirm();
  // const { compResult, createComp, setCompResult } = useCreateComp();
  const { compResult, createComp, setCompResult } = useCreateCompFD();
  const [bannerFileList, setBannerFileList] = useState();
  const [detailFileList, setDetailFileList] = useState();
  const [bracketFileList, setBracketFileList] = useState();
  const { contextHolder, loadingNoti, successNoti, errorNoti, closeLoadingNoti } = Notification();
  const [constraintValuePlaceholder, setConstraintValuePlaceholder] = useState('');
  const { goComp } = Navigation();

  const handleRegionChange = (value) => {
    if(regionList) {
      const selectedRegion = regionList.find((region) => region.addr_name === value);
      getCity(selectedRegion.cd);
    }
  };

  const handleBannerImgChange = (imgFile) => {
    setBannerFileList(imgFile.file.originFileObj)
  };

  const handleDetailImgChange = (imgFile) => {
    setDetailFileList(imgFile.fileList)
  };

  const handleBracketImgChange = (imgFile) => {
    setBracketFileList(imgFile.fileList)
  };

  const onSubmit = async (values) => {
    window.scrollTo(0, 0)
    loadingNoti()

    try {
      await createComp(values, bannerFileList, detailFileList, bracketFileList);
    } catch (error) {
      console.error('오류 발생:', error);
    }
  };

  const selectPriceType = (
    <Form.Item name='priceType' initialValue='팀당' >
      <Select style={{ width: 85 }}>
        <Option value="팀당">팀당</Option>
        <Option value="개인당">개인당</Option>
      </Select>
    </Form.Item>
  );

  useEffect(()=>{
    if (compResult === false) {
      closeLoadingNoti();
      errorNoti();
    } else if (compResult === true) {
      closeLoadingNoti();
      successNoti('생성'); 
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
    setCompResult(null);
  }, [compResult])

  useEffect(()=>{
    form.setFieldsValue({
      'competitionConstraint': [{
        title: 'STARTING_LINEUP_VALID_MEMBER_COUNT',
        value: 11,
      }],
      'competitionFirm': [
        {
          firmType: 'HOST',
          firmId: null,
        },
        {
          firmType: 'ORGANIZER',
          firmId: null,
        },
        {
          firmType: 'SPONSOR',
          firmId: null,
        }
      ],
    });
  },[])

  const items = [
      {
        key: '1',
        label: <DescTitle>대회 이름<RedDot/></DescTitle>,
        children: 
        <Form.Item name='name' rules={[{ required: true, message:'대회 이름 입력 필요' }]}>
          <Input placeholder='대회 이름을 입력해 주세요' />
        </Form.Item>,
        span:2,
      },
      {
        key: '2',
        label: <DescTitle>대회 종류<RedDot/></DescTitle>,
        children: 
        <Form.Item name='competitionType' rules={[{ required: true, message:'대회 종류 선택 필요' }]}>
          <Select placeholder='대회 종류를 선택해 주세요'>
            <Select.Option value="LEAGUE">리그</Select.Option>
            <Select.Option value="TOURNAMENT">토너먼트</Select.Option>
            <Select.Option value="COMPLEX">복합</Select.Option>
          </Select>
        </Form.Item>,
        span:1,
      },
      {
        key: '3',
        label: <DescTitle>대회 시작일 - 종료일<RedDot/></DescTitle>,
        children: 
        <InputContainer>
          <SFormItem name='startAt' rules={[{ required: true, message:'시작일 입력 필요' }]}>
            <DatePicker locale={locale} style={{width:'100%'}} placeholder='시작일' />
          </SFormItem>
          <InputText className='first'>부터</InputText>
          <SFormItem name='endAt' rules={[{ required: true, message:'종료일 입력 필요' }]}>
            <DatePicker locale={locale} style={{width:'100%'}} placeholder='종료일'/>
          </SFormItem>
          <InputText>까지</InputText>
        </InputContainer>,
        span:2,
      },
      {
        key: '4',
        label: <DescTitle>대회 참가 비용<RedDot/></DescTitle>,
        children: 
        <InputContainer>
          <SFormItem name='price' rules={[{ required: true, message:'금액 입력 필요' }]}>
            <SInputNumberPrice
              addonBefore={selectPriceType}
              suffix='원'
              controls={false}
              formatter={(value) => {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }}
              parser={(value) => {
                return value.replace(/(,*)/g, '');
              }}
              placeholder="금액을 입력해 주세요"
            />
          </SFormItem>
        </InputContainer>,
        span:1,
      },
      {
        key: '5',
        label: <DescTitle>접수 시작일 - 종료일<RedDot/></DescTitle>,
        children: 
        <InputContainer>
          <SFormItem name='applyStartAt' rules={[{ required: true, message:'시작일 입력 필요' }]}>
            <DatePicker locale={locale} style={{width:'100%'}} placeholder='접수 시작일'/>
          </SFormItem>
          <InputText className='first'>부터</InputText>
          <SFormItem name='applyEndAt' rules={[{ required: true, message:'종료일 입력 필요' }]}>
            <DatePicker locale={locale} style={{width:'100%'}} placeholder='접수 종료일'/>
          </SFormItem>
          <InputText>까지</InputText>
        </InputContainer>,
        span:2,
      },
      {
        key: '6',
        label: '참가 대상 범위',
        children: 
        <Form.Item name='target'>
          <Input placeholder='나이, 학년 등' />
        </Form.Item>,
        span:1,
      },
      {
        key: '7',
        label: <DescTitle>포스팅 시작일 - 종료일<RedDot/></DescTitle>,
        children: 
        <InputContainer>
          <SFormItem name='openAt' rules={[{ required: true, message:'시작일 입력 필요' }]}>
            <DatePicker locale={locale} style={{width:'100%'}} placeholder='포스팅 시작일'/>
          </SFormItem>
          <InputText className='first'>부터</InputText>
          <SFormItem name='closeAt' rules={[{ required: true, message:'종료일 입력 필요' }]}>
            <DatePicker locale={locale} style={{width:'100%'}} placeholder='포스팅 종료일'/>
          </SFormItem>
          <InputText>까지</InputText>
        </InputContainer>,
        span:2,
      },
      {
        key: '8',
        label: '참고 링크',
        children: 
        <Form.Item name='link'>
          <Input  placeholder='www.projectwith.io' />
        </Form.Item>,
        span:1,
      },
      {
        key: '9',
        label: <DescTitle>지역<RedDot/></DescTitle>,
        children: 
        <InputContainer>
          <InputText className='second'>시/도</InputText>
          <SFormItem name='locationCity1' rules={[{ required: true, message:'지역 선택 필요' }]}>
            <Select onChange={handleRegionChange} style={{width:'100%'}} placeholder='지역을 선택해 주세요'>
              {regionList?.map((a,i)=>{return <Option key={a.cd} value={a.addr_name}>{a.addr_name}</Option> })}
            </Select>
          </SFormItem>
          <InputText className='second'>시군구</InputText>
          <SFormItem name='locationCity2' rules={[{ required: true, message:'지역 선택 필요' }]}>
            <Select placeholder='지역을 선택해 주세요'>
              {cityList?.map((a,i)=>{return <Option value={a.addr_name}>{a.addr_name}</Option> })}
            </Select>
          </SFormItem>
          <InputText className='second'>상세주소</InputText>
          <SFormItem name='locationDetail' rules={[{ required: true, message:'장소 입력 필요' }]}>
            <Input placeholder='대회 장소를 입력해 주세요' />
          </SFormItem>
        </InputContainer>,
        span:3,
      },
  ];

  const items2 = [
    {
      key: '13',
      label: 
      <>
      <DescTitle>대회 상세 정보<RedDot/></DescTitle>
      <br/>
      <DescSubTitle>* 최대 2000자</DescSubTitle>
      </>,
      children: 
      <SFormItemLong name='detail' rules={[{ required: true, message:'상세 정보 입력 필요' }]} style={{minWidth:'491px'}}>
        <Input.TextArea rows={5} maxLength={2000} placeholder='상세 정보를 입력해 주세요' showCount={true} />
      </SFormItemLong>,
      span:3,
    },
  ]
  const items3 = [
    {
      key: '14',
      label:
      <>
      <DescTitle>
        대회 정책
        <RedDot/>
      </DescTitle><br/>
      <DescSubTitle>* 최대 1000자</DescSubTitle>
      </>,
      children: 
      <SFormItemLong name='policy' rules={[{ required: true, message:'정책 입력 필요' }]} style={{minWidth:'380px'}}>
        <Input.TextArea rows={4} maxLength={1000} placeholder='대회 정책을 입력해 주세요' showCount={true} />
      </SFormItemLong>,
      span:3,
    },
  ];
      
  return (
    <>
    {contextHolder}
        <PageTitle>
          <InvertImg src={images.icon.trophy} alt='' />
          <span onClick={goComp} className='pointer'>대회</span>
          <BlueImg src={images.icon.arrow} alt='' />
          <span className='blue'>대회 생성</span>
        </PageTitle>
        <Container>
          <Form form={form} onFinish={onSubmit}>
              <TitleBox>
                <Title>대회 생성</Title>
              </TitleBox>
              <Descriptions bordered items={items} className='botGap'/>
              <Descriptions bordered items={items2} className='botGap'/>
              <Descriptions bordered items={items3} className='botGap'/>

              <Descriptions bordered className='botGap'>
                <Descriptions.Item label='대회 제약 조건'>
                  <Form.List name="competitionConstraint" >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} style={{ display:'flex', marginBottom: 16 }} align="baseline">
                            <SFormItemLong
                              {...restField}
                              name={[name, 'title']}
                              rules={[{ required: true, message: '조건 선택 필요' }]}
                            >
                              <Select 
                                placeholder='제약 조건 구분' style={{width:'100%', minWidth:'350px'}}
                                onChange={(value) => {
                                  if (value === 'DOCUMENT_EXPIRE') {
                                    setConstraintValuePlaceholder((prevState) => ({
                                      ...prevState,
                                      [key]: '날짜 입력 ex)20230101',
                                    }));
                                  } else {
                                    setConstraintValuePlaceholder((prevState) => ({
                                      ...prevState,
                                      [key]: '조건 값을 숫자로 입력해주세요',
                                    }));
                                  }
                                }}>
                              {/* <Select placeholder='제약 조건 구분' style={{width:'100%', minWidth:'350px'}}> */}
                                <Select.Option value="MIN_AGE">최저연령</Select.Option>
                                <Select.Option value="MAX_AGE">최고연령</Select.Option>
                                <Select.Option value="DOCUMENT_EXPIRE">문서발급일</Select.Option>
                                <Select.Option value="MIN_COMPETITION_APPLY_MEMBER_COUNT">최소 대회참가 인원</Select.Option>
                                <Select.Option value="MAX_COMPETITION_APPLY_MEMBER_COUNT">최대 대회참가 인원</Select.Option>
                                <Select.Option value="MIN_STARTING_LINEUP_MEMBER_COUNT">최소 출전선수 명단 인원</Select.Option>
                                <Select.Option value="MAX_STARTING_LINEUP_MEMBER_COUNT">최대 출전선수 명단 인원</Select.Option>
                                <Select.Option value="STARTING_LINEUP_VALID_MEMBER_COUNT">선발선수 인원</Select.Option>
                              </Select>
                            </SFormItemLong>
                            <SFormItemLong
                              {...restField}
                              name={[name, 'value']}
                              rules={[{ required: true, message: '조건 값 입력 필요' }]}
                              style={{width:'100%', minWidth:'350px'}}
                            >
                              <SInputNumber placeholder={constraintValuePlaceholder[key]} controls={false} style={{width:'100%', minWidth:'320px'}}/>
                            </SFormItemLong>
                            <MinusCircleOutlined onClick={() => remove(name)} style={{color:'#999'}} />
                          </Space>
                        ))}
                        <Form.Item>
                          <SButton type="dashed" style={{width:'50%', minWidth:'700px'}}
                          onClick={() => add()} block icon={<PlusOutlined />}>조건 추가</SButton>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Descriptions.Item>
              </Descriptions>

              <Descriptions bordered className='botGap'>
                <Descriptions.Item label={
                  <>
                  <DescTitle>
                    기관
                  </DescTitle><br/>
                  <DescSubTitle>*선택하고자 하는 기관이 없다면 문의해주세요<br/>*문의주소 : contact@projectwith.io</DescSubTitle>
                  </>}
                >
                  <Form.List name="competitionFirm" >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} style={{ display:'flex', marginBottom: 16 }} align="baseline">
                            <SFormItemLong
                              {...restField}
                              name={[name, 'firmType']}
                              rules={[{ required: true, message: '기관 구분 선택 필요' }]}
                            >
                              <Select placeholder='기관 구분' style={{width:'100%', minWidth:'350px'}}>
                                <Select.Option value="HOST">주최</Select.Option>
                                <Select.Option value="ORGANIZER">주관</Select.Option>
                                <Select.Option value="SPONSOR">후원</Select.Option>
                              </Select>
                            </SFormItemLong>
                            <SFormItemLong
                              {...restField}
                              name={[name, 'firmId']}
                              rules={[{ required: true, message: '기관 선택 필요' }]}
                            >
                              <Select placeholder='기관 선택' style={{width:'100%', minWidth:'350px'}}>
                                {firmList?.map((a,i)=>{return <Option value={a.id} key={a.id}>{a.name}</Option> })}
                              </Select>
                            </SFormItemLong>
                            <MinusCircleOutlined onClick={() => remove(name)} style={{color:'#999'}} />
                          </Space>
                        ))}
                        <Form.Item>
                          <SButton type="dashed" style={{width:'50%', minWidth:'700px'}}
                          onClick={() => add()} block icon={<PlusOutlined />}>기관 추가</SButton>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Descriptions.Item>
              </Descriptions>

              <Descriptions bordered className='botGap'>
                <Descriptions.Item label={
                  <>
                  <DescTitle>
                    배너 이미지
                  </DescTitle><br/>
                  <DescSubTitle>*1장만 업로드 가능합니다</DescSubTitle><br/>
                  <DescSubTitle>*업로드 하지 않을 시 기본이미지가 적용됩니다</DescSubTitle><br/>
                  <DescSubTitle>{배너이미지안내문}</DescSubTitle>
                  </>} span={3}
                >
                  <Upload listType="picture-card" maxCount={1} onChange={handleBannerImgChange} onRemove={handleBannerImgChange}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  </Upload>
                </Descriptions.Item>
              </Descriptions>

              <Descriptions bordered className='botGap'>
                <Descriptions.Item label={
                  <>
                  <DescTitle>
                    상세 이미지
                  </DescTitle><br/>
                  <DescSubTitle>{상세이미지안내문}</DescSubTitle>
                  </>} span={3}
                >
                  <Upload listType="picture-card" onChange={handleDetailImgChange} onRemove={handleDetailImgChange}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  </Upload>
                </Descriptions.Item>
              </Descriptions>

              <Descriptions bordered>
                <Descriptions.Item label={
                  <>
                  <DescTitle>
                    대회 대진표
                  </DescTitle><br/>
                  <DescSubTitle>{상세이미지안내문}</DescSubTitle>
                  </>} span={3}
                >
                  <Upload listType="picture-card" onChange={handleBracketImgChange} onRemove={handleBracketImgChange}>
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  </Upload>
                </Descriptions.Item>
              </Descriptions>
              
              <ButtonContainer>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                      생성하기
                  </Button>
                </Form.Item>
              </ButtonContainer>
          </Form>
        </Container>
    </>
  )
}

export default CompCreate

const Container = styled.div`
.botGap {
  margin-bottom: 16px;
}
.ant-descriptions-item-label {
  width: 259px;
}
`;


const ButtonContainer = styled.div`
margin-top: 25px;
display: flex;
justify-content: flex-end;
width: 100%;
    button{
        margin-right: 10px;
    }
`;

const InputContainer = styled.div`
display: flex;
align-items: end;
width: 100%;
.first {
  margin-right: 23px;
}
.second {
  margin-right: 15px;
}
`;

const InputText = styled.span`
margin-left: 9px;
white-space: nowrap;
color: #999;
`;

const SFormItem = styled(Form.Item)`
width: 100%;
  .ant-input-number {
    height: 32px;
  }
`;

const DescTitle = styled.span`
padding-bottom: 15px;
`;

const DescSubTitle = styled.span`
color: #498AFF;
font-size: 9px;
`;

const SFormItemLong = styled(Form.Item)`
width: 100%;
`;

const SInput = styled(Input)`
width: 150px;
`;

const SButton = styled(Button)`
color: #999;
`;

const SInputNumber = styled(InputNumber)`
width: 150px;
`;

const SInputNumberPrice = styled(InputNumber)`
width: 100%;
`;