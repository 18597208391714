import { Button } from 'antd';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useAppContext } from '../../contexts/AppContext';
import { formatStopWatchTime } from '../../common/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';

const Stopwatch = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [laps, setLaps] = useState([]);
  const intervalRef = useRef();
  const { state, dispatch } = useAppContext();
  const { elapsedTime } = state;

  const handleToggleCollapsed = () => {
    dispatch({type: 'TOGGLE_COLLAPSE'});
  }

  const handleStartStop = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
    } else {
      const startTime = Date.now() - elapsedTime;
      intervalRef.current = setInterval(() => {
        const newElapsedTime = Date.now() - startTime;
        dispatch({ type: 'SET_ELAPSED_TIME', payload: newElapsedTime });
      }, 100);
    }

    setIsRunning(!isRunning);
  };

  const handleRecordLap = () => {
    setLaps((prevLaps) => [...prevLaps, elapsedTime]);
  };

  const handleReset = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    dispatch({ type: 'SET_ELAPSED_TIME', payload: 0 });
    setLaps([]);
  };

  const handleRemoveLap = (index) => {
    setLaps((prevLaps) => prevLaps.filter((_, lapIndex) => lapIndex !== index));
  };

  return (
    <Wrapper>
      <CollapseButtonBox>
        <Button onClick={handleToggleCollapsed}>
          <FontAwesomeIcon icon={faRightToBracket}/>
        </Button>
      </CollapseButtonBox>
      <Title>스톱워치</Title>
      <TimerBox>{formatStopWatchTime(elapsedTime)}</TimerBox>
      <ButtonBox>
        <Button onClick={handleReset}>초기화</Button>
        <Button onClick={handleRecordLap} disabled={!isRunning} style={{width:'71px'}} >기록</Button>
        <Button onClick={handleStartStop} style={{width:'71px'}} >{isRunning ? '정지' : '시작'}</Button>
      </ButtonBox>
      {laps.length > 0 && (
        <LapBox>
            {laps.map((lapTime, index) => (
              <LapRow key={index}>
                <LapTitle>{`기록 ${index + 1}: `}</LapTitle>
                <LapTime>{formatStopWatchTime(lapTime)}</LapTime>
                <MinusCircleOutlined onClick={() => handleRemoveLap(index)}>Remove</MinusCircleOutlined>
              </LapRow>
            ))} 
        </LapBox>
      )}
    </Wrapper>
  );
};

export default Stopwatch;

const Wrapper = styled.div`
background-color: white;
width: 260px;
height: 60vh;
min-height: 220px;
margin: 20px;
border-radius: 10px;
position: sticky;
top: 20px; 
overflow-y: auto;
padding-bottom: 20px;
filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
&::-webkit-scrollbar {
  width: 0.5em;
}

&::-webkit-scrollbar-thumb {
  background-color: transparent;
}
`

const CollapseButtonBox = styled.div`
margin-left: 15px;
margin-top: 15px;
`

const Title = styled.div`
font-size: 16px;
text-align: center;
`

const TimerBox = styled.div`
font-size: 40px;
text-align: left;
line-height: 100px;
margin: 15px;
padding-left: 53px;
height: 100px;
background-color: #FAFAFA;
font-family: 'Chakra Petch Light';
`

const LapBox = styled.div`
margin-top: 20px;
padding-left: 17px;
padding-right: 17px;
`

const LapRow = styled.div`
display: flex;
height: 20px;
border-bottom: 1px solid #FAFAFA;
margin-bottom: 5px;

`

const LapTitle = styled.div`
width: 140px;
`

const LapTime = styled.div`
width: 70px;
`

const ButtonBox = styled.div`
padding-left: 17px;
text-align: center;
display: flex;
gap: 6px;
`
