import React from 'react'
import SigninForm from '../components/Signin/SigninForm'
import { styled } from 'styled-components'
import images from '../common/images'
import { changeBaseUrl } from '../common/api'
import Notification from '../components/commons/Notification'

const Home = () => {
  const {contextHolder, serverStatusNoti} = Notification();
  const handleServerChange = (status) => {
    serverStatusNoti(status);
  }

  return (
    <Wrapper>
      {contextHolder}
        <SigninForm/>
        <Button onClick={()=>{handleServerChange(changeBaseUrl())}}></Button>
    </Wrapper>
  )
}

export default Home

const Wrapper = styled.div`
width: 100%;
height: 100vh;
background-color: #FF8667;
/* background: linear-gradient(146deg, rgba(255,134,103,1) 0%, rgba(255,134,103,1) 15%, rgba(73,138,255,1) 100%); */
background-size: cover;
background-repeat: no-repeat;
background-image: url(${images.background});
`;

const Button = styled.button`
border: none;
background-color: rgba(0,0,0,0);
position: absolute;
bottom: 0;
border: 0;
cursor: pointer;
width: 50px;
height: 50px;
`;