import React, { createContext, useContext, useReducer } from 'react'

const initialAppState = {
    collapsed: true,
    elapsedTime: 0,
}

const AppContext = createContext();

const appReducer = (state, action) => {
    switch(action.type) {
        case 'TOGGLE_COLLAPSE':
            return {...state, collapsed: !state.collapsed};
        case 'SET_ELAPSED_TIME':
            return {...state, elapsedTime: action.payload};
        default:
            return state;
    }
};

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialAppState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

const useAppContext = () => {
    const context = useContext(AppContext);
    if(!context) {
        throw new Error('useAppContext는 AppProvider를 사용해야함')
    }
    return context;
}

export { AppProvider, useAppContext }