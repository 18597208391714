import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'; 
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useSignin() {
  const [token, setToken] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
  const navigate = useNavigate(); 
  const [loginResult, setLoginResult] = useState(); 

  const login = async (data) => {
    const {id, password} = data;
    
    // 한글입력시 인코딩오류뜨는거 방지
    const encoder = new TextEncoder();
    const idBytes = encoder.encode(id);
    const passwordBytes = encoder.encode(password);
    const combinedBytes = new Uint8Array(idBytes.length + 1 + passwordBytes.length);
    combinedBytes.set(idBytes, 0);
    combinedBytes[idBytes.length] = ':'.charCodeAt(0);
    combinedBytes.set(passwordBytes, idBytes.length + 1);
    const basicAuth = btoa(String.fromCharCode.apply(null, combinedBytes));

      try {
          const response = await axios.get(`${getBaseUrl()}/auth/sign-in`, {
            headers: {
              Authorization: `Basic ${basicAuth}`,
            },
          });
          if (response.status === 200) {
            setLoginResult(true)
            const authToken = response.headers.authorization;
            const refToken = response.headers['jwt-refresh-token'];
            setToken(authToken);  

            setCookie(ACCESS_TOKEN, authToken, { path: '/' }); 
            setCookie(REFRESH_TOKEN, refToken, { path: '/' });  

            navigate('/');
            
          } else {
            setLoginResult(false)
            logOut(); 
          }
      } catch (error) {
          setLoginResult(false)
          console.error(error);
          logOut(); 
      }
  };

  const clearCookies = () => {
    removeCookie(ACCESS_TOKEN, { path: '/' }); 
    removeCookie(REFRESH_TOKEN, { path: '/' }); 
  }

  useEffect(() => {
    const clearCookiesTimer = setInterval(() => {
      clearCookies();
      navigate('/signin');
    }, 8 * 60 * 60 * 1000);

    return () => clearInterval(clearCookiesTimer);
  }, [navigate]);

  const logOut = () => {
    clearCookies();
    navigate('/signin'); 
  };

  const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};
 
  return { login, authHeaders, logOut, loginResult, setLoginResult };
}

export default useSignin;
