import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useDuplicateCheck() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [dupCheckResult, setDepCheckResult] = useState(null);
    
    const dupCheck = async (idString) => {
        try {
            const headers = {
                Authorization: `Bearer ${cookies[ACCESS_TOKEN]}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };
            
            const response = await axios.get(`${baseUrl}/admin-member/login-id/${idString}/duplicate-check`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setDepCheckResult(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    return { dupCheck, dupCheckResult, setDepCheckResult };
}

export default useDuplicateCheck;
