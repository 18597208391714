import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { getBaseUrl, ACCESS_TOKEN, REFRESH_TOKEN } from '../../common/api';

function useGetGroupName() {
    const baseUrl = getBaseUrl();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const [groupNameList, setGroupNameList] = useState(null);
    
    const getGroupName = async (compId) => {
        try {
            const headers = {
                Authorization: `Bearer ${cookies[ACCESS_TOKEN]}`,
                'JWT-Refresh-Token': cookies[REFRESH_TOKEN],
            };
            const response = await axios.get(`${baseUrl}/competition/${compId}/group-name`, { headers });

            const newAccessToken = response.headers.get('authorization'); 
            if(newAccessToken) {
                setCookie(ACCESS_TOKEN, newAccessToken); 
            }

            setGroupNameList(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    return { getGroupName, groupNameList };
}

export default useGetGroupName;
