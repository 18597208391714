import React from 'react'
import { Button, Descriptions, Modal } from 'antd';
import { styled } from 'styled-components'
import { ExclamationCircleFilled } from '@ant-design/icons';


const DocuModal = ({ open, setOpen, docu }) => {
    const handleCancel = () => {
        setOpen(false);
    };  

    const items = [
      {
        key: '1',
        label: '이름',
        children: docu.name,
        span:3,
      },
      {
        key: '2',
        label: '주민등록번호',
        children: docu.rrn,
        span:3,
      },
      {
        key: '3',
        label: '주소',
        children: docu.address,
        span:3,
      },
      {
        key: '4',
        label: '서류 등록일',
        children: docu.issueDate,
        span:3,
      },
      {
        key: '5',
        label: '서류 등록번호',
        children: docu.certNumber,
        span:3,
      },
      {
        key: '6',
        label: '서류 발급구분',
        children: docu.isOffline===true ? '오프라인' : '온라인',
        span:3,
      },
    ];

  return (
    <Modal
      open={open}
      centered
      title={
        <span>
          <ExclamationCircleFilled style={{ marginRight: '8px', color: "#1890ff",}} />
          서류 상세 확인
        </span>
      }
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleCancel}>확인</Button>
      ]}
    >
      <Wrapper>
        <Descriptions bordered items={items} />
      </Wrapper> 
    </Modal>
  )
}

export default DocuModal

const Wrapper = styled.div`
margin-top: 32px;
  svg{
    margin-right: 8px;
  }
`;