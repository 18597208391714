import React from 'react'
import styled from 'styled-components'
import images from '../common/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Error = () => {
    const channelUrl = 'https://pf.kakao.com/_zxfBTG';
  return (
    <Wrapper>
        <MainBox>
            <MainText><FontAwesomeIcon icon={faTriangleExclamation} size='xl'/></MainText>
            <MainText>요청하신 페이지를 찾을 수 없습니다.</MainText>
            <SubText>주소를 잘못 입력하였거나 변경 또는 삭제됐습니다.<br/>페이지 주소가 정확한지 한번 더 확인 후 이용 바랍니다.</SubText>
            <ButtonContainer>
                <Link to={channelUrl} target="_blank" rel="noopener noreferrer">
                    <SButton>고객센터</SButton>
                </Link>
                <Link to="/"><SButton>홈</SButton></Link>
            </ButtonContainer>
        </MainBox>
    </Wrapper>
  )
}

export default Error

const Wrapper = styled.div`
width: 100vw;
height: 100vh;
min-width: 800px;
display: flex;
background-color: black;
background-size: cover;
background-repeat: no-repeat;
background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url(${images.background});
font-family: 'NanumSquareNeo800';
align-items: center;
justify-content: center;
`;

const MainBox = styled.div`
margin-top: -70px;
`

const MainText = styled.div`
color: white;
font-size: 50px;
vertical-align: middle;
text-align: center;
margin-top: 30px;
`

const SubText = styled.div`
color: white;
margin-top: 30px;
text-align: center;
font-family: 'NanumSquareNeo';
line-height: 30px;
`

const ButtonContainer = styled.div`
display: flex;
justify-content: center;
gap: 30px;
margin-top: 60px;
`

const SButton = styled.button`
border-radius: 30px;
background: #1F1F1F;
color: white;
width: 226px;
height: 60px;
border: none;
cursor: pointer;
font-family: 'NanumSquareNeo';
margin-top: auto;
font-size: 21px;
`