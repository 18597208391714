import React, { useEffect, useState } from 'react'
import { Button, Table, Tag,  } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { adminTypeChecker, 지역필터 } from '../../../common/util';
import StyleAtoms from '../../../common/StyleAtoms';
import images from '../../../common/images';
import { styled } from 'styled-components'
import Navigation from '../../../common/Navigation';
import { PhoneNumberFormatter } from '../../../common/util';
import useGetAllTeam from '../../../hooks/Comp/SuperUser/useGetAllTeam';
import useAddTeam from '../../../hooks/Comp/SuperUser/useAddTeam';
import Message from '../../../components/commons/Message';
import { useCookies } from 'react-cookie';
import { ACCESS_TOKEN } from '../../../common/api';

const TeamListAdd = () => {
  const { PageTitle, SearchBox, SSearch, GreyText, BlueImg, InvertImg, 
    TitleBox, Title2, SumBox, SumCol, SumColBl, ButtonBox } = StyleAtoms();
  const { Column } = Table;
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { compId } = useParams();
  const location = useLocation();
  const { compName } = queryString.parse(location.search);
  const { allTeamList, getAllTeam } = useGetAllTeam();
  const { goComp, goError, goCompList, goTeamList } = Navigation();
  const { contextHolder, messageSuccess, messageError, 
    messageLoading, closeMessageLoading, messageEmptySelection } = Message();
  const { addTeam } = useAddTeam();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(8);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
  const adminType = adminTypeChecker(cookies);

  const rowClassName = (record, index) => {
    if (index % 2 !== 0) {
      // 짝수 로우 클래스
      return 'even-row';
    }
    return '';
  };

  const handleSearch = () => {
    const searchTextLower = searchText.toLowerCase();  
    const filtered = allTeamList.filter(item => item.name.toLowerCase().includes(searchTextLower));
    setFilteredData(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAddTeam = async () => {
    if(selectedRowKeys.length === 0) {
      messageEmptySelection();
    } else {
      messageLoading();

      const updResult = await addTeam(selectedRowKeys)
      if (updResult === 200) {
        closeMessageLoading();
        messageSuccess();
      } else {
        closeMessageLoading();
        messageError();
      }
      getAllTeam(compId);
      setSelectedRowKeys([])
    }
  }

  useEffect(()=>{
    if(adminType==='commonUser') {
      goError();
    } else {
      getAllTeam(compId);
    }
  },[])

  return (
    <Container>
      {contextHolder}
      <PageTitle>
        <InvertImg src={images.icon.trophy} alt='' />
        <span onClick={goComp} className='pointer'>대회</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goTeamList(compId, compName)}}>참가 팀 리스트</span>
        <BlueImg src={images.icon.arrow} alt='' />
        <span className='blue'>참가 팀 추가</span>
      </PageTitle>
      <SearchBox>
        <SSearch
          placeholder="팀 이름을 검색해 보세요"
          onChange={handleSearchChange}
          onSearch={handleSearch}
          value={searchText}
        />
      </SearchBox>
      <Title2>참가 팀 추가</Title2>

      <TitleBox>
        <SumBox>
          <SumColBl>
            <span>전체</span>  
            <span>{allTeamList?.length}</span>  
          </SumColBl>
          <SumCol>
            <span>선택</span>
            <span>{selectedRowKeys.length}</span>  
          </SumCol>
        </SumBox>
        <ButtonBox>
            <Button type='primary' onClick={onAddTeam}>선택 팀 추가</Button>
        </ButtonBox>
      </TitleBox>
      
      <Table dataSource={filteredData || allTeamList} 
        pagination={{defaultPageSize: 8, position: ['bottomRight'], hideOnSinglePage: false, showSizeChanger:true, onChange:(page, pageSize)=>{onPageChange(page, pageSize)}}}
        rowClassName={rowClassName}
        rowKey={(record)=>record.id}
        rowSelection={rowSelection}
      >
        <Column title="순서" key="order"
          render={(text, record, index) =>
            (index + 1) + currentPageSize * (currentPage - 1)
          }
          className='orderCol'
        />
        <Column title="팀 이름" dataIndex="name" key="name"/>
        <Column title="팀 지역" key="location" 
          filters={지역필터} onFilter={(value, record) => record.location.startsWith(value)} filterSearch={true} 
          render={(record)=>(
            <span>{record.location} {record.subLocation}</span>
        )}/>
        <Column title="팀리더 연락처" key="represent"
          render={(record)=>(
            <span>{record.represent.name}&nbsp;&nbsp;&nbsp;&nbsp;{PhoneNumberFormatter(record.represent.phone)}</span>
        )}/>
        
        <Column title={<span>인원 수 <GreyText>&#40;전체 인원수&#41;</GreyText></span>} 
          key="memberCount"
          render={(record) => (
            <span>{record.memberCount}</span>  
          )}
        />
      </Table>
    </Container>
  )
}

export default TeamListAdd

const Container = styled.div`
.orderCol {
  max-width: 60px;
}
`


