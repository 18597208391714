import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Table, Button, Cascader } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useGetMatchList from '../../hooks/Match/useGetMatchList';
import StyleAtoms from '../../common/StyleAtoms';
import { 대회종류번역기, 경기종류필터, 경기상태필터, 경기상태번역기, 경기그룹필터 } from '../../common/util'
import useGetQr from '../../hooks/useGetQr';
import QRModal from './QRModal';

const MatchList = () => {
  const { Column } = Table;
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { compId } = useParams();
  const location = useLocation();
  const { compName } = queryString.parse(location.search);
  const { matchList } = useGetMatchList();
  const {SearchBox, TitleBox, Title, SSearch, ButtonBox, SumBox, SumCol, SumColBl } = StyleAtoms();
  const [casValue, setCasValue] = useState();
  const [sortedMatchList, setSortedMatchList] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const navigate = useNavigate();
  const { getQr, qrCode} = useGetQr();
  const [open, setOpen] = useState(false);
  const [selectedMatchContent, setSelectedMatchContent] = useState();

  const today = new Date(); 
  const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  const todayMatchCount = matchList?.filter(item => {
    const itemDate = new Date(item.startAt);
    itemDate.setHours(0, 0, 0, 0);
    return itemDate.getTime() === todayStart.getTime();
  }).length;

  const qrCodeGen = (match) => {
    setSelectedMatchContent(match)
    getQr(match.id)
    setOpen(true);
  }

  const options2 = [
    {
      value: '경기 이름',
      label: '경기 이름',
    },
    {
      value: '팀 이름',
      label: '팀 이름',
    },
    {
      value: '장소',
      label: '장소',
    },
    {
      value: '기록 번호',
      label: '기록 번호',
    },
  ];

  const rowClassName = (record, index) => {
    if (index % 2 !== 0) {
      // 짝수 로우 클래스
      return 'even-row';
    }
    return '';
  };

  const handleSearch = () => {
    const searchTextLower = searchText.toLowerCase();  
  
    const filtered = matchList.filter(item => 
      casValue?.includes('장소') ? 
      item.location?.toLowerCase().includes(searchTextLower)
      : casValue?.includes('기록 번호') ? 
      item.recordNumber?.includes(searchTextLower)
      : casValue?.includes('경기 이름') ?
      item.name?.toLowerCase().includes(searchTextLower)
      : casValue?.includes('팀 이름') ?
      item.homeTeam.name?.toLowerCase().includes(searchTextLower) ||
      item.awayTeam.name?.toLowerCase().includes(searchTextLower)
      :
      item.location?.toLowerCase().includes(searchTextLower) ||
      item.recordNumber?.includes(searchTextLower) ||
      item.name?.toLowerCase().includes(searchTextLower) ||
      item.homeTeam.name?.toLowerCase().includes(searchTextLower) ||
      item.awayTeam.name?.toLowerCase().includes(searchTextLower)
    );
  
    setFilteredData(filtered);
    if(searchText === '') {
      setFilteredData(null);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = (newPage, pageSize) => {
    setPage(newPage);
    setPageSize(pageSize)
    const query = queryString.stringify({ page: newPage });
    navigate({ search: `?compName=${encodeURIComponent(compName)}&${query}` });
  };
  
  const 경기장소필터 = Array.from(new Set(matchList?.map(a => a.location)))
  .map(location => ({ text: location, value: location }));
  
  useEffect(() => {
    if(matchList) {
      const sortMatchList = [...matchList];
      sortMatchList.sort((a, b) => {
        const dateA = new Date(a.startAt);
        const dateB = new Date(b.startAt);
        return dateB - dateA; 
      });
    setSortedMatchList(sortMatchList);
    }
  }, [matchList]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.page) {
      setPage(Number(parsed.page));
    }
  }, [location.search]);

  return (
    <>
    <SearchBox>
      <SSearch
        placeholder="경기를 검색해 보세요"
        onChange={handleSearchChange}
        onSearch={handleSearch}
        value={searchText}
        addonBefore={
          <Cascader
            options={options2}
            placeholder="검색필터"
            value={casValue}
            onChange={value => setCasValue(value)}
            style={{width:'100px'}}
          />
        }
      />
    </SearchBox>
    <Title>경기 리스트</Title>
    <TitleBox>
      <SumBox>
        <SumColBl>
          <span>전체</span>  
          <span>{matchList?.length}</span>  
        </SumColBl>
        <SumCol>
          <span>오늘</span>  
          <span>{todayMatchCount}</span>  
        </SumCol>
        <SumCol>
          <span>경기 전</span>  
          <span>{matchList?.filter(item => item.matchStatus === 'BEFORE').length}</span>  
        </SumCol>
        <SumCol>
          <span>경기 종료</span>  
          <span>{matchList?.filter(item => item.matchStatus === 'END').length}</span>  
        </SumCol>
        <SumCol>
          <span>기록 입력 완료</span>  
          <span>{matchList?.filter(item => item.matchStatus === 'INSERT').length}</span>  
        </SumCol>
      </SumBox>
      <ButtonBox>
        <Link to={`/comp/${compId}/matchCreate?compName=${encodeURIComponent(compName)}`}>
          <Button type='primary'>경기 생성</Button>
        </Link>
      </ButtonBox>
    </TitleBox>
    

    <Table dataSource={filteredData || matchList} 
      pagination={
        {
          defaultPageSize: 8, 
          position: ['bottomRight'], 
          hideOnSinglePage: false, 
          showSizeChanger: true,
          current: page,
          onChange:(page, pageSize)=>{handlePageChange(page, pageSize)}
        }
      }
      rowClassName={rowClassName}
      rowKey={(record) => record.id}
      >
      <Column title="#" key="order"
        render={(text, record, index) =>
          (index + 1) + pageSize * (page - 1)
        }
      />
      <Column title="경기 이름" dataIndex="name" key='name'
        render={(text, record) => (
          <Link
            to={`/comp/${compId}/match/${record.id}/matchEdit?compName=${encodeURIComponent(compName)}`}
            style={{ color: 'black' }}
          >
            {record.name}
          </Link>
        )}
      /> 
      <Column title={<span>HOME VS AWAY</span>}
        key="matchTeams"
        render={(text, record) => (
          <Link
            to={`/comp/${compId}/match/${record.id}/matchEdit?compName=${encodeURIComponent(compName)}`}
            style={{ color: 'black' }}
          >
            {record.homeTeam.name} VS {record.awayTeam.name}
          </Link>
      )}/>
      <Column title="경기 시간" dataIndex="startAt" key="matchStartAt" 
        sorter={(a, b) => {
          const dateA = new Date(a.startAt);
          const dateB = new Date(b.startAt);
          return dateA - dateB;
        }} 
        render={(startAt, i) => (
          <span>
            {dayjs(startAt).format('YYYY.MM.DD(dd) HH:mm')}
          </span>
        )} 
      />
      <Column title="경기 장소" dataIndex="location" key="matchLocation" 
        filters={경기장소필터} onFilter={(value, record) => record.location.startsWith(value)} 
      />
      <Column title="경기 종류" dataIndex="matchType" key="matchType" 
        filters={경기종류필터} onFilter={(value, record) => record.matchType.startsWith(value)} 
        render={(record, i)=>(대회종류번역기(record))}
      />
      <Column title="경기 그룹" dataIndex="groupName" key="groupName" 
        filters={경기그룹필터(matchList)} onFilter={(value, record) => record.groupName?.startsWith(value)} 
      />
      <Column title="경기 상태" dataIndex="matchStatus" key="matchStatus" 
        filters={경기상태필터} onFilter={(value, record) => record.matchStatus.startsWith(value)} 
        render={(record, i)=>(
          <ColorSpan state={record}>
            {경기상태번역기(record)}
          </ColorSpan>
        )}
      />
      <Column
        title="QR 코드 프린트"
        key="matchRecord"
        render={(record, i) => (<RedLink onClick={()=>{qrCodeGen(record)}}>QR 코드</RedLink>)}
      />
      <Column title="경기 기록 번호" dataIndex="recordNumber" key="matchRecordNumber"/>
      <Column
        title="경기 기록지"
        key="matchRecord"
        render={(record, i) => (
          record.matchStatus === 'INSERT' 
          ? <Link to={`/comp/${compId}/match/${record.id}/record?compName=${encodeURIComponent(compName)}`} >경기 기록지</Link> 
          : <Link to={`/comp/${compId}/match/${record.id}/recordEdit?compName=${encodeURIComponent(compName)}`} >경기 기록지</Link>
        )}
      />
    </Table>
    <QRModal open={open} setOpen={setOpen} qrCodeValue={qrCode} matchContent={selectedMatchContent} compName={compName}/>
    </>
  )
}

export default MatchList

const ColorSpan = styled.span`
color: ${props =>
  props.state === 'CANCEL' ? 
  '#8c8c8c' : 
  props.state === 'INSERT' ? 
  '#20D167' : 
  'black'};
`;

const RedLink = styled.span`
color: #FF8667;
cursor: pointer;
`

 