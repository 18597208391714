import React from 'react'
import { Button, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const DeleteModal = ({onOk}) => {
    const { confirm } = Modal;

    const showConfirm = () => {
      confirm({
        title: '삭제 하시겠습니까?',
        content: 
        <>
        경기를 삭제하면 정보를 되돌릴 수 없습니다. <br/>정말 삭제하시겠습니까?
        </>,
        icon: <ExclamationCircleFilled />, 
        okText: '확인',
        okType: 'danger',
        cancelText: '취소',
        onOk(){ onOk() },
        onCancel(){},
      });
    };

  return (
    <Button danger onClick={showConfirm}>삭제하기</Button>
  );
};

export default DeleteModal
