import { useEffect, useContext } from 'react';
// import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
// import { createBrowserHistory } from 'history';


// export const useBlocker = (blocker, when = true) => {
//   const history = createBrowserHistory();

//   const navigator = useContext(NavigationContext).navigator;

//   useEffect(() => {
//     if (!when) return;

//     const unblock = history.block((tx) => {
//       const autoUnblockingTx = {
//         ...tx,
//         retry() {
//           unblock();
//           tx.retry();
//         },
//       };

//       blocker(autoUnblockingTx);
//     });

//     return unblock;
//   }, [navigator, blocker, when]);
// };

export const useBackListener = () => {
  const location = useLocation();
  const navType = useNavigationType();

  // console.log(navType, location.key, 'location111');
};